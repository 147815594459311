export default {
  ru: {
    meetings: 'Собрания',
    owners_meetings: 'Собрания собственников',
    notices_about_holding: 'Сообщения о проведении',
    general_meeting_of_owners: 'общего собрания собственников',
    active: 'Активные',
    completed: 'Завершенные',
    name: 'Наименование',
    initiator_of_meeting: 'Инициатор собрания',
    house_address: 'Адрес дома',
    meeting_date: 'Дата собрания',
    form_of_meeting: 'Форма проведения',
    meeting_status: 'Статус собрания',
    owners_meeting: 'Собрание собственников',
    meeting_completed: 'Завершено',
  },

  en: {
    meetings: 'Meetings',
    owners_meetings: 'Owners meetings',
    notices_about_holding: 'Notices about holding',
    general_meeting_of_owners: 'a general meeting of owners',
    active: 'Active',
    completed: 'Completed',
    name: 'Name',
    initiator_of_meeting: 'Initiator of the meeting',
    house_address: 'House address',
    meeting_date: 'Meeting date',
    form_of_meeting: 'Form of the meeting',
    meeting_status: 'Meeting status',
    owners_meeting: 'Owners meeting',
    meeting_completed: 'Completed',
  },
}