<template>
  <modal
    ref="chatDialog"
    :title="$t('chat_with_technical_support')"
    :chatMode="showChat"
    :key="'supportModalChatWithoutAuth'"
    class="modal--support-chat"
    @close="$emit('close')"
  >
    <template v-if="showChat">
      <support-chat :phone="phone" @close-chat="$emit('close')" />
    </template>

    <template v-else>
      <div class="intro">
        <h5 class="intro__note">{{ $t('before_start_communicating_with_technical_support') }}</h5>
        <div class="form-box">
          <form class="form-box__form" @submit.prevent="onConfirmPhone">
            <form-box-input
              v-model="phone"
              v-mask="`+7 (###) ### ## ##`"
              refName="loginControl"
              :label="$t('phone_number')"
              :message="$t('field_cannot_be_empty')"
              :focused="phoneIsFocused"
              type="tel"
              placeholder="+7 (999) 999 99 99"
            />
            <base-button tabindex="0" class="form-box__button" :disabled="!isValidPhone" @click="onConfirmPhone">
              {{ $t('send') }}
            </base-button>
          </form>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import supportChat from '@/components/SupportChat';
import FormBoxInput from '@/components/form/FormBoxInput';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'supportModalChatWithousAuth',

  components: {
    Modal,
    supportChat,
    FormBoxInput,
    BaseButton,
  },

  data() {
    return {
      phoneIsFocused: false,
      phone: '',
      showChat: false,
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.phoneIsFocused = true;
    });
  },

  computed: {
    isValidPhone() {
      return !!this.phone.length && !!(this.phone.match(/(\d+)/g).join('').length >= 11);
    },
  },

  methods: {
    onConfirmPhone() {
      this.showChat = true;
      this.$emit('phoneisconfirmed');
    },
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/form'

.intro
  .form-box__form
    margin-bottom: 0

.intro__note
  +TextMain($cTextMain)
  margin: 0 0 16px
</style>
