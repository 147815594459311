export default {
  ru: {
    confirmation_order: 'Подтверждение заказа',
    cost: 'Стоимость',
    goods: 'товаров',
    services: 'услуг',
    confirm_order: 'Подтвердить заказ',
    continue_shopping: 'Продолжить покупки',
  },

  en: {
    confirmation_order: 'Confirmation of an order',
    cost: 'Cost',
    goods: 'of goods',
    services: 'of services',
    confirm_order: 'Confirm the order',
    continue_shopping: 'Continue shopping',
  },
}