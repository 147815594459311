import common from './modules/common';

import accessCode from './modules/accessCode';
import account from './modules/account';
import announcement from './modules/announcement';
import home from './modules/home';
import login from './modules/login';
import meters from './modules/meters';
import mobileApp from './modules/mobileApp';
import newRequest from './modules/newRequest';
import news from './modules/news';
import notFound from './modules/notFound';
import oss from './modules/oss';
import ossInfo from './modules/ossInfo';
import ossResult from './modules/ossResult';
import ossVote from './modules/ossVote';
import payment from './modules/payment';
import personalAccounts from './modules/personalAccounts';
import polls from './modules/polls';
import privilegeClub from './modules/privilegeClub';
import profile from './modules/profile';
import register from './modules/register';
import requestConfirmation from './modules/requestConfirmation';
import requests from './modules/requests';
import services from './modules/services';
import shop from './modules/shop';
import support from './modules/support';
import userCart from './modules/userCart';
import accessGuide from './modules/accessGuide';

export default {
  ru: {
    ...common.ru,
    ...accessCode.ru,
    ...account.ru,
    ...announcement.ru,
    ...home.ru,
    ...login.ru,
    ...meters.ru,
    ...mobileApp.ru,
    ...newRequest.ru,
    ...news.ru,
    ...notFound.ru,
    ...oss.ru,
    ...ossInfo.ru,
    ...ossResult.ru,
    ...ossVote.ru,
    ...payment.ru,
    ...personalAccounts.ru,
    ...polls.ru,
    ...privilegeClub.ru,
    ...profile.ru,
    ...register.ru,
    ...requestConfirmation.ru,
    ...requests.ru,
    ...services.ru,
    ...shop.ru,
    ...support.ru,
    ...userCart.ru,
    ...accessGuide.ru
  },

  en: {
    ...common.en,
    ...accessCode.en,
    ...account.en,
    ...announcement.en,
    ...home.en,
    ...login.en,
    ...meters.en,
    ...mobileApp.en,
    ...newRequest.en,
    ...news.en,
    ...notFound.en,
    ...oss.en,
    ...ossInfo.en,
    ...ossResult.en,
    ...ossVote.en,
    ...payment.en,
    ...personalAccounts.en,
    ...polls.en,
    ...privilegeClub.en,
    ...profile.en,
    ...register.en,
    ...requestConfirmation.en,
    ...requests.en,
    ...services.en,
    ...shop.en,
    ...support.en,
    ...userCart.en,
    ...accessGuide.en
  },
}