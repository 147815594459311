export default {
  ru: {
    back_to_gmo_list: 'Назад к списку ОСС',
    results_of_general_voting: 'Результаты общего голосования в м.кв.',
    results_of_vote: 'Результаты вашего голосования в м.кв.',
    total_voted_for: 'Всего проголосовали за',
    you_voted_for: 'Вы проголосовали за',
    results: 'Результаты',
    result: 'Результат',
    your_answers: 'Ваши ответы',
    total_share_of_voters: 'Общая доля проголосовавших',
    thank_you_for_participating: 'Спасибо за участие!',
    voting_protocol: 'Протокол голосования',
    final_document: 'Итоговый документ',
    final_day_of_voting: 'заключительный день голосования. Итоги голосования будут доступны',
    when_voting_results_counted: 'местного времени. Когда результаты голосования будут подсчитаны, вы&nbsp;получите уведомление в формате Push сообщения и доступ к «Протоколу ОСС» с пакетом необходиых документов.',
    voting_results2: 'Итоги голосования',
    vote_counted: 'Ваш голос учтён',
  },

  en: {
    back_to_gmo_list: 'Back to GMO list',
    results_of_general_voting: 'Results of general voting in sq.m.',
    results_of_vote: 'Results of your vote in sq.m.',
    total_voted_for: 'Total voted for',
    you_voted_for: 'You voted for',
    results: 'Results',
    result: 'Result',
    your_answers: 'Your answers',
    total_share_of_voters: 'Total share of voters',
    thank_you_for_participating: 'Thank you for participating!',
    voting_protocol: 'Voting protocol',
    final_document: 'Final document',
    final_day_of_voting: 'final day of voting. Voting results will be available',
    when_voting_results_counted: 'local time. When the voting results are counted, you will receive a notification in push message format and access to the «GMO Protocol» with a package of necessary documents.',
    voting_results2: 'Voting results',
    vote_counted: 'Your vote has been counted',
  },
}