var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.items)?_c('perfect-scrollbar',{ref:"scrollSection",staticClass:"polls-list",attrs:{"tag":"div"}},_vm._l((_vm.items),function(item,i){return _c('article',{key:`poll-${i}`,staticClass:"poll-card",class:{ 'poll-card--active': item.ID == _vm.$route.params.id }},[_c('h3',{staticClass:"poll-card__title"},[_vm._v(_vm._s(item.Name))]),((item.Passed || item.PartiallyPassed) && !item.HideResults)?[(item.ID == _vm.$route.params.id)?_c('span',{staticClass:"poll-card__label"},[_vm._v(_vm._s(_vm.$t('viewing_answers')))]):_c('router-link',{staticClass:"poll-card__link",attrs:{"to":{
          name: 'Polls',
          params: {
            id: item.ID,
            subpage: 'results',
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t('show_answers'))+" "),_c('arrow-right-icon',{staticClass:"poll-card__link-icon",attrs:{"size":"12"}})],1)]:(!item.Passed)?[(item.ID == _vm.$route.params.id)?_c('span',{staticClass:"poll-card__label"},[_vm._v(_vm._s(_vm.$t('you_are_passing_now')))]):_c('router-link',{staticClass:"poll-card__link poll-card__link--go",attrs:{"to":{
          name: 'Polls',
          params: {
            id: item.ID,
            subpage: 'form',
          },
        }}},[_vm._v(" "+_vm._s(item.PartiallyPassed ? _vm.$t('continue_passing') : _vm.$t('pass_poll'))+" "),_c('arrow-right-icon',{staticClass:"poll-card__link-icon",attrs:{"size":"12"}})],1)]:_vm._e()],2)}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }