<template>
  <section
    v-show="(this.isAndroidPhone || this.isApplePhone) && isShow"
    ref="app-notification"
    tabindex="-1"
    class="app-notification"
  >
    <div class="app-notification__overlay" @click="onDismiss()"></div>

    <div class="app-notification__content">
      <figcaption v-if="logoIsExist" class="app-notification__logo" @click="onDownload()">
        <img :src="config.appIconFile" :alt="config.main_name" />
      </figcaption>

      <p class="app-notification__text" @click="onDownload()">
        {{ $t('install_mobile_application') }} <strong>{{ config.main_name }}</strong
        >?
      </p>

      <button type="button" class="app-notification__dismiss" @click="onDismiss()">
        <x-icon />
      </button>
    </div>
  </section>
</template>

<script>
import { XIcon } from '@vue-hero-icons/outline';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AppNotification',

  components: {
    XIcon,
  },

  data() {
    return {
      isShow: false,
    };
  },

  computed: {
    ...mapGetters({
      getMobileAppNotification: 'common/getMobileAppNotification',
    }),
  },

  mounted() {
    setTimeout(() => {
      if (!this.getMobileAppNotification) {
        this.onShow();
      }
    }, 7000);
  },

  methods: {
    ...mapActions({
      setMobileAppNotification: 'common/setMobileAppNotification',
    }),

    onShow() {
      this.isShow = true;

      const $body = document.querySelector('body');
      $body.classList.add('_block-scroll');

      if (this.config?.appIconFile) {
        this.checkAppLogo();
      }

      if (this.$refs['app-notification']) {
        this.$refs['app-notification'].focus();
      }
    },

    onDownload() {
      if (this.isAndroidPhone) {
        window.open(this.config.appLinkAndroid, '_blank');
      }

      if (this.isApplePhone) {
        window.open(this.config.appLinkIOS, '_blank');
      }

      this.$router.push({
        name: 'MobileApp',
      });
    },

    onDismiss() {
      this.isShow = false;

      const $body = document.querySelector('body');
      $body.classList.remove('_block-scroll');

      this.$refs['app-notification'].blur();

      this.setMobileAppNotification();
    },
  },
};
</script>

<style lang="sass">
@import '../sass/variables'
@import '../sass/mixins'

.app-notification
  display: flex
  align-items: flex-end
  width: 100%
  height: 100%
  position: fixed
  top: 0
  left: 0
  z-index: 999

  &__overlay
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    z-index: 0
    background-color: rgba(178, 180, 181, 0.3)
    // background-color: rgba(0, 0, 0, .4)
    -webkit-backdrop-filter: blur(1px)
    backdrop-filter: blur(1px)
    filter: blur(1px)
    filter: url(#blur)
    transform: scale(1.1)
    transition: transform .3s, filter .3s, -webkit-backdrop-filter .3s
    transition: backdrop-filter .3s, transform .3s, filter .3s
    transition: backdrop-filter .3s, transform .3s, filter .3s, -webkit-backdrop-filter .3s

  &__content
    display: flex
    align-items: center
    width: 100%
    padding: 12px 6px
    background: $cBgMain
    position: relative
    z-index: 1

  &__logo
    flex-shrink: 0
    width: 42px
    height: 42px
    margin-right: 10px
    border: 1px solid $cAccent
    border-radius: 6px
    overflow: hidden

    img
      width: 100%
      object-fit: center

  &__text
    +Typography ($size: 18px, $weight: 400, $height: 20px, $color: $cStyle)
    margin-bottom: 0

    strong
      white-space: nowrap

  &__dismiss
    flex-shrink: 0
    +ButtonIcon()
    box-sizing: content-box
    padding: 6px
    margin-left: 10px
</style>
