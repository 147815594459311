<template>
  <span v-if="caption" :class="['badge', `badge--${type}`]">
    {{ caption }}
  </span>
</template>

<script>
export default {
  name: 'badge',

  props: {
    caption: {
      type: String,
      default: '',
      required: true,
    },
    type: {
      type: String, // success, danger, info, warning, neutral
      default: 'success',
      required: false,
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.badge
  +TextMicro()
  display: inline-block
  padding: 6px 8px
  border-radius: 16px

  &:not(:last-child)
    margin-right: 4px

  &--neutral
    color: $cLabel
    background-color: $cElmLight

  &--success
    color: $cSuccess
    background-color: $cBgSuccess

  &--danger
    color: $cError
    background-color: $cBgError

  &--info
    color: $cLabel
    background-color: $cElmNote

  &--warning
    background-color: $cBgWarning
    color: $cWarning
</style>
