<template>
  <main class="main-content">
    <div class="container">
      <router-link class="back-page_btn" :to="{ name: 'Oss' }">{{ $t('back_to_gmo_list') }}</router-link>
      <h1 v-if="commonResult" class="main-inner_title main-inner_title-mod">{{ $t('results_of_general_voting') }}</h1>
      <h1 v-else class="main-inner_title main-inner_title-mod">{{ $t('results_of_vote') }}</h1>
      <div class="number-qustion">
        <template v-if="commonResult">{{ $t('total_voted_for') }}</template><template v-else>{{ $t('you_voted_for') }}</template>
        <span class="red-color">&nbsp;{{ totalVoted }}/{{ totalCount }}</span>
      </div>
    </div>
    <div class="qustion-line">
      <span class="qustion_progress-bar" style="width: 100%"></span>
    </div>
    <div class="container">
      <div class="qustion-section">
        <div class="row">
          <div class="col-xl-8">
            <div v-if="commonResult" class="h3">{{ $t('results') }}:</div>
            <div v-else class="h3">{{ $t('your_answers') }}:</div>
            <ul class="voting-results_list">
              <li>
                <span class="number">{{ yesCount }}</span
                >{{ $t('for') }}
              </li>
              <li>
                <span class="number">{{ noCount }}</span
                >{{ $t('against') }}
              </li>
              <li>
                <span class="number">{{ skippedCount }}</span
                >{{ $t('abstained') }}
              </li>
            </ul>
            <div class="qustion-section_footer">
              <div class="column">
                {{ $t('total_area_of_premises') }}:
                <span class="digit-text">{{ oss.VoitingArea }} {{ $t('sq_m') }} = 100%</span>
              </div>
              <div class="column">
                {{ $t('total_share_of_voters') }}:
                <span class="digit-text">{{ oss.ComplateArea }} {{ $t('sq_m') }} = {{ oss.ComplateAreaPercents }}%</span>
              </div>
            </div>
          </div>
          <div class="col-xl-4">
            <div class="voting-result_box-content">
              <div class="unified-box unified-box_mod">
                <div class="unified-box_title-mod">{{ $t('thank_you_for_participating') }}</div>
                <ul v-if="commonResult" class="voting-result_pdf-list">
                  <li>
                    <span class="glipf-pdf"></span>
                    <span class="item-desc"
                      >{{ $t('voting_protocol') }}<br /><a :href="oss.ProtocolFileLink" download="">{{ $t('download') }}</a></span
                    >
                  </li>
                  <li>
                    <span class="glipf-pdf"></span>
                    <span class="item-desc"
                      >{{ $t('final_document') }}<br /><a :href="oss.FinalDocumentFileLink" download="">{{ $t('download') }}</a></span
                    >
                  </li>
                </ul>
                <p v-else>
                  <strong>{{ dateNoTime(oss.DateEnd) }}</strong>
                  {{ $t('final_day_of_voting') }}
                  {{ dateNoTime(oss.ResultsReleaseDate) }} {{ $t('at') }}&nbsp;{{
                    dateOnlyTime(oss.ResultsReleaseDate)
                  }}&nbsp;{{ $t('when_voting_results_counted') }}
                </p>
                <ul v-if="ossBlanks && ossBlanks.length" class="voting-result_pdf-list">
                  <li v-for="blank in ossBlanks" :key="blank.link">
                    <span class="glipf-pdf"></span>
                    <span class="item-desc">{{ blank.name }}<br /><a :href="blank.link" download="">{{ $t('download') }}</a></span>
                  </li>
                </ul>
              </div>
              <div class="unified-box unified-box_mod unified-box_status">
                <div class="unified-box_title-mod">{{ $t('meeting_status') }}:</div>
                <div v-if="commonResult" class="status-meeting voting-results">
                  <span class="glipf-check"></span>{{ $t('voting_results2') }}
                </div>
                <div v-else class="status-meeting vote-counted">
                  <span class="glipf-check glipf-check_green"></span>{{ $t('vote_counted') }}
                </div>
                <router-link class="main-btn" :to="{ name: 'Oss' }">{{ $t('back_to_gmo_list') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'OssResult',
  data() {
    return {
      oss: {},
      ossBlanks: [],
      isLoading: false,
      commonResult: false,
      yesCount: 0,
      noCount: 0,
      skippedCount: 0,
      totalVoted: 0,
      totalCount: 0,
    };
  },
  mounted() {
    this.getOSS();
  },
  methods: {
    setupResults(oss) {
      if (!oss) {
        return;
      }

      if (oss.HasProtocolFile) {
        // Completed
        this.commonResult = true;
        this.yesCount = oss.Questions.reduce((s, x) => (s += x.CountWhyVoiteYes), 0);
        this.noCount = oss.Questions.reduce((s, x) => (s += x.CountWhyVoiteNo), 0);
        this.skippedCount = oss.Questions.reduce((s, x) => (s += x.CountWhyVoiteUnknow), 0);
        this.totalVoted = oss.Questions.reduce((s, x) => (s += x.AnswerTotalCount), 0);
        this.totalCount = oss.Questions.length * oss.TotalAccounts;
      } else if (oss.IsComplete || oss.Status.startsWith('Завершено')) {
        // User completed
        this.commonResult = false;
        this.yesCount = oss.Questions.filter((x) => x.Answer === '0').length;
        this.noCount = oss.Questions.filter((x) => x.Answer === '1').length;
        this.skippedCount = oss.Questions.filter((x) => x.Answer === '2').length;
        this.totalVoted = this.yesCount + this.noCount + this.skippedCount;
        this.totalCount = oss.Questions.length;
      } else {
        this.$router.replace({ name: 'OssVote', params: { id: this.idOss } });
        return;
      }

      if (oss.Accounts) {
        this.ossBlanks = oss.Accounts.filter((x) => x.HasVoitingBlankFile).map((x) => {
          return {
            name: 'Бланк голосования л/с ' + x.Ident,
            link: x.VoitingBlankFileLink,
          };
        });
      }
    },
    getOSS() {
      this.isLoading = true;
      this.$store
        .dispatch('oss/getOssById', this.idOss)
        .then((result) => {
          this.oss = result;
          this.isLoading = false;
          this.setupResults(result);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    idOss() {
      return this.$route.params.id;
    },
    totalAnswersExpected() {
      var oss = this.oss;
      if (!oss || !oss.Questions) {
        return 0;
      }
      return oss.Questions.length * oss.Accounts.length;
    },
  },
};
</script>

<style scoped></style>
