import * as openPollsApi from '@/api/openPolls';

const state = {
  /** Опрос */
  openPoll: null,

  /** Результат опроса */
  openPollResults: null
};

const getters = {
  /** Опрос */
  getOpenPoll: ({ openPoll }) => openPoll || [],

  /** Результат опроса */
  getOpenPollResults: ({ openPollResults }) => openPollResults || [],
};

const mutations = {
  /** Обновление опроса  */
  SET_OPEN_POLL: (state, data) => {
    state.openPoll = data;
  },

  /** Обновление результата опроса */
  SET_OPEN_POLL_RESULTS: (state, data) => {
    state.openPollResults = data;
  },

  /** Изменение статуса прохождения опроса */
  SET_OPEN_POLL_READED_FLAG: (state) => {
    state.openPoll.IsReaded = true;
  }
};

const actions = {
  /**
   * Получение информацию о голосовании
   *
   * @param   {Function}  commit
   * @param   {string}    code      Код доступа
   *
   * @return  {Object}              Информацию о голосовании
   */
  getInfo({ commit }, code) {
    return openPollsApi.getInfo(code).then((response) => {
        commit('SET_OPEN_POLL', response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Сохранение результата опроса
   *
   * @param   {[type]}  payload
   * @param   {[type]}  payload.Code       Код доступа
   * @param   {[type]}  payload.ExtraInfo  Дополнительная информация
   * @param   {[type]}  payload.Answers    Ответы на вопросы
   *
   * @return  {Object}                     Статус сохранения результата опроса
   */
  saveResult(_, payload) {
    return openPollsApi
      .saveResult(payload)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Получение результата опроса
   *
   * @param   {Function}  commit
   * @param   {string}    Code    Код доступа
   *
   * @return  {Object}            Результата опроса
   */
  getOpenPollResults({ commit }, code) {
    return openPollsApi
      .results(code)
      .then((response) => {
        commit('SET_OPEN_POLL_RESULTS', response.data.Data);
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Обновление информации о прочтении
   *
   * @param   {Function}  commit
   * @param   {string}    Code  Код доступа
   *
   * @return  {Object}          Статус обновления информации о прочтении
   */
  setReadedFlag({ commit }, code) {
    return openPollsApi
      .setReadedFlag(code)
      .then(() => {
        commit('SET_OPEN_POLL_READED_FLAG');
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};