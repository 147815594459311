export default {
  data() {
    return {
      logoIsExist: false,
    };
  },

  computed: {
    copyrightYear() {
      return new Date().getFullYear();
    },
  },

  methods: {
    dateNoTime(dateString) {
      if (!dateString) {
        return dateString;
      }

      return dateString.split(' ')[0];
    },

    dateOnlyTime(dateString) {
      if (!dateString) {
        return dateString;
      }

      const parts = dateString.split(' ');

      if (parts.length > 1) {
        return parts[1];
      }

      return '00:00';
    },

    truncateText(text, length) {
      if (text.length > length) {
        const position = text.substring(0, length).lastIndexOf(' ');

        if (position > 0) {
          return text.substring(0, position) + '...';
        }

        return text.substring(0, length) + '...';
      }

      return text;
    },

    checkAppLogo() {
      this.$store
        .dispatch('config/checkAppLogo', this.config.appIconFile)
        .then((response) => {
          this.logoIsExist = response;
        })
        .catch(() => {
          this.logoIsExist = false;
        });
    },
  },
};
