import { render, staticRenderFns } from "./FormBoxInput.vue?vue&type=template&id=0b369b78&scoped=true"
import script from "./FormBoxInput.vue?vue&type=script&lang=js"
export * from "./FormBoxInput.vue?vue&type=script&lang=js"
import style0 from "./FormBoxInput.vue?vue&type=style&index=0&id=0b369b78&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b369b78",
  null
  
)

export default component.exports