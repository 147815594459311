<template>
  <div :class="['toggler', { 'toggler--off': !value }, { 'toggler--on': value }]" @click="$emit('input', !value)">
    <div class="toggler__body">
      <div class="toggler__rect"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'toggler',

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      isOn: false,
    };
  },

  created() {
    this.isOn = this.value;
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

$onLeft: calc(100% - 16px)

.toggler
  display: inline-block
  cursor: pointer

  &--off
    .toggler__body
      background-color: $cIconNeutral
      border-color: $cIconNeutral

    .toggler__rect
      border-color: $cIconNeutral
      left: 0

  &--on
    .toggler__body
      background-color: $cAccentBlue

    .toggler__rect
      left: $onLeft

.toggler__body
  +Size(36px, 20px)
  display: flex
  align-items: center
  border: 2px solid $cAccentBlue
  border-radius: 10px
  position: relative
  transition: background-color .15s, border-color .15s

.toggler__rect
  +Size(16px)
  background-color: $cBgMain
  border: 2px solid $cAccentBlue
  border-radius: 50%
  box-sizing: content-box
  transform: translate(-2px, -2px)
  position: absolute
  top: 0
  transition: left .15s, outline-color .15s
</style>
