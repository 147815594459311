var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-box__field"},[_c('div',{class:[
    'field',
    'field--checkbox',
    { 'field--error': _vm.hasError },
    { 'field--active': _vm.isActiveState },
    { 'field--focus': _vm.fieldIsFocus },
    { 'field--disabled': _vm.disabled },
    ..._vm.modificators
  ]},[_c('div',{staticClass:"field__group",on:{"mousedown":function($event){_vm.isActiveState = (!_vm.disabled) ? true : false},"mouseup":function($event){_vm.isActiveState = false}}},[_c('span',{class:[
          'field__checkbox',
          { 'field__checkbox--checked': _vm.value }
        ],on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[(_vm.value)?_c('check-icon',{staticClass:"check",attrs:{"size":"16"}}):_vm._e(),_c('input',{ref:_vm.ref,attrs:{"type":"checkbox","id":_vm.id,"name":_vm.id,"disabled":_vm.disabled},on:{"focus":function($event){_vm.fieldIsFocus = true},"blur":function($event){_vm.fieldIsFocus = false},"change":function($event){return _vm.$emit('change', !_vm.value)}}})],1),_c('label',{staticClass:"field__label",attrs:{"for":_vm.id},on:{"click":function($event){return _vm.$emit('change', !_vm.value)}}},[_vm._t("default",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2)])]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasError),expression:"hasError"}],staticClass:"field__message field__message--error"},[_vm._v(" "+_vm._s(_vm.message)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }