<template>
  <modal 
    :title="data.title" 
    :setBaseStyle="true"
    class="modal--dialog dialog"
    :dialog="true"
    @close="$emit('close')"
  >
    <div v-loading="data.loading">
      <p class="dialog__text" v-html="data.text"></p>

      <div
        v-if="data.buttons" 
        class="dialog__buttons"
      >
        <base-button
          v-for="(button, i) in data.buttons"
          :key="i"
          :style-class="(button.type) ? button.type : 'base'"
          class="dialog__button"
          @click="button.handler"
        >
          {{ button.title }}
        </base-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'CloseRequestModal',

  components: {
    Modal,
    BaseButton,
  },

  props: {
    data: {
      type: Object,
      required: true,
      default() {
        return {};
      },
    },
  },

  data() {
    return {};
  },

  mounted() {
    if (this.data.timeout && this.data.timeout > 0) {
      setTimeout(() => {
        this.$emit('close');
      }, this.data.timeout);
    }
  },

  methods: {},
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.dialog__text
  +TextMain()
  text-align: center
  margin-bottom: 24px

.dialog__buttons
  display: flex
  justify-content: space-between

.dialog__button
  width: 100%
  margin: 0 calc(16px / 2)

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0
</style>
