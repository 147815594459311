<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    aria-labelledby="Water"
    role="presentation"
  >
    <path
      d="M12.5668 1.17544C12.3998 1.06116 12.202 1 11.9995 1C11.797 1 11.5992 1.06116 11.4322 1.17544C11.0877 1.41033 3.00009 7.00569 3.00009 13.8181C2.98927 16.2391 3.93249 18.5674 5.6266 20.3016C6.45272 21.1551 7.44278 21.8339 8.5377 22.2975C9.63262 22.7611 10.81 23 11.9995 23C13.189 23 14.3664 22.7611 15.4613 22.2975C16.5562 21.8339 17.5463 21.1551 18.3724 20.3016C20.067 18.5676 21.0106 16.2392 20.9999 13.8181C20.9999 7.00569 12.9113 1.41033 12.5668 1.17544ZM16.9335 18.9021C16.2947 19.564 15.5284 20.0907 14.6806 20.4504C13.8328 20.8101 12.921 20.9955 11.9996 20.9956C11.0783 20.9956 10.1664 20.8103 9.31858 20.4506C8.47076 20.091 7.70444 19.5645 7.06548 18.9026C5.73874 17.5418 5.00072 15.716 5.01021 13.8181C5.01021 9.00767 10.2416 4.5951 11.9995 3.24788C13.7584 4.5951 18.9898 9.00767 18.9898 13.8181C18.9995 15.7161 18.2611 17.5419 16.9335 18.9021ZM11.5127 7.29833C11.6913 7.49563 11.784 7.75553 11.7706 8.02097C11.7571 8.28641 11.6386 8.53568 11.441 8.71405C10.3889 9.66244 8.62411 11.544 8.62411 13.4892C8.62408 13.6187 8.63096 13.7481 8.64473 13.8768C8.6589 14.0076 8.64709 14.14 8.60997 14.2662C8.57285 14.3925 8.51114 14.5102 8.42837 14.6127C8.34561 14.7152 8.24341 14.8004 8.1276 14.8635C8.0118 14.9265 7.88467 14.9663 7.75348 14.9803C7.71729 14.9842 7.68092 14.9861 7.64453 14.9862C7.39674 14.986 7.15775 14.8946 6.9735 14.7293C6.78925 14.5641 6.67273 14.3368 6.64634 14.0912C6.62468 13.8913 6.61387 13.6903 6.61395 13.4892C6.61395 10.9059 8.50531 8.65827 10.0924 7.22742C10.2902 7.04917 10.551 6.95651 10.8173 6.96981C11.0836 6.9831 11.3337 7.10126 11.5127 7.29833Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'WaterIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
