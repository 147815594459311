<template>
  <div v-if="poll" class="poll-form" ref="$root">
    <h3 class="poll-form__name">{{ poll.Name }}</h3>

    <div class="poll-form__body">
      <div v-for="(question, i) in poll.Questions" :key="`question-${i}`" class="poll-form__question">
        <p class="poll-form__question-title" v-html="`${i + 1}. ${question.Text}`"></p>

        <div v-if="question.AnswerType === 'single' || question.AnswerType === null" class="poll-form__answers">
          <form-box-radioset
            v-model="form.find((item) => item.QuestionId === question.ID).AnswerId"
            :list="question.Answers"
            :disabled="question.IsCompleteByUser"
            vertical
            @input="collectAnswers()"
          />
        </div>

        <div v-if="question.AnswerType === 'multiple'" class="poll-form__answers">
          <form-box-checkbox
            v-for="answer in question.Answers"
            :key="`answer-${answer.ID}`"
            v-model="form.find((item) => item.QuestionId === question.ID && item.key === answer.value).AnswerId"
            :label="answer.Text"
            :disabled="question.IsCompleteByUser"
            @input="
              $event
                ? (form.find((item) => item.QuestionId === question.ID && item.key === answer.value).AnswerId =
                    answer.ID)
                : (form.find((item) => item.QuestionId === question.ID && item.key === answer.value).AnswerId = false);
              collectAnswers();
            "
          />
        </div>

        <div v-if="question.AnswerType === 'open'" class="poll-form__answers">
          <form-box-textarea
            v-model="form.find((item) => item.QuestionId === question.ID).CustomAnswer"
            :readonly="question.IsCompleteByUser"
            @input="collectAnswers()"
          />
        </div>
      </div>

      <div class="poll-form__buttons">
        <base-button :disabled="!validate" @click="onSendPoll()">{{ $t('submit_answers') }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';
import FormBoxCheckbox from '@/components/form/FormBoxCheckbox';
import FormBoxTextarea from '@/components/form/FormBoxTextarea';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'PollForm',

  components: {
    FormBoxRadioset,
    FormBoxCheckbox,
    FormBoxTextarea,
    BaseButton,
  },

  data() {
    return {
      form: [],
      collectedAnswers: [],
    };
  },

  computed: {
    ...mapGetters({
      detailPoll: 'polls/detailPoll',
    }),

    poll() {
      return {
        ...this.detailPoll,
        Questions: this.detailPoll.Questions.map((question) => ({
          ...question,
          Answers: question.Answers.map((answer) => ({
            ...answer,
            title: answer.Text,
            value: answer.ID,
          })),
        })),
      };
    },

    validate() {
      if (!this.collectedAnswers.length) {
        return false;
      }

      // NOTE: Допройти опрос нельзя
      // if (this.poll.Passed) {
      if (this.poll.PartiallyPassed) {
        return false;
      }

      return true;
    },

    //   collectedAnswers() {
    //     /**
    //      * Структура:
    //      * {
    //      *  PollId: <int pollId>,
    //      *  ExtrtaInfo: <string ?>, // В документации не описано, что это
    //      *  List: [
    //      *   {
    //      *    QuestionId: <int questionId>,
    //      *    AnswerId: <int answerId>, // id варианта ответа
    //      *    CustomAnswer: <string customAnswer>, // текст ответа для открытого вопроса
    //      *   },
    //      *   ...
    //      *  ]
    //      * }
    //      *
    //      * Для вопроса с единственным выбором должна присутствовать одна запись
    //      * в списке ответов с заполненым AnswerId
    //      * Для вопроса с множественным выбором может быть несколько записей ответов с заполненным AnswerId
    //      * Для открытого вопроса должно быть одна запись ответа с заполненным CustomAnswer
    //      */
    //     const temp = []

    //     // this.$nextTick(() => {
    //       this.form
    //         .map((answer) => {
    //           const type = this.poll.Questions
    //             .find(question => question.ID === answer.QuestionId)
    //             .AnswerType;

    //           if (["single", null].includes(type)) {
    //             if (answer.AnswerId) {
    //               temp.push({
    //                 QuestionId: answer.QuestionId,
    //                 AnswerId: answer.AnswerId,
    //               })
    //             }
    //           }

    //           if (type === "multiple") {
    //             if (answer.AnswerId) {
    //               temp.push({
    //                 QuestionId: answer.QuestionId,
    //                 AnswerId: answer.key,
    //               })
    //             }
    //           }

    //           if (type === 'open') {
    //             if (answer.CustomAnswer.length) {
    //               temp.push({
    //                 QuestionId: answer.QuestionId,
    //                 CustomAnswer: answer.CustomAnswer,
    //               })
    //             }
    //           }
    //         });
    //     // });

    //     return temp;
    //   }
  },

  watch: {
    '$route.params.id'(val, old) {
      if (val !== old && val) {
        this.initPollingResult();
        this.collectedAnswers = [];
      }
    },
  },

  created() {
    this.initPollingResult();
  },

  mounted() {
    this.setPollFormPadding();
  },

  methods: {
    ...mapActions({
      openPoll: 'polls/openPoll',
      saveResult: 'polls/saveResult',
      setReadedFlag: 'polls/setReadedFlag',
    }),

    initPollingResult() {
      // console.log('check')
      this.openPoll(+this.$route.params.id);

      this.form = [];

      this.poll.Questions.map((question) => {
        if (question.AnswerType === 'single') {
          this.form = [
            ...this.form,
            {
              QuestionId: question.ID,
              AnswerId: question.Answers[0].IsUserAnswer ? question.Answers[0].ID : false,
              CustomAnswer: '',
            },
          ];
          return;
        }

        if (question.AnswerType === 'multiple') {
          question.Answers.map((answer) => {
            this.form = [
              ...this.form,
              {
                QuestionId: question.ID,
                AnswerId: answer.IsUserAnswer ? answer.ID : false,
                CustomAnswer: '',
                key: answer.value,
              },
            ];
          });
          return;
        }

        if (question.AnswerType === 'open') {
          this.form = [
            ...this.form,
            {
              QuestionId: question.ID,
              AnswerId: false,
              CustomAnswer: '',
            },
          ];
          return;
        }
      });
    },

    onSendPoll() {
      this.saveResult({
        PollId: this.poll.ID,
        ExtrtaInfo: '',
        Answers: this.collectedAnswers,
      }).then((result) => {
        if (result.error) {
          this.$notify.error({
            title: this.$t('error'),
            message: this.$t('failed_to_complete_poll'),
            duration: 6000,
            offset: 100,
          });

          return;
        }

        if (result.isSucceed) {
          this.$emit('pass');

          this.$router.push({
            name: 'Polls',
            params: {
              id: this.poll.ID,
              subpage: 'results',
            },
          });
        }
      });
    },

    setPollFormPadding() {
      this.$nextTick(() => {
        if (this.isCustomView(768)) {
          const $root = this.$refs.$root;
          const height = document.querySelector('footer.footer').clientHeight;

          if ($root) {
            $root.style.paddingBottom = `${height}px`;
          }
        }
      });
    },

    collectAnswers() {
      this.collectedAnswers = [];

      this.form.map((answer) => {
        // console.log('answer:', answer)
        // console.log('questions:', this.poll.Questions)

        const type = this.poll.Questions.find((question) => question.ID === answer.QuestionId).AnswerType;

        // console.log('type:', type)

        if (['single', null].includes(type)) {
          if (answer.AnswerId) {
            this.collectedAnswers.push({
              QuestionId: answer.QuestionId,
              AnswerId: answer.AnswerId,
            });
          }
        }

        if (type === 'multiple') {
          if (answer.AnswerId) {
            this.collectedAnswers.push({
              QuestionId: answer.QuestionId,
              AnswerId: answer.key,
            });
          }
        }

        if (type === 'open') {
          if (answer.CustomAnswer.length) {
            this.collectedAnswers.push({
              QuestionId: answer.QuestionId,
              CustomAnswer: answer.CustomAnswer,
            });
          }
        }

        console.log('this.collectedAnswers:', this.collectedAnswers)
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/mixins';

.poll-form {
  height: 100%;
  position: relative;
  padding: 0;

  background: #ffffff;
  border: 1px solid $cBorder;
  border-radius: 16px 16px 0 0;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    height: auto;
    padding: 24px;
    position: initial;
    border-radius: 16px;
  }
}
.poll-form__name {
  @include TextHeader(1);

  padding: 16px;
  margin-bottom: 16px;
  background-color: $cWhite;
  border-bottom: 1px solid $cBorder;

  position: sticky;
  top: 0;
  z-index: 2;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    position: initial;
    padding: 0;
    margin-bottom: 40px;
    border-bottom: none;
  }
}
.poll-form__body {
  padding: 0 16px 16px;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    padding: 0;
  }
}
.poll-form__question:not(:last-child) {
  margin-bottom: 40px;
}
.poll-form__question-title {
  @include TextMain($cTextMain);

  margin-bottom: 16px;
}

// Form controls
::v-deep {
  .form-box__field {
    margin-bottom: 16px;
  }
  .form-box__field:last-child {
    margin-bottom: 0;
  }
  .field--radio .field__group {
    padding: 0;
  }
  .field--checkbox .field__group {
    height: auto;
    padding: 0;
  }
  .field--radio {
    margin: 0;
  }
}
</style>
