<template>
  <div class="form-box__field" @clickout="fieldClickoutHandler">
    <div
      :class="[
        'field',
        'field--select',
        { 'field--error': hasError },
        { 'field--active': showList || fieldIsFocus },
        { 'field--popover': showPopover },
        { 'field--selected-option': value },
      ]"
    >
      <div class="field__group" @click="fieldClickHandler">
        <div class="field__control">
          <label class="field__label">
            {{ label }}
          </label>
          <span class="field__selected-option">
            <slot name="selectedOption" :item="selectedOption">
              {{ selectedOption[keyPropName] }}
            </slot>
          </span>
        </div>
        <component
          :is="chevron"
          class="field__chevron"
          @click.stop="(showList = !showList), (fieldIsFocus = !fieldIsFocus)"
        />
      </div>

      <div v-show="showList" class="field__list-wrapper">
        <form-box-input
          v-if="search" 
          v-model="searchQuery"
          class="field__search-input" 
          type="text"
          @input="filterList"
          @click.stop
        />

        <perfect-scrollbar tag="ul" class="field__list" :style="`max-height: ${maxHeight}`">
          <li
            v-for="(item, i) in filteredList"
            :key="`select-option-${i}`"
            ref="option"
            :data-value="item[valuePropName]"
            class="field__option"
            @click="onSelectHandler"
          >
            <slot name="option" :item="item">
              {{ item[keyPropName] }}
            </slot>
          </li>
        </perfect-scrollbar>
        <ul class="field__list">
          <li
            v-if="allowSelectAll && list.length > 1"
            class="field__option field__option--select-all"
            @click="onSelectAllHandler"
          >
            {{ $t('select_all') }} ({{ list.length }})
          </li>
          <slot name="customOption" ></slot>
        </ul>
      </div>

      <p v-show="hasError && message" class="field__message">
        {{ message }}
      </p>
      <div v-show="showPopover" class="field__popover">
        <slot name="popover"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from '@vue-hero-icons/outline';
import FormBoxInput from '@/components/form/FormBoxInput'
export default {
  name: 'FormBoxSelect',

  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    FormBoxInput
  },

  props: {
    value: {
      type: [String, Number],
    },
    label: {
      type: String,
      required: true,
    },
    list: {
      type: [Array, Object],
      required: false,
      default() {
        return [];
      },
    },
    valuePropName: {
      type: String,
      required: false,
      default: 'key',
    },
    keyPropName: {
      type: String,
      required: false,
      default: 'value',
    },
    // optionsParams: {
    //   type: Object,
    //   required: false,
    //   default() {
    //     return {}
    //   }
    // },
    message: {
      type: String,
      required: false,
      default: '',
    },
    showPopover: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    allowSelectAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxShowOptions: {
      type: Number,
      required: false,
      default: 0,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      fieldIsFocus: false,
      showList: false,
      maxHeight: 'auto',
      searchQuery: '',
    };
  },

  computed: {
    chevron() {
      return `chevron-${this.fieldIsFocus ? 'up' : 'down'}-icon`;
    },

    selectedOption() {
      if (this.value) {
        return this.list.find((item) => {
          // TODO: Не строгое сравнение необходимо на случай того, что в массиве
          // число, а value есть строка
          return item[this.valuePropName] == this.value;
        });
      }

      return { [this.valuePropName]: null };
    },

    filteredList() {
      if (!this.searchQuery) {
        return this.list;
      }
      const query = this.searchQuery.toLowerCase();
      return this.list.filter(item => item[this.keyPropName].toLowerCase().includes(query));
    },
  },

  watch: {
    showList(val, old) {
      if (val !== old && val && this.list) {
        this.setListHeight();
      }
    },
  },

  methods: {
    fieldClickHandler() {
      this.openList();
    },

    fieldClickoutHandler() {
        this.closeList();
    },
    test() {
      if(this.showList || this.fieldIsFocus) {
        this.closeList();
      } else {
        this.openList()
      }
    },
    onSelectHandler(e) {
      // onSelectHandler() {
      // console.log('this.selectedOption:', this.selectedOption)
      this.$emit('input', e.target.dataset.value);
      // this.$emit('input', this.value);
      this.closeList();
    },

    onSelectAllHandler() {
      // console.log('--- onSelectAllHandler ---')
      this.$emit('input', null);
      this.closeList();
    },

    openList() {
      this.fieldIsFocus = true;
      this.showList = true;
    },

    closeList() {
      this.fieldIsFocus = false;
      this.showList = false;
    },

    setListHeight() {
      this.$nextTick(() => {
        if (this.maxShowOptions > 0 && this.maxShowOptions < this.list.length) {
          let maxHeight = 0;
          const options = this.$refs['option'].slice(0, this.maxShowOptions);

          options.map((option) => {
            maxHeight += option.offsetHeight;
          });

          this.maxHeight = `${maxHeight}px`;
        }
      });
    },

    filterList() {
      this.$nextTick(() => {
        this.setListHeight();
      });
    },
  },
};
</script>


<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>
