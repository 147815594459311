<template>
  <accordion 
    v-if="items.length" 
    class="meters-accordion" 
    :items="items"
    @sm-accordion:toggle-item="toggleItem"
  >
    <!-- Header -->
    <template 
      v-slot:header="{ item }"
      class="meters-accordion__header"
    >
      <div class="header">
        <div class="header__column header__column--left">
          <div class="header__icon-box">
            <ui-icons
              :iconName="getIconName(item.Resource)"
              :iconColor="getIconColor(item.Resource)"
              width="24"
              height="24"
              class="header__icon"
            />
          </div>
          <div class="header__info">
            <h3 class="header__title">
              {{ item.Resource }}
            </h3>
            <p class="header__subtitle">
              {{ item.CustomName || item.Name }}
            </p>
          </div>
        </div>
        <div class="header__column header__column--right">
          <template v-if="item.AutoValueGettingOnly">
            <badge
              :caption="$t('automatic_withdrawal')"
              type="success"
            />
          </template>
          <template v-else-if="item.IsDisabled">
            <badge
              :caption="$t('meter_is_out')"
              type="danger"
            />
          </template>
          <template v-else>
            <template 
              v-if="item.Values.length"
            >
              <badge
                v-if="item.Values[0]?.IsCurrentPeriod && !item.Values[0]?.IsAccepted"
                :caption="$t('readings_transmitted')"
                type="success"
              />
            </template>
          </template>
        </div>
      </div>
    </template>

    <!-- Content -->
    <template 
      v-slot:content="{ item, index }"
      class="meters-accordion__meter-statistic"
    >
      <div class="meter-statistic">
        <div class="meter-statistic__header">
          <div class="meter-statistic__value">
            <span class="meter-statistic__value-title">{{ $t('factory') }} №:</span>
            <span class="meter-statistic__value-data">
              {{ item.FactoryNumber }}
            </span>
          </div>

          <div class="meter-statistic__value">
            <span class="meter-statistic__value-title">{{ $t('next_verification') }}:</span>
            <span class="meter-statistic__value-data">
              {{ item.NextCheckupDate || '-' }}
            </span>
          </div>

          <div class="meter-statistic__value">
            <span class="meter-statistic__value-title">
              {{ $t('calibration_interval') }}:
            </span>
            <span class="meter-statistic__value-data">
              {{ item.RecheckInterval }} {{ $t('years') }}
            </span>
          </div>
        </div>

        <div class="meter-statistic__body">
          <!-- Form -->
          <form
            v-if="!item.AutoValueGettingOnly"
            class="meter-statistic__form"
          >
            <p class="meter-statistic__form-title">{{ $t('meter_readings') }}</p>
            <p class="meter-statistic__form-previous-value">
              {{ $t('previous_readings') }}:
              {{ getPrevValues(item.ID) }}
            </p>
            
              <div 
                v-if="item.ValuesCanAdd"
                class="meter-statistic__form-fields"
              >
                <div
                  v-for="i in item.TariffNumberInt"
                  :key="`tariff-${i}`"
                  class="meter-statistic__form-field"
                >
                  <form-box-input
                    v-model="values[i]"
                    :refName="`MeterControl${item.ID}${index}${i}`"
                    type="number"
                    :label="(item.TariffNumberInt < 2) ? `${$t('readings')}` : `${$t('readings')} ${i}`"
                  />
                  <span 
                    v-if="item.TariffNumberInt > 1 && !!item.Values?.[0]?.Period"
                    class="meter-statistic__form-caption"
                  >
                    За {{ item.Values[0]?.Period }}
                  </span>
                </div>

                <trash-icon
                  v-if="(!item.AutoValueGettingOnly &&
                    !item.IsDisabled &&
                    getDiffCheckupDate(item.NextCheckupDate) > 0) && 
                    (item.Values.length &&
                    item.Values[0]?.IsCurrentPeriod)"
                  style-class="secondary"
                  :disabled="(item.AutoValueGettingOnly)"
                  :title="$t('delete_readings')"
                  class="meter-statistic__remove"
                  @click="removeValue(item.ID)"
                />
              </div>
          </form>

          <!-- Где-то тут ошибка -->
          <div v-else class="meter-statictic__auto-data">
            <div class="auto-data">
              <alert
                :caption="autoValueGettingComment(item)"
                type="info"
              />

              <div
                v-if="item.Values" 
                class="auto-data__table"
              >
                <div class="table">
                  <template v-for="(col, i) in convertValues(item)">
                    <div
                      v-if="!!col"
                      :key="`table-column-${i}`" 
                      :class="[
                        'table__column',
                        ...((col.modificators) 
                        ? col.modificators.map((mod) => (mod) 
                          ? `table__column--${mod}` 
                          : '') 
                        : []
                        )
                      ]"
                    >
                        <span
                          v-for="(row, j) in col.values"
                          :key="`table-row-${j}`"
                          :class="[
                            'table__row',
                            ...((row.modificators) ? 
                              row.modificators.map((mod) => (mod) ? `table__row--${mod}` : '') : 
                              []
                            ),
                            { 'table__row--empty': !row.value }
                          ]"
                        >
                          <template v-if="row.button">
                            <button
                              v-on="row.button.events"
                              class="table__button"
                            >
                              {{ row.button.title }}
                            </button>
                          </template>
                          <template v-else>
                            {{ (row.value) ? row.value : '...' }}
                          </template>
                        </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>

          <!-- History -->
          <div class="meter-statistic__history">
            <div class="history">
              <base-button
                style-class="secondary-text"
                class="history__button"
                @click="[showHistory = !showHistory, getHistory(item.ID, item.UniqueNum)]" 
              >
                <clock-icon class="history__icon" />
                {{ $t('readings_history') }}
                <chevron-down-icon 
                  :class="[
                    'history__chevron',
                    { 'history__chevron--up': showHistory }
                  ]" 
                />
              </base-button>

              <base-table
                v-if="showHistory && uniqueNumHistory === item.UniqueNum" 
                v-loading="loadingHistory"
                :header="history.header"
                :body="history.body"
                type="white"
                class="history__table" 
              />
            </div>
          </div>
        </div>

        <div class="meter-statistic__footer">
          <template
            v-if="!item.AutoValueGettingOnly &&
              !item.IsDisabled &&
              getDiffCheckupDate(item.NextCheckupDate) > 0"
          >
            <base-button
              v-if="item.ValuesCanAdd"
              style-class="secondary"
              :disabled="(item.AutoValueGettingOnly)"
              class="meter-statistic__button"
              @click="sendValues(item.ID)"
            >
              <template
                v-if="item.Values.length &&
                  item.Values[0]?.IsCurrentPeriod"
              >
                {{ $t('change_readings') }}
              </template>
              <template v-else>
                {{ $t('submit_readings') }}
              </template>
            </base-button>
            <alert 
              v-else
              :caption="item.PeriodMessage"
              type="info"
            />
          </template>

          <template
            v-if="item.AllowMeterVerificationRequest &&
              getDiffCheckupDate(item.NextCheckupDate) <= 30 &&
              verificationService"
          >
            <base-button
              style-class="secondary"
              class="meter-statistic__button"
              :disabled="isNotAllowedVerification()"
              @click="openVerificationConfirmationModal(item.ID)"
            >
              {{ $t('order_verification') }}
            </base-button>
          </template>
        </div>
      </div>
    </template>
  </accordion>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { ClockIcon, ChevronDownIcon, TrashIcon } from '@vue-hero-icons/outline';
import UiIcons from '@/components/ui/icons/UIIcons';
import Accordion from '@/components/ui/Accordion';
import FormBoxInput from '@/components/form/FormBoxInput';
import Badge from '@/components/ui/Badge';
import Alert from '@/components/ui/Alert';
import BaseTable from '@/components/ui/BaseTable';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'MetersList',

  components: {
    Accordion,
    UiIcons,
    FormBoxInput,
    ClockIcon,
    ChevronDownIcon,
    TrashIcon,
    Badge,
    Alert,
    BaseTable,
    BaseButton,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showHistory: false,
      uniqueNumHistory: null,
      loadingHistory: false,
      values: {
        1: '',
        2: '',
        3: '',
      }
    }
  },

  computed: {
    ...mapState(['additionalService']),

    ...mapGetters({
      getMeterValuesGetter: 'meter/getMeterValues'
    }),

    meterValues() {
      return this.getMeterValuesGetter;
    },

    autoValueGettingComment() {
      return (item) => item.AutoValueGettingComment || this.$t('readings_transmitted_automatically')
    },
    
  },

  methods: {
    ...mapActions({
      saveValues: 'meter/saveMeterValue',
      getMeterValues: 'meter/getMeterValues',
      deleteMeterValue: 'meter/deleteMeterValue',
    }),

    getPrevValues(id) {
      const item = this.items.find(item => item.ID === id);
      let vals = [];

      if (item.TariffNumberInt === 1) {
        vals.push(item.CheckValue);
      } else {
        vals.push(`${item.Tariff1Name || 'T1'} - ${item.CheckValue}`)
      }

      if (item.TariffNumberInt >= 2) { 
        vals.push(`${item.Tariff2Name || 'T2'} - ${item.CheckValueT2}`);
      }

      if (item.TariffNumberInt === 3) {
        vals.push(`${item.Tariff3Name || 'T3'} - ${item.CheckValueT3}`);
      }

      return vals.join(', ');
    },

    convertValues(item) {
      if (item.Values && item.Values.length) {
        return [
          item.TariffNumberInt > 1
            ? {
                values: [
                  { value: null },
                  { value: null },
                  { value: `${item.Tariff1Name || 'T1'}` },
                  {
                    value: `${item.Tariff2Name || 'T2'}`,
                    modificators: [
                      !(item.TariffNumberInt > 1) ? 'hide' : null,
                    ],
                  },
                  {
                    value: `${item.Tariff3Name || 'T3'}`,
                    modificators: [
                      !(item.TariffNumberInt > 2) ? 'hide' : null,
                    ],
                  },
                  { value: null },
                ],
                modificators: [
                  !(item.TariffNumberInt > 1) ? 'hide' : null,
                ],
              }
            : null,
          ...item.Values.map(val => {
            return {
              values: [
                { value: val.Kind },
                { value: val.Period, modificators: ['mini'] },
                { value: val.Value, modificators: ['offset-top'] },
                {
                  value: val.Value2,
                  modificators: [
                    !(item.TariffNumberInt > 1) ? 'hide' : null,
                  ],
                },
                {
                  value: val.Value3,
                  modificators: [
                    !(item.TariffNumberInt > 2) ? 'hide' : null,
                  ],
                },
                {
                  value: '',
                  modificators: [!val.IsCurrentPeriod ? 'hide' : null],
                  button: {
                    events: { click: () => { this.removeValue(item.ID) } },
                    title: this.$t('delete'),
                  },
                },
              ],
            };
          }),
          item.StartValue
            ? {
                values: [
                  { value: 'Начальные' },
                  { value: null },
                  { value: item.StartValue },
                  {
                    value: `${item.StartValue2 || 0}`,
                    modificators: [
                      !(item.TariffNumberInt > 1) ? 'hide' : null,
                    ],
                  },
                  {
                    value: `${item.StartValue3 || 0}`,
                    modificators: [
                      !(item.TariffNumberInt > 2) ? 'hide' : null,
                    ],
                  },
                  { value: null },
                ],
              }
            : null,
        ]
      }

      return []
    },

    getHistory(id, uniqueNum) {
      this.loadingHistory = true;
      // const item = this.items.find((item) => item.ID === id);
      this.getMeterValues({
        meterUniqueNum: uniqueNum
      })
        .then((result) => {
          if (result.length) {
            const meter = this.items.find((item) => item.ID === id);
            this.uniqueNumHistory = uniqueNum;
            
            let body = [];

            this.meterValues.map((val) => {
              body.push([
                {
                  value: val.Period,
                  textColor: '#A1A3B1'
                },
                (() => {
                  let returnData = {};

                  if (meter.TariffNumberInt === 1) {
                    returnData = {
                      value: val.Value,
                      align: 'right'
                    }
                  }

                  if (meter.TariffNumberInt > 1) {
                    returnData = {
                      value: `
                        ${meter.Tariff1Name || 'T1'}: ${val.Value}
                        ${(meter.TariffNumberInt >= 2) ? `, ${meter.Tariff2Name || 'T2'}: ${val.ValueT2 || 'нет'}` : ''}
                        ${(meter.TariffNumberInt === 3) ? `, ${meter.Tariff3Name || 'T3'}: ${val.ValueT3 || 'нет'}` : ''}
                      `,
                      align: 'right'
                    }
                  }

                  return returnData;
                })(),
                {
                  value: val.Kind,
                  align: 'center'
                }
              ]);
            });

            if (meter.StartValue) {
              body.push([
                {
                  value: ''
                },
                (() => {
                  let returnData = {};

                  if (meter.TariffNumberInt === 1) {
                    returnData = {
                      value: meter.StartValue,
                      align: 'right'
                    }
                  }

                  if (meter.TariffNumberInt > 1) {
                    returnData = {
                      value: `
                        ${meter.Tariff1Name || 'T1'}: ${meter.StartValue}
                        ${(meter.TariffNumberInt >= 2) ? `, ${meter.Tariff2Name || 'T2'}: ${meter.StartValue2 || '0'}` : ''}
                        ${(meter.TariffNumberInt === 3) ? `, ${meter.Tariff3Name || 'T3'}: ${meter.StartValue3 || '0'}` : ''}
                      `,
                      align: 'right'
                    }
                  }

                  return returnData;
                })(),
                {
                  value: 'Начальные',
                  align: 'center'
                }
              ]);
            }

            this.history = {
              header: [
                {
                  title: this.$t('date'),
                  width: '25%'
                },
                {
                  title: this.$t('readings'),
                  width: '50%',
                  align: 'right'
                },
                {
                  title: this.$t('status'),
                  width: '25%',
                  align: 'center'
                },
              ],
              body: body
            }
          }
        })
        .catch((error) => {
          this.$notify.error(error)
        })
        .finally(() => {
          this.loadingHistory = false;
        })
    },

    sendValues(id) {
      const item = this.items.find(item => item.ID === id);

      if (this.values[1] < item.CheckValue
          || item.TariffNumberInt > 1 && this.values[2] < item.CheckValue2
          || item.TariffNumberInt > 2 && this.values[3] < item.CheckValue3) {
        this.$notify.error(this.$t('new_value_cannot_be_less'));
      } else {
        // this.isSending = true;

        this.saveValues({ 
          Value: this.values[1], 
          ID: id, 
          ValueT2: this.values[2], 
          ValueT3: this.values[3] 
        })
          .then(() => {
            this.setLastValues(id);
            // this.isSending = false
            // this.$root.$emit('added-meter')
            // this.$emit('close')
            this.$notify.success(this.$t('new_readings_submitted'));

            this.$emit('meters-list:values-sent', id);
          });
          // .catch(() => this.isSending = false)
      }
    },

    toggleItem(index) {
      this.showHistory = false;
      this.setLastValues(this.items[index].ID);
    },

    getDiffCheckupDate(nextCheckupDate) {
      let diff;

      if (nextCheckupDate) {
        let checkupDate = nextCheckupDate.split('.');
        checkupDate = new Date(
          checkupDate[2],
          parseInt(checkupDate[1]) - 1,
          checkupDate[0],
        );
        const nowDate = new Date();
        diff = (checkupDate - nowDate) / (1000 * 60 * 60 * 24) + 1;
      }
      if (diff) {
        return diff;
      } else {
        diff = 9999;
      }

      return diff;
    },

    openVerificationConfirmationModal(meterId) {
      if (!this.isNotAllowedVerification) {
        this.selectedMeter = this.getMeterById(meterId);
        this.$modal.show('createCheckupRequest');
      }
    },

    verificationService() {
      let service = Object.entries(this.additionalService.services).find(
        item => {
          return item[1].Name === 'Поверка (замена) прибора';
        },
      );

      if (service) {
        return service[1];
      }

      return null;
    },

    isNotAllowedVerification() {
      const foundedMeter = this.items.find(meter => meter.ExistVerificationRequest);

      //Если есть хоть один счетчик с ExistVerificationRequest=true
      //То отключаем для всего ЛС все кнопки заказа поверки
      return !!foundedMeter;
    },

    removeValue(id) {
      this.openDialog({
        title: this.$t('deleting_meter_readings'),
        text: this.$t('really_possible_to_delete_transmitted_meter_readings'),
        buttons: [
          {
            title: this.$t('cancel'),
            type: 'additional',
            handler: () => {
              this.cancelDialog()
            }
          },
          {
            title: this.$t('delete'),
            type: 'base',
            handler: () => {
              this.isLoading = true;

              this.deleteMeterValue({ ID: id })
                .then((result) => {
                  if (result.isSucceed) {
                    this.$emit('meters-list:removed-value');
                  }
                })
                .catch(error => {
                  this.$notify.error(error);
                })
                .finally(() => {
                  this.isLoading = false;
                });

              this.cancelDialog();
            }
          },
        ]
      })
    },

    setLastValues(id) {
      const meter = this.items.find((meter) => meter.ID === id);

      if ((!meter.AutoValueGettingOnly &&
        !meter.IsDisabled &&
        this.getDiffCheckupDate(meter.NextCheckupDate) > 0) && 
        (meter.Values.length && meter.Values[0].IsCurrentPeriod)) {
        this.values['1'] = meter.Values[0].Value;
        this.values['2'] = meter.Values[0].ValueT2;
        this.values['3'] = meter.Values[0].ValueT3;
      } else {
        this.values['1'] = '';
        this.values['2'] = '';
        this.values['3'] = '';
      }
    },

    getIconName(resource) {
      if (resource === 'Холодная вода' || resource === 'Горячая вода') {
        return 'Water';
      }

      if (resource === 'Газ') {
        return 'Gas';
      }

      if (resource === 'Бытовой газ в баллонах') {
        return 'HouseholdGas'
      }

      if (resource === 'Электрическая энергия') {
        return 'Electricity';
      }

      if (resource === 'Твердое топливо') {
        return 'SolidFuel';
      }

      if (resource === 'Сточные воды') {
        return 'Wastewater';
      }

      if (resource === 'Тепловая энергия') {
        return 'ThermalEnergy';
      }

      return 'UndefinedMeter';
    },

    getIconColor(resource) {
      if (resource === 'Холодная вода' || resource === 'Газ' || resource === 'Бытовой газ в баллонах') {
        return '#97D8FD';
      }

      if (resource === 'Горячая вода') {
        return '#FE8F8F'
      }

      if (resource === 'Электрическая энергия') {
        return '#FDDA97';
      }

      if (resource === 'Твердое топливо' || resource === 'Тепловая энергия') {
        return '#77838A';
      }

      if (resource === 'Сточные воды') {
        return '#98AAD9';
      }

      return '#B8B2B2';
    },
  },
};
</script>

<style lang="sass">
@import '../../../sass/variables'
@import '../../../sass/mixins'

.meters-accordion
  .header
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    align-items: center
    width: 100%

    @media screen and (min-width: 768px)
      flex-wrap: nowrap

  .header__column
    display: flex
    align-items: center

    &--left
      flex-shrink: 1
      margin-bottom: 4px

      @media screen and (min-width: 768px)
        margin-bottom: 0

    &--right
      flex-shrink: 0

  .header__title
    +TextHeader(2)
    margin-bottom: 0

    @media screen and (min-width: 768px)
      margin-bottom: 4px

  .header__subtitle
    +TextMini()
    display: inline-block
    width: 100%
    max-width: 210px
    margin-bottom: 0

  .header__icon-box
    width: 28px
    height: 28px
    padding: 2px
    margin-right: 16px

    border-radius: 8px
    background-color: $cBgSec

    @media screen and (min-width: 768px)
      width: 40px
      height: 40px
      padding: 8px
      margin-right: 24px

  .header__icon
    +Size(20px)

    @media screen and (min-width: 768px)
      +Size(24px)

  .meter-statistic
    background-color: $cBgSec
    border-radius: 16px

    .auto-data
      padding: 16px

      .table
        display: flex

      .table__column
        display: flex
        flex-direction: column

        &:not(:last-child)
          margin-right: 40px

      .table__row
        +TextMain($cTextMain)
        display: block

        &--mini
          +TextMini()

        &--offset
          margin-top: 8px

        &--empty
          visibility: hidden

        &--hide
          display: none

    .auto-data__table
      margin-top: 24px

    .history
      // display: flex
      align-items: center
      background-color: #F2F6FA

    .history__button
      margin: 0 24px

    .history__icon
      +Size(22px)
      margin-right: 8px

    .history__chevron
      +Size(16px)
      +Transition((transform))
      color: $cIconNeutral
      margin-left: 8px

      &--up
        transform: rotate(180deg)

  .meter-statistic__header
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding: 16px
    border-bottom: 1px solid $cBorder

    @media screen and (min-width: 768px)
      flex-wrap: nowrap
      padding: 16px 24px

  .meter-statistic__value
    display: inline
    width: 100%
    margin: 0

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

    @media screen and (min-width: 768px)
      margin: 0 calc(8px / 2)
      width: auto

  .meter-statistic__value-title
    +TextMini()
    display: inline-block
    margin-right: 4px

  .meter-statistic__value-data
    +TextMain($cTextMain)

  .meter-statistic__form
    padding: 12px 12px 0 12px

    @media screen and (min-width: 768px)
      padding: 24px 24px 0 24px

  .meter-statistic__form-title
    +TextSubMain($cTextMain)

  .meter-statistic__form-previous-value
    +TextMain($cTextMain)

  .meter-statistic__form-fields
    display: flex

  .meter-statistic__form-field
    width: 100%
    margin: 0

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

    @media screen and (min-width: 768px)
      width: 196px
      margin: 0 calc(24px / 2)

  .meter-statistic__form-caption
    +TextMini()
    display: block
    padding: 0 16px
    margin-top: -20px
    margin-bottom: 32px

  .meter-statistic__footer
    display: flex
    justify-content: flex-end
    padding: 16px 24px

  .meter-statistic__button
    width: 100%

    @media screen and (min-width: 576px)
      width: auto

  .meter-statistic__history-table
    width: 100%
    border-collapse: collapse

  .meter-statistic__history-table-head-cell,
  .meter-statistic__history-table-cell
    padding: 6px 24px

    font-size: 13px
    font-weight: 400
    line-height: 16px
    color: #A1A3B1

    border: 1px solid #DEE3E9

    &:first-of-type
      text-align: left
      border-left: none

    &:nth-of-type(2)
      text-align: right

    &:last-of-type
      text-align: center
      border-right: none

  .meter-statistic__history-table-cell
    &:nth-of-type(2),
    &:nth-of-type(3)
      color: #1B1F3B

  .meter-statistic__remove
    color: $cIconNeutral
    cursor: pointer
    margin-top: 15px

.meters-accordion__header
  display: flex
  justify-content: space-between
  width: 100%
</style>
