<template>
  <Modal @close="$emit('close')" class="modal-content_testimony">
    <div class="testimony-item_header"><span :class="['item-icon', ...icon]"></span>{{ meter.Resource }}</div>
    <ul class="testimony-item_top-list">
      <li>
        Заводской №: <span class="digit">{{ meter.FactoryNumber }}</span>
      </li>
      <li>
        Последняя поверка: <span class="digit">{{ meter.LastCheckupDate || '-' }}</span>
      </li>
      <li>
        Межповерочный интервал: <span class="digit">{{ meter.RecheckInterval }} лет</span>
      </li>
    </ul>
    <form v-on:submit.prevent class="testimony-form" v-loading="isSending">
      <table class="testimony-form-table">
        <tr>
          <td></td>
          <td><span class="old-testimony_subtitle">Предыдущие показания</span></td>
          <td><span class="new-testimony_subtitle">Новые показания</span></td>
        </tr>
        <tr>
          <td>
            <span v-if="meter.TariffNumberInt > 1">{{ meter.Tariff1Name || 'T1' }}</span>
          </td>
          <td style="letter-spacing: 10px; text-align: right" class="testimony-form_list-mod">
            {{ previousValue }}
          </td>
          <td class="testimony-form_list">
            <input
              type="text"
              class="digit-item"
              placeholder="0.000"
              :ref="`meterInput`"
              @keypress="isNumber($event)"
              v-model.number="value"
            />
          </td>
        </tr>
        <tr v-if="meter.TariffNumberInt > 1">
          <td>{{ meter.Tariff2Name || 'T2' }}</td>
          <td style="letter-spacing: 10px; text-align: right" class="testimony-form_list-mod">
            {{ previousValue2 }}
          </td>
          <td class="testimony-form_list">
            <input
              type="text"
              class="digit-item"
              placeholder="0.000"
              :ref="`meterInput2`"
              @keypress="isNumber($event)"
              v-model.number="value2"
            />
          </td>
        </tr>
        <tr v-if="meter.TariffNumberInt > 2">
          <td>{{ meter.Tariff3Name || 'T3' }}</td>
          <td style="letter-spacing: 10px; text-align: right" class="testimony-form_list-mod">
            {{ previousValue3 }}
          </td>
          <td class="testimony-form_list">
            <input
              type="text"
              class="digit-item"
              placeholder="0.000"
              :ref="`meterInput3`"
              @keypress="isNumber($event)"
              v-model.number="value3"
            />
          </td>
        </tr>
      </table>

      <input type="submit" class="testimony_form_submit main-btn" @click="send" value="Передать показания" />
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';

export default {
  name: 'addMeterModal',
  components: { Modal },
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      meter: {},
      previousValue: 0,
      previousValue2: 0,
      previousValue3: 0,
      value: 0,
      value2: 0,
      value3: 0,
      isSending: false,
    };
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    initEditor: function () {
      this.meter = this.$store.getters['meter/getMeterById'](this.id);

      if (this.meter.Values.length > 0) {
        this.value = this.meter.Values[0].Value;
        this.value2 = this.meter.Values[0].ValueT2;
        this.value3 = this.meter.Values[0].ValueT3;
      }

      this.previousValue = this.meter.CheckValue;
      this.previousValue2 = this.meter.CheckValueT2;
      this.previousValue3 = this.meter.CheckValueT3;
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 44) {
        evt.preventDefault();
        this.$refs['meterInput'].value = this.$refs['meterInput'].value + '.';
      } else if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46 && charCode !== 44) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    send() {
      // Check is more or equal to previous
      if (
        this.value < this.previousValue ||
        (this.meter.TariffNumberInt > 1 && this.value2 < this.previousValue2) ||
        (this.meter.TariffNumberInt > 2 && this.value3 < this.previousValue3)
      ) {
        this.$notify.error({
          position: 'top-left',
          title: 'Ошибка',
          message: 'Новое значение не может быть меньше предыдущего',
        });
      } else {
        this.isSending = true;
        this.$store
          .dispatch('meter/saveMeterValue', {
            Value: this.value,
            ID: this.id,
            ValueT2: this.value2,
            ValueT3: this.value3,
          })
          .then(() => {
            this.value = [];
            this.isSending = false;
            this.$root.$emit('added-meter');
            this.$emit('close');
          })
          .catch(() => (this.isSending = false));
      }
    },
  },
  computed: {
    icon() {
      if (!this.meter.Resource) {
        return [];
      }

      if (['Горячее водоснабжение', 'Горячее ВС'].indexOf(this.meter.Resource) >= 0) {
        return ['glipf-drop'];
      } else if (['Холодное водоснабжение', 'Холодное ВС'].indexOf(this.meter.Resource) >= 0) {
        return ['glipf-drop', 'glipf-drop_mod'];
      } else if (this.meter.Resource.match(/(электроснабжение)/i) !== null) {
        return ['glipf-electricity'];
      }

      return [];
    },
  },
  watch: {},
};
</script>
