export default {
  ru: {
    account: 'Личный кабинет',
    login_to_account: 'Вход в личный кабинет',
    register_using_phone_number: 'Если Вы ранее входили по номеру лицевого счета или логину, зарегистрируйтесь по номеру телефона и подключите свой лицевой счет.',
    welcome: 'Добро пожаловать',
    to_account: 'в личный кабинет',
    delete_account: 'Удалить аккаунт',
    deleting_account: 'Удаление аккаунта',
    sure_delete_account: 'Вы уверены, что хотите удалить аккаунт?',
    account_deleted: 'Ваш аккаунт удален',
    log_out_of_account: 'Выйти из аккаунта',
    return_to_account: 'Вернуться в личный кабинет',
  },

  en: {
    account: 'Personal account',
    login_to_account: 'Login to your personal account',
    register_using_phone_number: 'If you previously logged in using your personal account number or login, register using your phone number and connect your personal account.',
    welcome: 'Welcome',
    to_account: 'to your personal account',
    delete_account: 'Delete account',
    deleting_account: 'Deleting an account',
    sure_delete_account: 'Are you sure you want to delete your account?',
    account_deleted: 'Your account has been deleted',
    log_out_of_account: 'Log out of your account',
    return_to_account: 'Return to your personal account',
  },
}