<template>
  <header class="header" ref="header">
    <div class="header__container">
      <div class="header__logo">
        <template v-if="config.logoLink">
          <a :href="config.logoLink()" class="logo">
            <div class="logo__img">
              <img :src="logoSrc" :alt="config.main_name" />
            </div>
            <span class="logo__name">{{ config.main_name }}</span>
          </a>
        </template>
        <template v-else>
          <div class="logo">
            <div class="logo__img">
              <img :src="logoSrc" :alt="config.main_name" />
            </div>
            <span class="logo__name">{{ config.main_name }}</span>
          </div>
        </template>
      </div>

      <nav class="header__menu">
        <menu-icon size="32" class="header__menu-toggler" @click="showMenuMobile = !showMenuMobile" />

        <!-- Mobile menu -->
        <ul :class="['menu', 'menu--mobile', { 'menu menu--show': showMenuMobile }]">
          <perfect-scrollbar>
            <li class="menu__item" @click="showMenuMobile = false">
              <router-link
                :to="{ name: 'Profile' }"
                class="menu__link menu__link--profile"
                active-class="menu__link--active"
              >
                <div class="header__profile-avatar">
                  <user-icon class="header__profile-icon" size="16" />
                </div>
                {{ userName }}
              </router-link>
            </li>
            <li class="menu__item" @click="showMenuMobile = false">
              <router-link :to="{ name: 'Home' }" class="menu__link" active-class="menu__link--active">
                {{ $t('events') }}
              </router-link>
            </li>
            <li v-if="menuItems.Payment" class="menu__item" @click="showMenuMobile = false">
              <router-link :to="{ name: 'Payment' }" class="menu__link" active-class="menu__link--active">
                {{ $t('payment') }}
              </router-link>
            </li>
            <li v-if="menuItems.Meters" class="menu__item" @click="showMenuMobile = false">
              <router-link :to="{ name: 'Meters' }" class="menu__link" active-class="menu__link--active">
                {{ $t('readings') }}
              </router-link>
            </li>
            <li
              v-if="menuItems.Requests && !accessOSS && !hideRequests"
              class="menu__item"
              @click="showMenuMobile = false"
            >
              <router-link :to="{ name: 'Requests' }" class="menu__link" active-class="menu__link--active">
                {{ $t('requests') }}
                <span v-if="getUnreadRequests.length" class="menu__badge">
                  {{ getUnreadRequests.length > 99 ? '99+' : getUnreadRequests.length }}
                </span>
              </router-link>
            </li>
            <li v-if="config.showOurService" class="menu__item" @click="showMenuMobile = false">
              <router-link :to="{ name: 'Services' }" class="menu__link" active-class="menu__link--active">
                {{ $t('our_service') }}
              </router-link>
            </li>
            <li v-if="accessOSS" class="menu__item" @click="showMenuMobile = false">
              <router-link :to="{ name: 'Oss' }" class="menu__link" active-class="menu__link--active">
                {{ $t('meetings') }}
              </router-link>
            </li>
            <li v-if="showOSSLink" class="menu__item">
              <a href="https://oss.sm-center.ru/" class="menu__link"> {{ $t('meetings') }} </a>
            </li>
            <li class="menu__item" @click="showMenuMobile = false">
              <a href="javascript:;" class="menu__link" @click.prevent="openSupportModalChat">{{ $t('support') }}</a>
            </li>
            <li class="menu__item">
              <a :href="`tel:${config.clearPhone}`" class="menu__link">
                {{ config.phone }}
              </a>
            </li>
          </perfect-scrollbar>
        </ul>

        <!-- Desktop menu -->
        <ul class="menu menu--desktop">
          <li class="menu__item">
            <router-link :to="{ name: 'Home' }" class="menu__link" active-class="menu__link--active">
              {{ $t('events') }}
            </router-link>
          </li>
          <li v-if="menuItems.Payment" class="menu__item">
            <router-link :to="{ name: 'Payment' }" class="menu__link" active-class="menu__link--active">
              {{ $t('payment') }}
            </router-link>
          </li>
          <li v-if="menuItems.Meters" class="menu__item">
            <router-link :to="{ name: 'Meters' }" class="menu__link" active-class="menu__link--active">
              {{ $t('readings') }}
            </router-link>
          </li>
          <li v-if="menuItems.Requests && !accessOSS && !hideRequests" class="menu__item">
            <router-link :to="{ name: 'Requests' }" class="menu__link" active-class="menu__link--active">
              {{ $t('requests') }}
              <span v-if="getUnreadRequests.length" class="menu__badge">
                {{ getUnreadRequests.length > 99 ? '99+' : getUnreadRequests.length }}
              </span>
            </router-link>
          </li>
          <li v-if="config.showOurService" class="menu__item">
            <router-link :to="{ name: 'Services' }" class="menu__link" active-class="menu__link--active">
              {{ $t('our_service') }}
            </router-link>
          </li>
          <li v-if="accessOSS" class="menu__item">
            <router-link :to="{ name: 'Oss' }" class="menu__link" active-class="menu__link--active">
              {{ $t('meetings') }}
            </router-link>
          </li>
          <li v-if="showOSSLink" class="menu__item">
            <a href="https://oss.sm-center.ru/" class="menu__link">{{ $t('meetings') }}</a>
          </li>
          <li class="menu__item menu__item--phone">
            <a :href="`tel:${config.clearPhone}`" class="menu__link">
              {{ config.phone }}
            </a>
          </li>
        </ul>
      </nav>
      <div class="header__contact header__contact--desktop">
        <a :href="`tel:${config.clearPhone}`" class="header__support-phone">
          {{ config.phone }}
        </a>
        <a 
          href="javascript:;"
          class="header__support"
          @click.prevent="openSupportModalChat"
        >
          <ui-icons 
            iconName="support" 
            class="header__support-icon"
          /> 
        </a>
        <router-link :to="{ name: 'Profile' }" class="header__profile">
          <div class="header__profile-avatar">
            <user-icon class="header__profile-icon" size="16" />
          </div>
          {{ userName }}
        </router-link>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UiIcons from '@/components/ui/icons/UIIcons'
import { UserIcon, MenuIcon } from '@vue-hero-icons/outline';

export default {
  name: 'Header',

  components: {
    UiIcons,
    UserIcon,
    MenuIcon,
  },

  data() {
    return {
      isShowMobileNav: false,
      accessOSS: false,
      showOSSLink: false,
      menuItems: {
        Payment: false,
        Meters: false,
        Requests: false,
      },
      showMenuMobile: false,
    };
  },

  computed: {
    ...mapGetters({
      getMenuVisibilitySettings: 'config/getMenuVisibilitySettings',
      getUnreadRequests: 'request/getUnreadByClient',
      accounts: 'account/getAccounts',
      getAccountSettings: 'account/getAccountSettings',
    }),

    logoSrc() {
      return this.config.appIconFile || this.resolveUrl('/img/logo.svg');
    },

    userName() {
      if (this.isAuth && this.authUser.fio) {
        const nameArray = this.authUser.fio.split(' ');

        if (nameArray.length === 1) {
          return nameArray[0];
        }

        return `${nameArray[0]} ${nameArray[1].charAt(0)}.`;
      } else {
        return '';
      }
    },

    hideRequests() {
      return this.accounts && this.accounts.length
        ? this.accounts.filter((account) => {
            const accountSettings = this.getAccountSettings(account.Id);
            return accountSettings.HideOnRequestsPage || accountSettings.HouseCantCreateRequests;
          }).length === this.accounts.length
        : false;
    },
  },

  created() {
    this.updateMenuItemVisibility(this.getMenuVisibilitySettings);
  },

  mounted() {
    this.getAccountInfo();
    if (this.isAuth) this.getRequestsList();

    if (window.matchMedia('(min-width: 768px)').matches) {
      this.showMenuMobile = false;
    }
  },

  watch: {
    getMenuVisibilitySettings(newValue) {
      this.updateMenuItemVisibility(newValue);
    },

    showMenuMobile(newValue, oldValue) {
      if (newValue !== oldValue) {
        const $body = document.querySelector('body');

        if (newValue) {
          $body.classList.add('_block-scroll');
        } else {
          $body.classList.remove('_block-scroll');
        }
      }
    },
  },

  methods: {
    ...mapActions({
      getRequestsList: 'request/getRequestsList',
    }),

    getAccountInfo() {
      this.accessOSS = this.authUser.accessOSS;
      this.showOSSLink = !this.accessOSS && this.authUser.showOSSLink;
    },

    updateMenuItemVisibility(visibilitySettings) {
      this.menuItems = visibilitySettings;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.header
  +Size(100vw, auto)
  display: flex
  justify-content: center
  background-color: $cBgMain
  border-bottom: 1px solid $cBorder
  position: fixed
  top: 0
  left: 0
  z-index: 900
  margin-right: calc(-1 * (100vw - 100%))

  .logo
    display: inline-flex
    align-items: center

  .logo__img
    +ResponsiveImg(39px)
    border-radius: 50%
    overflow: hidden
    margin-right: 16px
    flex-shrink: 0

  .logo__name
    +Typography(14px, 600, 14px, $cLogo)
    display: inline-block
    max-width: 100%
    flex-shrink: 1

    @media screen and (min-width: 768px)
      max-width: 100px

.header__container
  +PageLayoutContainer()

  @media screen and (min-width: 768px)
    flex-wrap: wrap

  @media screen and (min-width: 992px)
    flex-wrap: nowrap

.header__logo,
.header__menu
  flex: 0 1 auto

  @media screen and (min-width: 768px)
    flex: auto

.header__logo
  padding-right: calc(16px / 2)

  @media screen and (min-width: 768px)
    padding-right: 24px

.header__menu-toggler
  +Transition((color))
  color: $cAccentBlue

  &:hover
    color: $cAccent
    cursor: pointer

  @media screen and (min-width: 768px)
    display: none

.header__menu
  display: flex
  justify-content: flex-end
  margin-left: calc(16px / 2)

  @media screen and (min-width: 768px)
    display: block
    margin-left: 0

  .menu
    +ListReset()
    display: flex
    justify-content: space-around
    position: relative

    &--desktop
      display: none

      @media screen and (min-width: 768px)
        display: flex
        justify-content: flex-end

      @media screen and (min-width: 992px)
        justify-content: space-around

    &--mobile
      +Size(100%, calc(100vh - 70px))
      flex-direction: column
      justify-content: flex-start
      position: fixed
      top: 70px
      right: 0
      transform: translateX(100%)
      transition: transform .3s
      background-color: $cBgMain

      .menu__link
        font-size: 14px
        line-height: 22px

    &--show
      transform: translateX(0)

  .menu__item
    &--phone
      display: none

    @media screen and (min-width: 768px)
      &:nth-last-of-type(2)
        .menu__link
          padding-right: 0

    @media screen and (min-width: 992px)
      &--phone
        display: inline

      &:nth-last-of-type(2)
        .menu__link
          padding-right: 12px

  .menu__link
    +TextMain($color: $cLabel)
    +Transition((color))
    display: inline-flex
    width: 100%
    align-items: center
    justify-content: flex-end
    padding: 10px 12px

    &:hover
      color: $cAccent
      text-decoration: none

    &--contact
      color: $cAccent

      &:hover
        color: $cAccent
        text-decoration: underline

    &--profile
      color: $cAccent

    &--active
      color: $cAccentBlue

    @media screen and (min-width: 768px)
      justify-content: center
      width: auto

  .menu__badge
    +Badge()
    margin-left: 14px

.header__contact
  display: none
  align-items: center

  @media screen and (min-width: 768px)
    display: flex
    justify-content: flex-end
    width: 100%
    // padding: 0 12px

  @media screen and (min-width: 992px)
    justify-content: flex-start
    width: auto
    // padding: 0

.header__support
  +Transition((color))
  line-height: 0
  margin-left: 24px
  color: $cIconNeutral

  &:hover
    color: $cAccent

.header__support-phone
  +Transition((color))
  +TextMain()
  display: inline-block

  &:hover
    color: $cAccent

  @media screen and (min-width: 992px)
    display: none

.header__profile
  +TextSubMain($cAccent)
  display: inline-flex
  // justify-content: center
  align-items: center
  color: $cAccent

  @media screen and (min-width: 992px)
    font-size: 13px
    line-height: 15px

  @media screen and (min-width: 1200px)
    font-size: 15px
    line-height: 20px

.header__profile-avatar
  +Size(24px)
  display: flex
  justify-content: center
  align-items: center
  background-color: $cAccent
  border-radius: 50%
  margin-left: 18px
  margin-right: 16px

  @media screen and (min-width: 768px)
    margin-left: 24px

  @media screen and (min-width: 992px)
    margin-left: 18px

.header__profile-icon
  color: $cBase
</style>
