// FIXME: Удалить. Не используется
import newRequestModal from '@/components/modals/newRequestModal';
import addAccountIdentModal from '@/components/modals/addAccountIdentModal';
import addMeterModal from '@/components/modals/addMeterModal';
import payModal from '@/components/modals/payModal';
import payInvoicesModal from '@/components/modals/payInvoicesModal';
import closeRequestModal from '@/components/modals/closeRequestModal';
import closeOpenRequestModal from '@/components/modals/closeOpenRequestModal';
import contactSupportModal from '@/components/modals/contactSupportModal';
import supportModalChat from '@/components/modals/supportModalChat';
import emailForPayModal from '@/components/modals/emailForPayModal';
import messageRequiredModal from '@/components/modals/messageRequiredModal';
import personalDataPoliticsModal from '@/components/modals/personalDataPoliticsModal';
import modalWrapper from '@/components/modals/modalWrapper';
import supportModalChatWithousAuth from '@/components/modals/supportModalChatWithousAuth';
import PayRequestModal from '@/components/modals/PayRequestModal';
import ReopenRequestModal from '@/components/modals/ReopenRequestModal';
import ReopenOpenRequestModal from '@/components/modals/ReopenOpenRequestModal';
import dialogModal from '@/components/ui/DialogModal';
import PrivilegeClubAmoModal from '@/components/modals/PrivilegeClubAmoModal';

const baseModalConfig = {
  width: window.innerWidth > 717 ? '717px' : '100%',
  height: 'auto',
  scrollable: true,
};

export default {
  methods: {
    openModalWrapper() {
      this.$modal.show(modalWrapper, {}, ...baseModalConfig);
    },

    openNewRequestModal() {
      this.$modal.show(
        newRequestModal,
        {},
        {
          ...baseModalConfig,
        },
      );
    },
    openAddAccountIdentModal() {
      this.$modal.show(
        addAccountIdentModal,
        {},
        {
          ...baseModalConfig,
        },
      );
    },
    openAddMeterModal(id) {
      this.$modal.show(
        addMeterModal,
        { id },
        {
          ...baseModalConfig,
        },
      );
    },
    openPayModal(id) {
      this.$modal.show(
        payModal,
        { id },
        {
          ...baseModalConfig,
        },
      );
    },
    openPayInvoicesModal(id) {
      this.$modal.show(
        payInvoicesModal,
        { id },
        {
          ...baseModalConfig,
        },
      );
    },
    closeRequestModal(requestId, requestNumber) {
      this.$modal.show(
        closeRequestModal,
        { requestId, requestNumber },
        {
          ...baseModalConfig,
        },
      );
    },
    closeOpenRequestModal(accessCode, requestNumber) {
      this.$modal.show(
        closeOpenRequestModal,
        { accessCode, requestNumber },
        {
          ...baseModalConfig,
        },
      );
    },
    contactSupportModal() {
      this.$modal.show(
        contactSupportModal,
        {},
        {
          ...baseModalConfig,
        },
      );
    },
    openSupportModalChat() {
      this.$modal.show(
        supportModalChat,
        {},
        {
          addaptive: true,
          height: 'auto',
          scrollable: true,
        },
      );
    },
    openSupportModalChatWithousAuth() {
      this.$modal.show(
        supportModalChatWithousAuth,
        {},
        {
          addaptive: true,
          height: 'auto',
          scrollable: true,
        },
      );
    },
    openEnterEmailForPay(ident) {
      this.$modal.show(
        emailForPayModal,
        { ident },
        {
          ...baseModalConfig,
        },
      );
    },
    openMessageRequired() {
      this.$modal.show(
        messageRequiredModal,
        {},
        {
          ...baseModalConfig
        }
      )
    },
    openPersonalDataPolitics() {
      this.$modal.show(
        personalDataPoliticsModal,
        {},
        {
          ...baseModalConfig,
        },
      );
    },
    openPayRequestModal(info) {
      this.$modal.show(
        PayRequestModal,
        { info },
        {
          ...baseModalConfig,
        },
      );
    },
    openReopenRequestModal(requestId, requestNumber) {
      this.$modal.show(
        ReopenRequestModal,
        {
          requestId,
          requestNumber,
        },
        {
          ...baseModalConfig,
          clickToClose: false,
        },
      );
    },
    openReopenOpenRequestModal(accessCode, requestNumber) {
      this.$modal.show(
        ReopenOpenRequestModal,
        {
          accessCode,
          requestNumber,
        },
        {
          ...baseModalConfig,
          clickToClose: false,
        },
      );
    },
    openPrivilegeClubAmoModal(fields) {
      this.$modal.show(
        PrivilegeClubAmoModal,
        {
          fields,
        },
        {
          ...baseModalConfig,
          // TODO: size
        },
      );
    },

    /**
     * Открытие диалога
     * @param {*}        data
     * @param {string}   data.title Заголовок
     * @param {string}   data.text Содержание
     * @param {array}    data.buttons Кнопки взаимодействия
     * @param {string}   data.buttons.type Тип
     * @param {string}   data.buttons.title Значение
     * @param {function} data.buttons.handler Обработчик клика
     */
    openDialog(data) {
      this.$modal.show(
        dialogModal,
        {
          data,
        },
        {
          name: 'dialog',
          // width: (window.matchMedia("(max-width: 576px)").matches) ? '100%' : '576px',
          height: 'auto',
          scrollable: false,
          addaptive: true,
          clickToClose: false,
          loading: false
        },
      );
    },
    /**
     * Закрытие диалога
     */
    cancelDialog() {
      this.$modal.hide('dialog');
    },
  },
};
