<template>
  <div v-loading="loading" class="news-page">
    <page-header
      :title="$t('news')"
      :back="{
        title: $t('back'),
        to: { name: 'Home' },
      }"
    />

    <article v-if="article" class="article">
      <div class="article__header">
        <h3 class="article__title">{{ article.Header }}</h3>
        <time class="article__date">{{ article.Created }}</time>
      </div>

      <div class="article__body">
        <figure v-if="article.HasImage" class="article__image">
          <img :src="article.ImageLink" :alt="article.Header" />
        </figure>

        <div class="article__content">
          <div
            v-html="article.Text"
            class="article__text"
          ></div>

          <div
            v-if="article.Files && article.Files.length"  
            class="article__attachments"
          >
            <p class="article__attachments-header">
              {{ $t('files_for_downloads') }}:
            </p>

            <ul class="article__attachments-list">
              <li
                v-for="(file, i) in article.Files"
                :key="`file-${i}`" 
                class="article__attach"
                @click="onDownloadFile(file.ID)"
              >
                <document-download-icon class="article__download-icon"/>
                {{ file.Name }} 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DocumentDownloadIcon } from "@vue-hero-icons/outline"
import PageHeader from '@/components/pages/common/PageHeader';

export default {
  name: 'News',

  components: {
    PageHeader,
    DocumentDownloadIcon
  },

  data() {
    return {
      loading: true,
    };
  },

  computed: {
    ...mapGetters({
      article: 'news/getNewsDetail',
    }),
  },

  mounted() {
    // console.log('--- mounted hook ---')
    this.loading = true;

    this.getNewsDetail(this.$route.params.id).finally(() => {
      this.loading = false;
    });
  },

  destroyed() {
    this.clearNewsDetail();
  },

  methods: {
    ...mapActions({
      getNewsDetail: 'news/getNewsDetail',
      clearNewsDetail: 'news/clearNewsDetail',
      getNewsFile: 'news/getNewsFile',
    }),

    onDownloadFile(fileId) {
      this.getNewsFile(fileId);
    }
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/variables';
@import '../sass/mixins';

.news-page {
  .page-header {
    display: block;
  }
}

.article__header {
  margin-bottom: 24px;

  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
}

.article__title {
  @include TextHeader(0);
  margin-bottom: 8px;

  @media screen and (min-width: 768px) {
    margin-bottom: 24px;
  }
}

.article__date {
  @include TextMini();
}

.article__image {
  @include ResponsiveImg(100%, 200px);

  float: right;
  margin-left: 0;
  margin-bottom: 16px;
  border: 1px solid $cBorder;
  border-radius: 16px;
  overflow: hidden;

  @media screen and (min-width: 576px) {
    @include Size(50%, 258px);

    float: right;
    margin-bottom: 24px;
  }

  @media screen and (min-width: 768px) {
    @include Size(398px, 258px);
    margin-left: 24px;
    margin-bottom: 40px;
  }

  img {
    object-fit: cover;
  }
}

.article__content:after {
  content: '';
  clear: both;
}

.article__text {
  @include TextMain();

  margin-bottom: 0;

  ::v-deep img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.article__attachments {
  width: 100%;
  margin-top: 25px;
}

.article__attachments-list {
  float: left;
}

.article__attachments-header {
  @include TextMain();
  color: $cTextMain;
}

.article__attach {
  @include TextMain();

  color: $cTextMain;

  display: flex;
  align-items: center;
  width: 100%;

  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 14px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.article__download-icon {
  margin-right: 10px;
}
</style>
