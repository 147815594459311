import { normalizeData } from "../../services/common";
import * as commonApi from "@/api/common";
import * as newsApi from "@/api/news";
import download from "downloadjs";

const state = {
  newsList: [],
  newsDetail: null,
};

const actions = {
  async getEventBlockData({ commit }) {
    return await commonApi
      .getEventBlockData({
        htmlAsPlaintText: true,
      })
      .then((response) => {
        let news = normalizeData(response.data.News, 'ID');
        commit('setNewsList', news.list);

        let announcements = normalizeData(response.data.Announcements, 'ID');
        commit('announcement/setAnnouncements', announcements.list, {
          root: true,
        });

        let services = normalizeData(response.data.AdditionalServices, 'ID');
        /* Перенос значения из списка магазинов в список магазинов в AdditionalServicesByGroups*/
        Object.keys(response.data.AdditionalServicesByGroups).forEach((group) => {
          response.data.AdditionalServicesByGroups[group].forEach((item) => {
            item.ExternalSystem = services.list[item.ID].ExternalSystem;
          });
        });
        commit('additionalService/setServices', services.list, { root: true });
        commit('additionalService/setServicesByGroups', response.data.AdditionalServicesByGroups, { root: true });

        const polls = response.data.Polls;
        commit('polls/setPolls', response.data.Polls, { root: true });

        return { news, announcements, services, polls };
      })
      .catch((error) => {
        throw error;
      });
  },

  async getNewsDetail({ commit }, news_id) {
    return await newsApi
      .getNewsContent(news_id)
      .then((response) => {
        commit('setNewsDetail', response.data);
      })
      .catch((error) => {
        throw error;
      });
  },

  async getImage(_, id) {
    let response = await newsApi.getNewsImage(id);

    if (response.status === 200) {
      let blob = new Blob([response.data], { type: 'image/png' });
      return window.URL.createObjectURL(blob);
    }

    return false;
  },

  async getNewsFile(_, id) {
    let response = await newsApi.getNewsFile(id);

    if (response.status === 200) {
      let filename = "";
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(response.headers["content-disposition"]);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }

      download(response.data, filename, response.headers["content-type"]);
    }

    return false;
  },

  clearNewsDetail({ commit }) {
    commit('clearNewsDetail');
  },
};

const getters = {
  getNewsDetail: ({ newsDetail }) => newsDetail,
  getNewsList:
    ({ newsList }) =>
    (list) =>
      list.length ? list.map((id) => newsList[id] || null) : null,
};

const mutations = {
  setNewsList(state, data) {
    state.newsList = {
      ...state.newsList,
      ...data,
    };
  },

  setNewsDetail(state, data) {
    state.newsDetail = data;
  },

  clearNewsDetail(state) {
    state.newsDetail = null;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
