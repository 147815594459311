<template>
  <div 
    v-loading="loading" 
    class="checkup-notifications"
  >
    <template v-if="filteredExpiredMeters.length">
      <div 
        v-for="(meter, i) in filteredExpiredMeters"
        :key="`note-${i}`"
        class="checkup-notifications__article"
      >
        <alert 
          :type="(meter.ExistVerificationRequest) ? 'neutral' : 'danger'"
          critical
          class="checkup-notifications__alert"
        >
          <!-- <pre>{{ meter }}</pre> -->
          <p
            v-if="meter.ExistVerificationRequest" 
            class="checkup-notifications__text checkup-notifications__text--strong"
          >
            {{ $t('verification_request_created') }}
          </p>
          <p class="checkup-notifications__text">
            {{ $t('calibration_interval_for_meter_expired') }}
            "{{ meter.Resource }}" {{ meter.NextCheckupDate }}.
            №&nbsp;{{ meter.FactoryNumber }} {{ $t('by_address') }} {{ meter.Address }}
          </p>

          <base-button
            v-if="!meter.ExistVerificationRequest"
            styleClass="text-mini"
            class="checkup-notifications__request-button"
            @click="onOpenRequestVerificationDialog(meter)"
          >
            {{ $t('order_verification') }}
          </base-button>
        </alert>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'CheckupNotifications',

  components: {
    Alert,
    BaseButton,
  },

  data() {
    return {
      loading: false,
      loadingDialog: false
    }
  },

  computed: {
    ...mapGetters({
      expiredMeters: 'meter/getExpiredVerificationMeters'
    }),

    filteredExpiredMeters() {
      const expiredMeters = this.expiredMeters;

      return expiredMeters.filter((meter) => meter?.AllowMeterVerificationRequest);
    }
  },

  created() {
    this.getMetersList()
  },

  methods: {
    ...mapActions({
      getMetersList: 'meter/getMetersList',
      createRequestOnMeterRecheck: "meter/createRequestOnMeterRecheck",
    }),

    onOpenRequestVerificationDialog(meter) {
      this.openDialog({
        title: this.$t('request_for_verification'),
        text: `
          ${this.$t('create_request_for_verification_of_metering_device')} "${meter.Resource}"
          №&nbsp;${meter.FactoryNumber} ${this.$t('by_address')} ${meter.Address}?
          <br><br>
          ${this.$t('metering_out_of_order')}
        `,
        loading: this.loadingDialog,
        buttons: [
          {
            title: this.$t('no'),
            type: 'additional',
            handler: () => {
              this.cancelDialog()
            }
          },
          {
            title: this.$t('yes'),
            type: 'base',
            handler: () => {
              this.loadingDialog = true;

              this.createRequestOnMeterRecheck(meter.ID)
                .then(({ error, isSucceed, requestNumbers }) => {
                  this.getMetersList();
                  
                  if (error) {
                    this.$notify.error(error);
                  } 

                  if (isSucceed) {
                    this.$notify.success({
                      message: `
                        ${this.$t('verification_request_created_successfully')}
                        ${this.$t('request_number')}: ${requestNumbers[0]}.
                        ${this.$t('specialist_will_contact_you')}
                      `,
                      duration: 6000,
                    });

                    this.cancelDialog();
                  }

                  this.loadingDialog = false;
                })
                .catch(() => {
                  this.cancelDialog();
                })
            }
          },
        ]
      })
    },
  }
}
</script>

<style lang="scss">
@import '../../../sass/variables';
@import '../../../sass/mixins';

.checkup-notifications {
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 16px;
  justify-content: space-between;
  
  @media screen and (min-width: 768px) {
    column-gap: 0;
  }
}

.checkup-notifications__article {
  display: flex;
  flex-basis: 100%;

  @media screen and (min-width: 768px) {
    flex-basis: 50%;
  }
  
  &:nth-child(odd) {
    padding-right: 0;  
    
    @media screen and (min-width: 768px) {
      padding-right: calc(16px / 2);  
    }
  }

  &:nth-child(even) {
    padding-left: 0;  
    
    @media screen and (min-width: 768px) {
      padding-left: calc(16px / 2);  
    }
  }
}

.checkup-notifications__alert {
  align-items: flex-start !important;
}

.checkup-notifications__text {
  @include TextMini($cWhite);

  margin-bottom: 12px;
  
  &:last-of-type {
    margin: 0;
  }
  
  &--strong {
    font-weight: 600;
  }
}

.checkup-notifications__request-button {
  @include TextMini($cWhite);

  color: $cWhite !important;
  margin-top: 12px;
}
</style>

