export default {
  ru: {
    news: 'Новости',
    no_news: 'Новостей нет',
    files_for_downloads: 'Файлы для скачивания',
  },

  en: {
    news: 'News',
    no_news: 'No news',
    files_for_downloads: 'Files for downloads',
  },
}