<template>
  <div class="form-box__field">
    <div :class="[
      'field',
      'field--checkbox',
      { 'field--error': hasError },
      { 'field--active': isActiveState },
      { 'field--focus': fieldIsFocus },
      { 'field--disabled': disabled },
      ...modificators
    ]"
    >
      <div 
        class="field__group"
        @mousedown="isActiveState = (!disabled) ? true : false"
        @mouseup="isActiveState = false"
      >
        <span 
          :class="[
            'field__checkbox',
            { 'field__checkbox--checked': value }
          ]"
          @click="$emit('input', !value)"
        >
          <check-icon 
            v-if="value"
            size="16"
            class="check"
          />
          <input 
            :ref="ref"
            type="checkbox" 
            :id="id"
            :name="id"
            :disabled="disabled"
            @focus="fieldIsFocus = true"
            @blur="fieldIsFocus = false"
            @change="$emit('change', !value)"
            />
        </span>
        <label 
          class="field__label" 
          :for="id"
          @click="$emit('change', !value)"
        >
          <slot>
            {{ label }}
          </slot>
        </label>
      </div>
    </div>
    <p 
      v-show="hasError"
      class="field__message field__message--error"
    >
      {{ message }}
    </p>
  </div>
</template>

<script>
import { CheckIcon } from '@vue-hero-icons/outline';

export default {
  name: 'FormBoxCheckbox',

  components: {
    CheckIcon,
  },

  props: {
    value: {
      // type: Boolean,
      required: false,
      default: false,
    },
    inputId: {
      type: String,
      required: false,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    refName: {
      type: String,
      required: false,
      default: '',
    },
    hasError: {
      type: Boolean,
      required: false,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default: '',
    },
    focused: {
      type: Boolean,
      required: false,
      default: false,
    },
    classModificators: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      fieldIsFocus: false,
      isActiveState: false,
    };
  },

  computed: {
    modificators() {
      return this.classModificators.map((item) => `field--${item}`);
    },

    id() {
      if (this.inputId.length) {
        return this.inputId;
      }

      return `input-checkbox-${this._uid}`;
    },

    ref() {
      if (this.refName.length) {
        return this.refName;
      }

      return `input-checkbox-${this._uid}`;
    },
  },

  watch: {
    focused(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.$nextTick(() => this.$refs[this.refName].focus());
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/input'
</style>
