<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7" y="2" width="10" height="20" rx="1" :stroke="fill" stroke-width="2" />
    <circle cx="12" cy="12" r="7" fill="#FAFAFA" :stroke="fill" stroke-width="2" />
    <path d="M8 12H16" :stroke="fill" stroke-width="2" stroke-linecap="round" />
    <path d="M10 15L14 15" :stroke="fill" stroke-width="2" stroke-linecap="round" />
    <path d="M11 9H13" :stroke="fill" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: 'UndefinedMeterIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
