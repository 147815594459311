export default {
  ru: {
    new_request: 'Новая заявка',
    request_type: 'Тип заявки',
    malfunction: 'Неисправность',
    detail_view: 'Подробный вид',
    description_of_request: 'Описание заявки',
    drag_or_upload_file: 'Перетащите или загрузите файл',
    no_more_10_mb: 'не более 10 Мб',
    request_created: 'Заявка успешно создана',
    error_while_creating_request: 'При создании заявки произошла ошибка',
    emergency_works_title: 'Аварийные работы',
    emergency_works_header_1: 'Описание',
    emergency_works_header_2: 'Дата начала',
    emergency_works_header_3: 'Дата окончания',
    emergency_works_alert_description: `По данному адресу уже ведутся аварийные работы. 
    Пожалуйста, не создавайте новые заявки до указанного срока завершения, если они касаются общего благоустройства дома.`,
  },

  en: {
    new_request: 'New request',
    request_type: 'Request type',
    malfunction: 'Malfunction',
    detail_view: 'Detail view',
    description_of_request: 'Description of the request',
    drag_or_upload_file: 'Drag or upload file',
    no_more_10_mb: 'no more 10 Mb',
    request_created: 'Request successfully created',
    error_while_creating_request: 'An error occurred while creating the request',
    emergency_works_title: 'Emergency work',
    emergency_works_header_1: 'Description',
    emergency_works_header_2: 'Start date',
    emergency_works_header_3: 'End date',
    emergency_works_alert_description: `Emergency work is already underway at this address.
    Please do not create new applications before the stated deadline if they are for general home improvement.`,
  },
}