<template>
  <modal
    :title="$t('confirmation_of_request_closure')"
    :setBaseStyle="true"
    class="modal--close-request request"
    @close="$emit('close')"
  >
    <div class="form-box">
      <form v-loading="isSending" v-on:submit.prevent class="request__form form-box__form">
        <h2 class="request__title">{{ $t('closing_request') }} №{{ requestNumber }}</h2>

        <div class="request__rating">
          <p class="request__rating-title">{{ $t('evaluate_quality_of_execution_of_request') }}:</p>

          <template v-if="config.styleOfEvaluation === 'star'">
            <ul class="rating rating--start">
              <li
                v-for="i in [1, 2, 3, 4, 5]"
                :key="`evaluation-${i}`"
                :class="['rating__mark', { 'rating__mark--active': form.Mark >= i }]"
                :aria-label="i"
                @click="form.Mark = i"
              >
                {{ i }}
              </li>
            </ul>
          </template>
          <template v-if="config.styleOfEvaluation === 'smile'">
            <div class="rating rating--smile">
              <div
                v-for="(emoji, key) in smiles"
                :key="`evaluation-${key}`"
                :class="['rating__smile', { 'rating__smile--active': form.Mark === key }]"
                @click="form.Mark = key"
              >
                <img :src="resolveUrl(emoji)" :alt="key" />
              </div>
            </div>
          </template>
        </div>

        <div class="request__message">
          <form-box-textarea v-model="form.Text" :label="$t('comment_on_closed_request')" height="111px" />
        </div>

        <button type="submit" class="request__submit" @click="closeRequest">{{ $t('confirm') }}</button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import Modal from '@/components/ui/Modal';
import FormBoxTextarea from '@/components/form/FormBoxTextarea';

export default {
  name: 'CloseOpenRequestModal',

  components: {
    Modal,
    FormBoxTextarea,
  },

  props: {
    accessCode: {
      type: String,
      default: undefined,
    },
    requestNumber: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: {
        AccessCode: this.accessCode,
        Mark: 5,
        Text: '',
      },

      isSending: false,
      smiles: {
        1: '/img/emoji/angry_sad.svg', // 😡 angry
        3: '/img/emoji/thinking.svg', // 😐 neutral
        5: '/img/emoji/stars.svg', // 😍 hearteyes
      },
    };
  },

  mounted() {
    this.form.AccessCode = this.accessCode;
  },

  methods: {
    ...mapActions({
      closeRequestAction: 'openRequest/closeRequest',
    }),

    closeRequest() {
      this.isSending = true;

      this.closeRequestAction(this.form)
        .then(() => {
          this.isSending = false;
          this.$root.$emit('requests:closed-request');
          this.$emit('close');

          this.isSending = false;
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.request
  .rating
    display: flex
    justify-content: center
    margin-bottom: 16px

  .rating__mark
    +Size(32px)
    +Transition((color, background-color))
    display: inline-flex
    justify-content: center
    align-items: center
    color: $cAccent
    background-color: $cBgMain
    border: 1px solid $cAccent
    border-radius: 8px
    margin: 0 calc(8px / 2)
    cursor: pointer

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

    &--active
      color: $cBgMain
      background-color: $cAccent

    &:hover
      color: $cBgMain
      background-color: $cAccent

  .rating__smile
    +Size(48px)
    +Transition((border-color))
    display: flex
    justify-content: center
    align-items: center
    border: 1px solid transparent
    border-radius: 8px
    margin: 0 calc(8px / 2)
    cursor: pointer

    &:first-child
      margin-left: 0

    &:last-child
      margin-right: 0

    &--active
      border-color: $cAccent
      background-color: $cAccent

    &:hover
      border-color: $cAccent

    img
      +Size(36px)

.request__title
  +TextMain($cTextMain)
  text-align: center
  margin-bottom: 16px

.request__rating-title
  +TextHeader(2)
  text-align: center
  margin-bottom: 16px

.request__submit
  +Button('base')
</style>
