<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 4H5V20H19V4ZM5 2C3.89543 2 3 2.89543 3 4L3 5H2C1.44772 5 1 5.44772 1 6C1 6.55228 1.44772 7 2 7H3L3 18H2C1.44772 18 1 18.4477 1 19C1 19.5523 1.44772 20 2 20H3C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20H22C22.5523 20 23 19.5523 23 19C23 18.4477 22.5523 18 22 18H21V7H22C22.5523 7 23 6.55228 23 6C23 5.44772 22.5523 5 22 5H21V4C21 2.89543 20.1046 2 19 2H5ZM8 6C7.44772 6 7 6.44771 7 7V17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17V7C9 6.44772 8.55228 6 8 6ZM12 6C11.4477 6 11 6.44771 11 7V17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17V7C13 6.44772 12.5523 6 12 6ZM15 7C15 6.44771 15.4477 6 16 6C16.5523 6 17 6.44772 17 7V17C17 17.5523 16.5523 18 16 18C15.4477 18 15 17.5523 15 17V7Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThermalEnergyIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
