import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      eventBlockIsLoaded: false,
    };
  },

  computed: {
    ...mapState(['additionalService']),
  },

  mounted() {
    this.getEventBlockData().then((result) => {
      if (result) {
        this.eventBlockIsLoaded = true;
      }
    });
  },

  methods: {
    ...mapActions({
      getEventBlockData: 'news/getEventBlockData',
    }),

    getServiceGroup() {
      return this.additionalService.servicesByGroups.find((item) => {
        return item.services.map((shop) => shop.ID).includes(+this.$route.params.AdditionalServiceId);
      });
    },

    getShopName() {
      return this.getServiceGroup().services.find((shop) => {
        return shop.ID == +this.$route.params.AdditionalServiceId;
      }).Name;
    },

    nextRouteParams(currentRouteName, nextRouteName) {
      let params = JSON.parse(JSON.stringify(this.$route.params));

      if (this.additionalService.servicesByGroups.length > 0) {
        if (!params.breadcrumbs) {
          params.breadcrumbs = JSON.parse(JSON.stringify(this.currentBreadcrumbs));
        }

        params.breadcrumbs[params.breadcrumbs.length - 1].route = {
          params: JSON.parse(JSON.stringify(this.$route.params)),
          name: currentRouteName,
        };

        params.breadcrumbs.push({ text: nextRouteName, route: '' });

        return params;
      }

      return params;
    },

    defineServiceBreadcrumb() {
      let serviceGroup = this.getServiceGroup();
      return {
        text: serviceGroup.name,
        route: {
          name: 'Services',
          params: { serviceId: +this.$route.params.AdditionalServiceId },
        },
      };
    },
  },
};
