<template>
  <Modal @close="$emit('close')" class="modal-content_testimony">
    <div class="testimony-item_header">Укажите адрес электронной почты</div>
    <ul class="testimony-item_top-list">
      <li>Для отправки чека об оплате необходимо указать адрес электронной почты</li>
    </ul>
    <form v-on:submit.prevent class="testimony-form" v-loading="isSending">
      <div class="form-group">
        <label class="form-label"><span class="glipf-mail-2"></span>Email</label>
        <input type="email" v-model="form.email" class="form-control" />
      </div>
      <input type="submit" class="testimony_form_submit main-btn" @click="send" value="Сохранить" />
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';

export default {
  name: 'emailForPayModal',
  components: { Modal },
  props: {
    ident: {
      type: String,
    },
  },
  data() {
    return {
      form: {
        fio: '',
        email: '',
      },
      isSending: false,
    };
  },
  mounted() {
    this.form.email = this.authUser.email;
    this.form.fio = this.authUser.fio;
  },
  methods: {
    send() {
      // Check is more or equal to previous
      if (!this.form.email || this.form.email.indexOf('@') < 0 || this.form.email.indexOf('.') < 0) {
        this.$notify.error({
          position: 'top-left',
          title: 'Ошибка',
          message: 'Указан неверный адрес электронной почты',
        });
      } else {
        this.$store
          .dispatch('account/updateProfile', this.form)
          .then((result) => {
            if (result.isSucceed) {
              this.isSending = false;
              this.$emit('close');
              this.$notify.success('Адрес электронной почты сохранен');
              this.openPayModal(this.ident);
            }
          })
          .catch(() => {
            this.$notify.error('Ошибка обновления данных профиля');
          });
        /*this.isSending = true
          this.$store.dispatch('meter/saveMeterValue', 
          { Value: this.value, ID: this.id, ValueT2: this.value2, ValueT3: this.value3 })
            .then(() => {
              this.value = []
              this.isSending = false
              this.$root.$emit('added-meter')
              this.$emit('close')
            })
            .catch(() => this.isSending = false)*/
      }
    },
  },
  computed: {},
  watch: {},
};
</script>
