<template>
  <div class="payment-page">
    <page-header :title="$t('payment')" />

    <div v-loading="!accountInfo">
      <template v-if="accountInfo">
        <div v-if="accountInfo.length" class="payment-page__content">
          <section v-loading="isLoadingAccounts" class="payment-page__tabs">
            <tabs :tabs="tabs" v-model="activeTab">
              <template v-slot:content>
                <keep-alive>
                  <component :is="activeTab" :content="tabs.find((tab) => tab.id === activeTab)" />
                </keep-alive>
              </template>
            </tabs>
          </section>
        </div>

        <section v-else class="payment-page__add-account">
          <add-account-ident :text="$t('make_payment')" />
        </section>
      </template>
    </div>
  </div>
</template>

<script>
//vuex
import { mapGetters, mapActions } from 'vuex';

import pageHeader from '@/components/pages/common/PageHeader';
import tabs from '@/components/ui/Tabs';
import personalAccounts from '@/components/pages/payment/PersonalAccounts';
import history from '@/components/pages/payment/History';
import invoices from '@/components/pages/payment/Invoices';
import addAccountIdent from '@/components/pages/common/AddAccountIdent';

export default {
  name: 'Payment',

  components: {
    pageHeader,
    tabs,
    personalAccounts,
    history,
    invoices,
    addAccountIdent,
  },

  data() {
    return {
      accountInfoIDs: null,
      selectedIdent: null,
      activeTab: null,
      isLoadingAccounts: true,
    };
  },

  computed: {
    ...mapGetters({
      getMenuVisibilitySettings: 'config/getMenuVisibilitySettings',
      // getInfos: "account/getInfos",
      getAllInfo: 'account/getAllInfo',
      getAccountSettings: 'account/getAccountSettings',
    }),

    /**
     * Все лицевые счета с полной информацией.
     * @returns {array} Список счетов.
     */
    accountInfo() {
      return this.getAllInfo;
    },

    /**
     * Уникальные лицевые счета. Удалены дубликаты.
     * @returns {array} Список счетов.
     */
    uniqueAccounts() {
      if (this.accountInfo) {
        return this.accountInfo
          .filter((x, i, self) => {
            return self.findIndex((t) => t.Ident == x.Ident) === i
          }
        );
      }

      return null;
    },

    /**
     * bills - Квитанции на оплату.
     * @returns {array} Список квитанций.
     */
    bills() {
      if (!this.accountInfo) {
        return [];
      }

      return this.accountInfo
        .filter((account) => {
          return !this.getAccountSettings(account.AccountID).HideOnBillsPage;
        })
        .reduce((oldEl, item) => {
          if (!item.Bills) {
            return [];
          }

          let newEl = item.Bills.map((bill) => {
            const dateParts = bill.Date?.split('.') || [];
            const [
              day = 0,
              month = 0,
              year = 0,
            ] = dateParts;

            return {
              ...bill,
              SortableDate: Number(year + month + day),
              Address: item.Address,
            };
          });

          return [...oldEl, ...newEl];
        }, []);
    },

    /**
     * payments - Платежи ЖКУ для истории.
     * @returns {array} Отсортированный список.
     */
    payments() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo
        .filter((account) => {
          return !this.getAccountSettings(account.AccountID).HideOnBillsPage;
        })
        .reduce((newEl, item) => {
          if (!newEl[item.Ident]) {
            newEl[item.Ident] = item.Payments;
          } else {
            newEl[item.Ident] = [...newEl[item.Ident], ...item.Payments];
          }
          return newEl;
        }, {});
    },

    /**
     * pendingPayments - Ожидающие платежи.
     * @returns {array} Отсортированный список
     */
    pendingPayments() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo.reduce((newEl, item) => {
        if (!newEl[item.Ident]) {
          newEl[item.Ident] = item.PendingPayments;
        } else {
          newEl[item.Ident] = [...newEl[item.Ident], ...item.PendingPayments];
        }
        return newEl;
      }, {});
    },

    /**
     * insurances - Платежи страховой для истории.
     * @returns {array} Отсортированный список
     */
    insurances() {
      if (!this.accountInfo) {
        return [];
      }
      return this.accountInfo.reduce((newEl, item) => {
        if (!newEl[item.Ident]) {
          newEl[item.Ident] = item.InsurancePayments;
        } else {
          newEl[item.Ident] = [...newEl[item.Ident], ...item.InsurancePayments];
        }
        return newEl;
      }, {});
    },

    /**
     * Табы, разделяющие отображаемый контент.
     * @returns {array} Список табов с id компонента для отображения в
     * контентной части.
     */
    tabs() {
      return [
        {
          text: this.$t('personal_accounts'),
          id: 'personal-accounts',
          data: this.accountInfo.filter((account) => {
            return !this.getAccountSettings(account.AccountID).HideOnPaymentsPage;
          }),
          isLoading: this.isLoadingAccounts,
        },
        {
          text: this.$t('payment_history'),
          id: 'history',
          data: {
            accounts: this.accountInfo.filter((account) => {
              return !this.getAccountSettings(account.AccountID).HideOnBillsPage;
            }),
            pendingPayments: this.pendingPayments,
            payments: this.payments,
            insurances: this.insurances,
          },
        },
        this.getMenuVisibilitySettings.Bills
          ? {
              text: this.$t('bills'),
              id: 'invoices',
              data: {
                accounts: this.accountInfo.filter((account) => {
                  return !this.getAccountSettings(account.AccountID).HideOnBillsPage;
                }),
                bills: this.bills,
              },
            }
          : false,
      ];
    },
  },

  mounted() {
    this.getAccountInfo();

    this.$root.$on('updated-account-info', this.getAccountInfo);
    this.$root.$on('deleted-account-info', this.getAccountInfo);

    setTimeout(this.showPaymentResult, 1000);
    if (this.authUser.accessOSS) {
      this.$router.push('home');
    }

    this.$set(this, 'activeTab', this.tabs[0].id);
  },

  destroyed() {
    this.$root.$off('updated-account-info');
    this.$root.$off('deleted-account-info');
  },

  methods: {
    ...mapActions({
      getInfo: 'account/getInfo',
    }),

    /**
     * Получение списка лицевых счетах.
     */
    getAccountInfo() {
      this.isLoadingAccounts = true;

      this.getInfo()
        .then(() => {
          if (
            this.accountInfo.length > 0 &&
            this.accountInfo[0].Bills.length > 0
          ) {
            this.selectedIdent = this.accountInfo[0].Bills[0].Ident;
          }

          setTimeout(() => {
            this.isLoadingAccounts = false;
          }, 300)
        });
    },

    /**
     * showPaymentResult - Перехват и отображение результата оплаты.
     */
    showPaymentResult() {
      if (location.hash.match(/paymentSuccess$/)) {
        this.$notify.success({
          title: this.$t('payment'),
          message: this.$t('payment_successful'),
          duration: 6000,
          offset: 100,
        });
        location.hash = '/payment';
      }

      if (location.hash.match(/paymentFail$/)) {
        this.$notify.error({
          title: this.$t('payment'),
          message: this.$t('payment_failed'),
          duration: 6000,
          offset: 100,
        });
        location.hash = '/payment';
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.payment-page__tabs
  +ContentContainer()
  margin-bottom: 16px
</style>
