<template>
  <div id="app">
    <!-- <preloader /> -->
    <modals-container />
    <transition>
      <component :is="layout" class="layout">
        <keep-alive exclude="News">
          <router-view />
        </keep-alive>
      </component>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import preloader from '@/components/ui/Preloader'

export default {
  name: 'App',

  // components: {
  //   preloader
  // },

  data() {
    return {
      transitionName: '',
    };
  },

  computed: {
    ...mapGetters({
      requests: 'request/getRequests',
    }),

    layout() {
      if (this.$route.name === 'Requests' && !this.requests.length) {
        return 'page-layout';
      }

      return this.$route.meta.layout || 'page-layout';
    },
  },

  watch: {
    $route(to, from) {
      const toDepth = to.path.split('/').length;
      const fromDepth = from.path.split('/').length;
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    },

    config(newValue) {
      let root = document.documentElement;
      root.style.setProperty('--base-color', `#${newValue.color}`);
      root.style.setProperty('--base-color-rgb', this.colorToRGB(newValue.color));
      this.setIcon(newValue.siteIconFile);
    },
  },

  created() {
    this.$root.$on('page-loading', (status) => {
      this.loading = status;
    });

    if (!localStorage.getItem('deviceID')) {
      this.generateGUID();
    }

    this.syncUserData();
  },

  destroyed() {
    this.$root.$off('page-loading');
  },

  methods: {
    ...mapActions({
      generateGUID: 'common/generateGUID',
      syncUserData: 'account/syncUserData',
    }),

    colorToRGB(colorString) {
      if (!colorString || colorString.length != 6) {
        return '0, 0, 0';
      }

      return (
        parseInt(colorString.substr(0, 2), 16) +
        ', ' +
        parseInt(colorString.substr(2, 2), 16) +
        ', ' +
        parseInt(colorString.substr(4, 2), 16)
      );
    },

    setIcon(url) {
      let link = document.querySelector("link[rel*='icon']") || document.createElement('link');

      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = url;
      document.getElementsByTagName('head')[0].appendChild(link);
    },
  },
};
</script>

<style lang="css">
:root {
  --base-color: transparent;
  --base-color-rgb: 0, 0, 0;
}
</style>

<style lang="sass">
@import 'sass/variables'
@import 'sass/mixins'

.vm--overlay
  $blur: 1px
  background-color: rgba(#B2B4B5, .3)
  backdrop-filter: blur($blur)
  -webkit-filter: blur($blur)
  -ms-filter: blur($blur)
  -moz-filter: blur($blur)
  -o-filter: blur($blur)
  filter: blur($blur)
  filter: url("#blur")
  transform: scale(1.1)
  transition: backdrop-filter .3s, transform .3s, filter .3s

.el-loading-mask
  background-color: $cWhite

.el-loading-spinner .path
  stroke: $cAccent

.layout
  display: flex
  align-items: stretch
  width: 100%
  min-height: 100vh
  background: $cBgMain

  &--page,
  &--empty,
  &--services,
    flex-direction: column
    align-items: center

  &--services
    margin-bottom: 100px

    @media screen and (min-width: 992px)
      margin-bottom: 0 !important
      min-height: 100vh !important
</style>
