export default {
  ru: {
    voting: 'Голосование',
    questions_for_voting: 'Вопросы для голосования',
    end_of_voting: 'Завершение голосования...',
    please_wait: 'Пожалуйста, подождите...',
    for: 'За',
    against: 'Против',
    abstained: 'Воздержался',
    complete: 'Завершить',
  },

  en: {
    voting: 'Voting',
    questions_for_voting: 'Questions for voting',
    end_of_voting: 'End of voting...',
    please_wait: 'Please wait...',
    for: 'For',
    against: 'Against',
    abstained: 'Abstained',
    complete: 'Complete',
  },
}