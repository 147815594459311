import * as emergencyWorksApi from '@/api/emergencyWorks';

const state = {
  list: [],
};

const mutations = {
  SET_LIST(state, data) {
    state.list = data
  }
}

const actions = {
  getList({ commit }) {
    return emergencyWorksApi
      .getList()
      .then((response) => {
        commit('SET_LIST', response.data);

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations
};