<template>
  <main class="main-content">
    <div class="meeting-owners_section">
      <div class="container">
        <div class="main-title">
          {{ $t('welcome_to') }}
          <small>{{ $t('homeowners_meeting') }}</small>
        </div>
        <div class="row">
          <div class="meeting-owners_col-left">
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod"><span class="glipf-info"></span>{{ $t('general_information') }}</div>
              <div class="meeting-information_box">
                <div class="row">
                  <div class="col-md-8">
                    <ul class="meeting-information_list">
                      <li>
                        <span class="item-title">{{ $t('title_of_document') }}:</span>
                        {{ oss.MeetingTitle }}
                      </li>
                      <li>
                        <span class="item-title">{{ $t('initiator_of_meeting') }}: </span>
                        {{ oss.Author }}
                      </li>
                      <li>
                        <span class="item-title">{{ $t('house_address') }}: </span>
                        {{ oss.HouseAddress }}
                      </li>
                      <li>
                        <span class="item-title">{{ $t('details_confirming_ownership') }}:</span>
                        <p v-for="account in oss.Accounts" :key="`oss_acc-${account.Ident}-${account.PremiseNumber}`">
                          {{ account.Document }}{{ account.DocumentDate ? ' ' + $t('document_from') + ' ' + account.DocumentDate : '' }}
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-4">
                    <ul class="meeting-information_list">
                      <li><span class="item-title">{{ $t('property_number') }}:</span>{{ account_premises }}</li>
                      <li><span class="item-title">{{ $t('area') }}: </span>{{ account_areas }}</li>
                      <li><span class="item-title">{{ $t('share') }}:</span>{{ account_ownership }}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-8">
                  <ul class="meeting-information_list">
                    <li class="last-child">
                      <span class="item-title">{{ $t('meeting_date') }}: </span>
                      {{ $t('from') }} {{ oss.DateStart }} {{ $t('to') }} {{ oss.DateEnd }} {{ $t('inclusive') }}.
                    </li>
                  </ul>
                </div>
                <div class="col-md-4">
                  <ul class="meeting-information_list">
                    <li class="last-child">
                      <span class="item-title">{{ $t('form_of_meeting') }}:</span>
                      {{ oss.Form }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod"><span class="glipf-agenda"></span>{{ $t('meeting_agenda') }}</div>
              <ul class="meeting-information_list">
                <li v-for="question in oss.Questions" :key="`question-${question.ID}`">
                  <span class="item-title">{{ $t('question') }} № {{ question.Number }}</span>
                  {{ question.Text }}
                  <div class="item-file" v-if="question.HasFile">
                    <span class="glipf-pdf"></span>
                    <span class="item-desc"
                      >{{ $t('discussion_file') }}.pdf<br /><a :href="question.FileLink" download="">{{ $t('download') }}</a></span
                    >
                  </div>
                </li>
              </ul>
            </div>

            <!--Сведения об администраторе-->
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod"><span class="glipf-admin2"></span>{{ $t('administrator_information') }}</div>
              <template v-if="oss.AdministratorIsFL">
                <div class="meeting-information_box">
                  <div class="row">
                    <ul class="meeting-information_list">
                      <li><span class="item-title">{{ $t('fio') }}: </span>{{ oss.AdminstratorName }}</li>
                      <li><span class="item-title">{{ $t('passport_details') }}: </span>{{ oss.AdminstratorDocNumber }}</li>
                      <li><span class="item-title">{{ $t('place_of_permanent_residence') }}</span>{{ oss.AdminstratorAddress }}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorPhone">
                        <span class="item-title">{{ $t('contact_phone_number') }}: </span>
                        <a :href="`tel:${oss.AdminstratorPhone}`" class="admin-phone">{{ oss.AdminstratorPhone }}</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorEmail">
                        <span class="item-title">{{ $t('email_address') }}: </span>
                        <a :href="`mailto:${oss.AdminstratorEmail}`" class="admin-email">{{ oss.AdminstratorEmail }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="meeting-information_box">
                  <div class="row">
                    <ul class="meeting-information_list">
                      <li><span class="item-title">{{ $t('brand_name') }}:</span>{{ oss.AdminstratorName }}</li>
                      <li>
                        <span class="item-title">{{ $t('organizational_legal_form') }}:</span>{{ oss.AdminstratorDocNumber }}
                      </li>
                      <li>
                        <span class="item-title">{{ $t('location') }}: </span>
                        {{ oss.AdminstratorAddress }}
                      </li>
                      <li><span class="item-title">{{ $t('mailing_address') }}: </span>{{ oss.AdminstratorPostAddress }}</li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorPhone">
                        <span class="item-title">{{ $t('contact_phone_number') }}: </span>
                        <a :href="`tel:${oss.AdminstratorPhone}`" class="admin-phone">{{ oss.AdminstratorPhone }}</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-md-6">
                    <ul class="meeting-information_list">
                      <li v-if="oss.AdminstratorSite">
                        <span class="item-title">{{ $t('official_site') }}: </span>
                        <a :href="oss.AdminstratorSite" class="site-link">{{ oss.AdminstratorSite }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="meeting-owners_col-right">
            <!--Статус собрания-->
            <div class="unified-box unified-box_mod unified-box_status">
              <div class="unified-box_title-mod">{{ $t('meeting_status') }}:</div>

              <div :class="'status-meeting ' + oss_status.statusClass">
                <span :class="oss_status.glipf"></span>{{ oss_status.statusText }}
              </div>
              <!-- <a href="" class="main-btn">Принять участие</a> -->
              <router-link v-if="oss_status.canVote" class="main-btn" :to="{ name: 'OssVote', params: { id: oss.ID } }"
                >{{ $t('participate') }}</router-link
              >
              <router-link
                v-if="oss_status.canViewResults"
                class="main-btn"
                :to="{ name: 'OssResult', params: { id: oss.ID } }"
                >{{ $t('voting_results') }}</router-link
              >
            </div>

            <!--Сведения о собственности-->
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod"><span class="glipf-briefcase"></span>{{ $t('property_details') }}</div>
              <ul class="meeting-information_list">
                <li>
                  <span class="item-title">{{ $t('details_confirming_ownership') }}: </span>
                  <p v-for="account in oss.Accounts" :key="`oss_acc-${account.Ident}-${account.PremiseNumber}`">
                    {{ account.Document }}{{ account.DocumentDate ? ' ' + $t('document_from') + ' ' + account.DocumentDate : '' }}
                  </p>
                </li>
              </ul>
              <div class="row">
                <div class="col-md-7">
                  <ul class="meeting-information_list">
                    <li><span class="item-title">{{ $t('property_number') }}: </span>{{ account_premises }}</li>
                    <li><span class="item-title item-title_mod">{{ $t('share') }}:</span>{{ account_ownership }}</li>
                  </ul>
                </div>
                <div class="col-md-5">
                  <ul class="meeting-information_list">
                    <li><span class="item-title">{{ $t('area') }}: </span>{{ account_areas }}</li>
                  </ul>
                </div>
              </div>
              <ul class="meeting-information_list">
                <li class="last-child">
                  <span class="item-title">{{ $t('total_area_of_premises') }}: </span>{{ $t('residential') }}: {{ oss.AreaResidential }} {{ $t('sq_m') }}<br />
                  {{ $t('non_residential') }}: {{ oss.AreaNonresidential }} {{ $t('sq_m') }}
                </li>
              </ul>
            </div>

            <!--Порядок приёма решений-->
            <div class="unified-box unified-box_mod">
              <div class="unified-box_title-mod"><span class="glipf-decision"></span>{{ $t('procedure_for_making_decisions') }}</div>
              <ul class="meeting-information_list">
                <li>
                  <span class="item-title">{{ $t('electronic_samples') }}: </span>
                  <a :href="oss.WebSiteForScanDocView">{{ oss.WebSiteForScanDocView }}</a>
                </li>
                <li class="last-child">
                  <span class="item-title">{{ $t('handwritten_solutions') }}: </span>
                  {{ oss.PlaceOfReceiptSolutions }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'OssInfo',
  data() {
    return {
      //ossIDs: [],
      oss: {},
      isLoading: false,
    };
  },
  mounted() {
    this.getOSS();
  },
  methods: {
    getOSS() {
      this.isLoading = true;
      this.$store
        .dispatch('oss/getOssById', this.idOss)
        .then((result) => {
          this.oss = result;
          this.isLoading = false;
          this.$store.dispatch('oss/setAcquainted', this.idOss);
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    idOss() {
      return this.$route.params.id;
    },
    //   oss() {
    //     return this.$store.getters['oss/getOssById'](this.idOss);
    //   },
    account_premises() {
      var oss = this.oss;
      var result = '';
      if (!oss.Accounts) {
        return result;
      }
      for (let i = 0; i < oss.Accounts.length; i++) {
        if (i > 0) {
          result += '; ';
        }
        result += oss.Accounts[i].PremiseNumber;
      }
      return result;
    },
    account_areas() {
      var oss = this.oss;
      var result = '';
      if (!oss.Accounts) {
        return result;
      }
      for (let i = 0; i < oss.Accounts.length; i++) {
        if (i > 0) {
          result += '; ';
        }
        result += oss.Accounts[i].Area;
      }
      return result + ' м.кв.';
    },
    account_ownership() {
      var oss = this.oss;
      var result = '';
      if (!oss.Accounts) {
        return result;
      }
      for (let i = 0; i < oss.Accounts.length; i++) {
        if (i > 0) {
          result += '; ';
        }
        result += oss.Accounts[i].PropertyPercent;
      }
      return result + ' %';
    },
    oss_status() {
      return this.$store.getters['oss/getStateParameters'](this.oss);
    },
  },
};
</script>

<style scoped></style>
