import axios from 'axios';

export function login({ phone, password }) {
  return axios.post('/auth/login', { phone, password });
}

export function updateProfile({ fio, email, onlyElectronicBills }) {
  return axios.post('/User/UpdateProfile', { fio, email, onlyElectronicBills });
}

export function getRequestAccessCode({ phone }) {
  return axios.post('/Auth/RequestAccessCode', { phone });
}

export function sendCheckCodeSMS({ phone }) {
  return axios.post('/Auth/SendCheckCode', { phone });
}

export function requestAccessCode({ phone, dontCheck }) {
  return axios.post('/Auth/RequestAccessCode', { phone, dontCheck });
}

export function getUserInfo() {
  return axios.get('/User/Info');
}

export function getInfo() {
  return axios.get('/Accounting/Info');
}

export function checkAccessCode({ phone, code }) {
  return axios.post('/Auth/CheckAccessCode', { phone, code });
}

export function registerByPhone({ fio, phone, password, code, birthday }) {
  return axios.post('/Auth/RegisterByPhone', {
    fio,
    phone,
    password,
    code,
    birthday,
  });
}

export function deleteAccountByIdent({ ident }) {
  return axios.post('/User/DeleteAccountByIdent', { ident });
}

export function addAccountByIdent({ ident, pinCode, clientBaseID, billAmount }) {
  return axios.post('/User/AddAccountByIdent', { ident, pinCode, clientBaseID, billAmount });
}

export function addAccountByAddress({ houseId, premiseNumber, lastName, inn, pinCode, clientBaseID, billAmount }) {
  return axios.post('/User/AddAccountByAddress', { houseId, premiseNumber, lastName, inn, pinCode, clientBaseID, billAmount });
}

export function getPayLink({
  accountID,
  sum,
  sumFine,
  payInsurance,
  paymentSystem,
  payAll,
  allowCardSaving,
  paidRequestId,
  bonusAmount,
}) {
  return axios.post('/PayOnline/GetPayLink', {
    accountID,
    payAll,
    sum,
    sumFine,
    payInsurance,
    paymentSystem,
    allowCardSaving,
    SuccessUrl: location.href + '?paymentSuccess',
    FailUrl: location.href + '?paymentFail',
    paidRequestId,
    bonusAmount,
  });
}

export function getPaymentSystems(accountId) {
  if (accountId) {
    return axios.get(`/PayOnline/GetPaymentSystemsList/`, {
      params: {
        accountId,
      },
    });
  } else {
    return axios.get(`/PayOnline/GetPaymentSystemsList/`);
  }
}

/**
 * Метод возвращает параметры оплаты для переданных настроек.
 * @param {object} Праметры запроса:
 * @param {boolean} payAll true, если оплата идет по всем подключенным лицевым счетам
 * @param {integer} accountId ID лицевого счета (обязательно, если PayAll = false)
 * @param {string} paymentSystem платежная система (обязательно, если происходит выбор системы из списка)
 * @returns {Promise<GetPaymentParametersResponse>} Праметры эквайрига по ЛС
 */
export function getPaymentParameters({ payAll, accountId, paymentSystem }) {
  return axios.post('/PayOnline/GetPaymentParameters', {
    payAll,
    accountId,
    paymentSystem
  })
}

/**
 * https://wiki.sm-center.ru:1085/doku.php?id=api_core#%D0%BC%D0%B5%D1%82%D0%BE%D0%B4_getinvoices_get
 * Метод возвращает список доступных для оплаты счетов
 * @param {number} accountId ИД аккаунта
 * @returns {Promise<InvoiceDetails>} список счетов
 */
export function getInvoices(accountId) {
  return axios.get('/PayOnline/GetInvoices/', {
    params: {
      accountId
    }
  })
}

/**
 * https://wiki.sm-center.ru:1085/doku.php?id=api_core#%D0%BC%D0%B5%D1%82%D0%BE%D0%B4_getpayinvoicelink_post
 * Метод возвращает ссылку на оплату по счету.
 * @param {object} payload Параметры: 
 * @param {string} invoiceUid – (обязательно) идентификатор счета на оплату (см. GetInvoices)
 * @param {string} PaymentSystem - система оплаты (поле Name объектов, возвращаемых GetPaymentSystemsList либо null для использования системы оплаты по умолчанию )
 * @param {boolean} AllowCardSaving - если true, для оплаты разрешено сохранение и использование ранее сохраненной карты
 * @param {string} SuccessUrl - урл возврата в случае успешной оплаты
 * @param {string} FailUrl - урл возврата в случае ошибки оплаты
 * @returns {Promise<payLink>} ссылка на оплату
 */
export function getPayInvoiceLink({ invoiceUid, paymentSystem, allowCardSaving }) {
  return axios.post('/PayOnline/GetPayInvoiceLink', {
    invoiceUid,
    paymentSystem,
    allowCardSaving,
    successUrl: location.href + '?paymentSuccess',
    failUrl: location.href + '?paymentFail',
  })
}

export function getSumWithCommission({ sum, accountID }) {
  return axios.get('/Accounting/SumWithComission', {
    params: { sum, accountID },
  });
}

export function disableAccountInsurance({ accountID }) {
  return axios.post('/Accounting/DisableAccountInsurance', { accountID });
}

export function getAccountPrefixes() {
  return axios.get('/User/AccountPrefixes');
}

export function deleteMobileUser() {
  return axios.post('/User/DeleteMobileUser');
}

export function getClientBases() {
  return axios.get('/User/GetClientBases');
}

/**
 * Скачать Финансовый лицевой счет.
 * TODO: Ссылка на документацию
 * GET public HttpResponseMessage DownloadSignedFile(string ident) (вернет zip)
 *
 * @param {Object} Параметры запроса:
 * @param {String} Ident Номер лицевого счета
 *
 * @returns {Blob} Файл ZIP
 */
export function downloadSignedFile({ ident }) {
  return axios.get('RostovCustom/DownloadSignedFile/', {
    params: {
      Ident: ident,
    },
    responseType: 'blob',
  });
}

/**
 * Скачать файл выписки.
 * TODO: Ссылка на документацию
 * GET public HttpResponseMessage DownloadDetailsFile(string ident, int inJpg = 1) (вернет PDF если InJPG=0)
 *
 * @param {Object} Параметры запроса:
 * @param {String} Ident Номер лицевого счета
 *
 * @returns {Blob} Файл PDF или JPG
 */
export function downloadDetailsFile({ ident }) {
  return axios.get('RostovCustom/DownloadDetailsFile', {
    params: {
      Ident: ident,
      InJPG: 0,
    },
    responseType: 'blob',
  });
}

/**
 * Скачать файл сверки.
 * TODO: Ссылка на документацию
 * GET public HttpResponseMessage DownloadControlFile(string ident, int inJpg = 1) (вернет PDF если InJPG=0)
 *
 * @param {Object} Параметры запроса:
 * @param {String} Ident Номер лицевого счета
 *
 * @returns {Blob} Файл PDF или JPG
 */
export function downloadControlFile({ ident }) {
  return axios.get('RostovCustom/DownloadControlFile', {
    params: {
      Ident: ident,
      InJPG: 0,
    },
    responseType: 'blob',
  });
}
