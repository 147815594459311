<template>
  <div class="shop-page">
    <page-header
      :back="{
        title: $t('service'),
        to: { name: 'Services' },
      }"
    />

    <!-- Top -->
    <section class="shop-page__header">
      <div class="shop-page__header-wrap">
        <h1 class="shop-page__title">
          {{ title }}
        </h1>

        <base-button style-class="text" @click="sortDirection = sortsSwitch[sortDirection].name">
          {{ $t('by_price') }}
          <component :is="sortingIcon" class="shop-page__sort-icon" />
        </base-button>
      </div>
    </section>

    <div class="shop-page__container">
      <!-- Left column: Goods list -->
      <section class="shop-page__column shop-page__column--left">
        <div class="shop-page__goods-list">
          <shop-item v-for="good in goods" :key="`good-${good.ID}`" :good="good" />
        </div>
      </section>

      <!-- Right column: Total amount card -->
      <section id="cart" class="shop-page__column shop-page__column--right">
        <div class="amount-card">
          <ul class="prices">
            <li v-if="isGoodsType" class="prices__field">
              <label class="prices__title">{{ $t('weight') }}:</label>
              <span class="prices__value"> {{ cartWeight }} {{ $t('kg') }} </span>
            </li>

            <li class="prices__field">
              <label class="prices__title">{{ $t('total_payable') }}:</label>
              <span class="prices__value"> {{ cartCost }} ₽ </span>
              <!-- <p 
                v-if="isGorserv" 
                class="prices__sub"
              >
                Мин. сумма заказа 1000р.
              </p> -->
            </li>
          </ul>

          <base-button
            :disabled="cartIsEmpty"
            class="amount-card__button"
            @click="
              $router.push({
                name: 'UserCart',
                params: $route.params,
              })
            "
          >
            {{ $t('place_order') }}
          </base-button>
        </div>
      </section>
    </div>
    <!-- / .shop-page__container -->
  </div>
</template>

<script>
import cartMixin from '@/mixins/cart';
import additionalServiceMixin from '@/mixins/additionalService';

import { ArrowDownIcon, ArrowUpIcon } from '@vue-hero-icons/outline';
import PageHeader from '@/components/pages/common/PageHeader';
import ShopItem from '@/components/pages/services/ShopItem';
import BaseButton from '@/components/ui/BaseButton';

import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Shop',

  components: {
    ShopItem,
    PageHeader,
    ArrowDownIcon,
    ArrowUpIcon,
    BaseButton,
  },

  mixins: [additionalServiceMixin, cartMixin],

  data() {
    return {
      selectedCategory: '',
      searchText: '',
      cart: [],
      sortDirection: 'asc',
      sortsSwitch: {
        asc: { name: 'desc', sortFn: (a, b) => a.Price - b.Price },
        desc: { name: 'asc', sortFn: (a, b) => b.Price - a.Price },
      },
    };
  },

  computed: {
    ...mapGetters({
      categories: 'additionalService/getCategories',
      servicesGroups: 'additionalService/getServicesByGroups',
      getServiceById: 'additionalService/getServiceById',
      getGoodsList: 'additionalService/getGoodsList',
    }),

    service() {
      return this.getServiceById(+this.$route.params.AdditionalServiceId);
    },

    title() {
      return this.service.Group;
    },

    goods() {
      return this.getGoodsList(this.selectedCategory)
        .filter((item) => {
          if (this.searchText) {
            return item.Name.toUpperCase().search(this.searchText.toUpperCase()) > -1;
          }
          return true;
        })
        .sort(this.sortsSwitch[this.sortDirection].sortFn);
    },

    sortingIcon() {
      return `arrow-${this.sortDirection === 'asc' ? 'up' : 'down'}-icon`;
    },
  },

  created() {
    this.getGoods(this.$route.params.id);

    if (this.servicesGroups.length <= 0) {
      this.getEventBlockData();
    }
  },

  methods: {
    ...mapActions({
      getGoods: 'additionalService/getGoods',
    }),
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.shop-page
  .amount-card
    display: flex
    justify-content: space-between
    align-items: center

    background: $cBgMain
    box-shadow: none
    border-top: 1px solid $cBorder
    border-radius: 0
    padding: 16px

    @media screen and (min-width: 992px)
      flex-direction: column
      padding: 24px
      box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
      border: none
      border-radius: 16px

    .prices
      margin-bottom: 0

      @media screen and (min-width: 992px)
        width: 100%
        margin-bottom: 16px

    .prices__field
      display: flex
      flex-direction: column
      flex-wrap: wrap
      justify-content: space-between

      &:not(:last-child)
        margin-bottom: 8px

      @media screen and (min-width: 768px)
        flex-direction: row
        justify-content: flex-start

      @media screen and (min-width: 992px)
        justify-content: space-between

    .prices__title
      +TextHeader(2)

      @media screen and (min-width: 768px)
        margin-right: 8px

      @media screen and (min-width: 992px)
        margin-right: 0

    .prices__value
      +TextHeader(2)

    .prices__sub
      +TextMain()

      display: inline-block
      width: 100%
      margin-top: 8px
      margin-bottom: 0

  .amount-card__button
    width: auto

    @media screen and (min-width: 992px)
      width: 100%

.shop-page__header-wrap
  width: 100%
  max-width: 600px
  display: flex
  justify-content: space-between
  align-items: center

  @media screen and (min-width: 768px)
    align-items: flex-end

  @media screen and (min-width: 1200px)
    max-width: 640px

  @media screen and (min-width: 1400px)
    max-width: 730px

.shop-page__header
  width: 100%
  margin-bottom: 16px

.shop-page__title
  +TextHeader(0)
  margin-bottom: 0

.shop-page__sort-icon
  +Size(16px)

  color: $cAccent

.shop-page__container
  display: flex
  flex-wrap: wrap
  flex-direction: column-reverse

  @media screen and (min-width: 992px)
    flex-wrap: nowrap
    flex-direction: row

.shop-page__column
  &--left
    width: 100%
    max-width: 600px
    flex-shrink: 0

    @media screen and (min-width: 1200px)
      max-width: 640px

    @media screen and (min-width: 1400px)
      max-width: 730px

  &--right
    flex-shrink: 1
    width: 100%
    padding-left: 0
    position: fixed
    left: 0
    right: 0
    bottom: 0
    z-index: 10

    @media screen and (min-width: 992px)
      padding-left: 24px
      position: static
</style>
