<template>
  <div class="accounts-list">
    <div
      v-for="(account, i) in accounts"
      :key="`account-${i}`"
      class="form-box__field"
    >
      <div class="field field--horizontal">
        <div class="field__group">
          <div class="field__control">
            <label class="field__label field__label--main">
              <span>{{ $t('short_personal_account') }}:</span> {{ account.Ident }}
              <br />
              {{ account.AccountType }}
            </label>
            <p class="field__input">
              {{ account.Address }}
            </p>
          </div>
          <base-button
            style-class="text-mini"
            modificator="error"
            class="field__button"
            @click="removeAccount(account.Id)"
          >
            {{ $t('delete') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'ProfileAccountsList',

  components: {
    BaseButton,
  },

  props: {
    closed: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo',
    }),

    accounts() {
      let accounts = this.getAllInfo;

      if (this.isDgservice) {
        accounts = this.getAllInfo.filter(
          (account) => {
            if (/^\d+$/.test(account.Ident)) return true;
          }
        );
      }

      if (this.closed) return accounts.filter(account => account.IsClosed);
      return accounts.filter(account => !account.IsClosed);
    },
  },

  methods: {
    ...mapActions({
      deleteAccountByIdent: 'account/deleteAccountByIdent',
    }),

    removeAccount(id) {
      const account = this.accounts.find((account) => account.Id === id);

      this.openDialog({
        title: this.$t('deleting_personal_account'),
        text: `${this.$t('sure_delete_personal_account')} №${account.Ident} (${account.Address})?`,
        buttons: [
          {
            type: 'additional',
            title: this.$t('cancel'),
            handler: () => {
              this.cancelDialog();
            },
          },
          {
            type: 'base',
            title: this.$t('confirm'),
            handler: () => {
              this.deleteAccountByIdent(account.Ident)
                .then((result) => {
                  if (result && result.error) {
                    this.$notify.error(result.error);
                    return;
                  }

                  this.$notify.success(`${this.$t('personal_account')} №${account.Ident} ${this.$t('deleted')}.`);
                })
                .catch((error) => {
                  this.$notify.error(error);
                });

              this.cancelDialog();
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/mixins';
@import '../../../sass/components/form/form';
@import '../../../sass/components/form/input';

.form-box__field:last-of-type {
  margin-bottom: 0;
}</style>
