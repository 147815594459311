import * as authService from '../services/auth.service';

export default {
  computed: {
    isAuth() {
      return this.$store.getters['account/isAuth'] || authService.isAuthUser();
    },
    authUser() {
      let userStore = this.$store.getters['account/getUser'];
      if (userStore.id) {
        return userStore;
      } else {
        return authService.getUserData();
      }
    },
  },
};
