var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-box__field"},[_c('div',{class:[
      'field', 
      { 'field--error': _vm.hasError || _vm.error },
      { 'field--active': (_vm.value && _vm.value.toString().length > 0) || _vm.fieldIsFocus },
      { 'field--popover': _vm.showPopover },
      { 'field--horizontal': _vm.horizontal },
      { 'field--has-button': _vm.editButton || _vm.$slots.button },
      { 'field--no-label': !_vm.label },
    ]},[_c('div',{staticClass:"field__group"},[_c('div',{staticClass:"field__control"},[(_vm.label)?_c('label',{staticClass:"field__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('input',_vm._g({ref:_vm.ref,staticClass:"field__input",style:(_vm.inputStyles),attrs:{"name":_vm.name,"type":_vm.inputType,"placeholder":_vm.placeholder,"autocomplete":_vm.autocomplete,"step":_vm.inputType === 'number' ? _vm.step : false,"readonly":_vm.readonly || (_vm.editButton && !_vm.editable),"pattern":_vm.pattern},domProps:{"value":_vm.value},on:{"input":_vm.inputHandler}},_vm.toggleInputFocus()))]),(_vm.type === 'password')?[_c(_vm.eyePassword,{tag:"component",class:[
            'field__icon',
            { 'field__icon--hide': _vm.showPassword }
          ],on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}})]:_vm._e(),_vm._t("icon"),_vm._t("button",function(){return [(_vm.editButton)?_c('base-button',{staticClass:"field__button",attrs:{"style-class":"secondary-text-mini"},on:{"click":_vm.editButtonHandler}},[_vm._v(" "+_vm._s(_vm.editable ? _vm.$t('save') : _vm.$t('change'))+" ")]):_vm._e()]})],2),_c('p',{directives:[{name:"show",rawName:"v-show",value:(((_vm.hasError || _vm.error) && _vm.message) || _vm.message),expression:"((hasError || error) && message) || message"}],staticClass:"field__message"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopover),expression:"showPopover"}],staticClass:"field__popover"},[_vm._t("popover")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }