import { render, staticRenderFns } from "./RequestsLayout.vue?vue&type=template&id=294e4313&scoped=true"
import script from "./RequestsLayout.vue?vue&type=script&lang=js"
export * from "./RequestsLayout.vue?vue&type=script&lang=js"
import style0 from "./RequestsLayout.vue?vue&type=style&index=0&id=294e4313&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "294e4313",
  null
  
)

export default component.exports