<template>
  <modal
    ref="chatDialog"
    :title="$t('support_chat')"
    :chatMode="true"
    class="modal--support-chat"
    @close="$emit('close')"
    :key="'supportModalChat'"
  >
    <support-chat @close-chat="$emit('close')" />
  </modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import supportChat from '@/components/SupportChat';

export default {
  name: 'supportModalChat',

  components: {
    Modal,
    supportChat,
  },
};
</script>
