import * as authService from '../../services/auth.service';
import * as accountApi from '../../api/account';
import { normalizeData } from '@/services/common';
import Vue from 'vue';
// import axios from "axios";
import download from 'downloadjs';
import i18n from '@/plugins/i18n';

const state = {
  user: {}, //this is auth user,
  info: [],
  selectedIdentAddress: null,
  accountPrefixes: [],
  userInfo: {},
  canPayForAllAccounts: false,
  clientBases: [],
};

const actions = {
  syncUserData({ commit }) {
    let localUser = authService.getUserData();
    commit('SET_USER', localUser);
  },

  /**
   * Вход на форме авторизации
   * @param {Object} store
   * @param {Object} Параметры запроса:
   * @param {String} phone Логин (номер телефона)
   * @param {String} password Пароль
   * @returns {Promise} Результат запроса
   */
  async login({ commit }, { phone, password }) {
    return await accountApi
      .login({ phone, password })
      .then((response) => {
        if (response.status === 200) {
          authService.setAuthData(response.data.acx);
          commit('SET_USER', response.data);
        }

        return response.data || {};
      })
      .catch((error) => {
        throw error;
      });
  },

  sendCheckCodeSMS(context, { phone }) {
    return accountApi
      .sendCheckCodeSMS({ phone })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  requestAccessCode(_, { phone, dontCheck }) {
    return accountApi
      .requestAccessCode({ phone, dontCheck })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  getRequestAccessCode(context, { phone }) {
    return accountApi
      .getRequestAccessCode({ phone })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  getInfo({ commit }) {
    return accountApi
      .getInfo()
      .then((response) => {
        commit('setCanPayForAllAccounts', response.data.CanPayForAllAccounts);

        return normalizeData(response.data.Data, 'AccountID');
      })
      .then((result) => {
        if (result.keys[0]) {
          commit('setSelectedIdentAddress', result.keys[0]);
        }

        commit('setInfo', result.list);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  },

  async getUserInfo({ commit }) {
    return await accountApi
      .getUserInfo()
      .then((response) => {
        commit('SET_USER_INFO', response.data);
      })
      .catch((error) => {
        throw error;
      });
  },

  updateProfile({ commit }, data) {
    return accountApi
      .updateProfile(data)
      .then((response) => {
        if (response.data.isSucceed) {
          commit('SET_USER', data);
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  checkAccessCode(context, { phone, code }) {
    return accountApi
      .checkAccessCode({ phone, code })
      .then((response) => {
        let result = response.data || {};
        if (response.status === 200) {
          return result;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  /**
   * Регистрация
   * @param {Object} Параметры запроса:
   * @param {String} fio ФИО
   * @param {String} phone Телефон
   * @param {String} password Пароль
   * @param {String} code Код подтверждения телефона
   * @param {String} birthday Дата рождения
   * @returns
   */
  registerByPhone(_, { fio, phone, password, code, birthday }) {
    // console.log('--- registerByPhone action ---')
    return accountApi
      .registerByPhone({ fio, phone, password, code, birthday })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }

        return response.data || {};
      })
      .catch((error) => {
        throw error;
      });
  },

  deleteAccountByIdent({ dispatch, commit }, ident) {
    return accountApi
      .deleteAccountByIdent({ ident })
      .then((response) => {
        commit('deleteInfo', ident);
        dispatch('getInfo');

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  async addAccountByIdent({ commit, dispatch }, { ident, pinCode, clientBaseID, billAmount }) {
    return await accountApi
      .addAccountByIdent({ ident, pinCode, clientBaseID, billAmount })
      .then((response) => {
        if (response.data.acx) {
          authService.setAuthData(response.data.acx);
          // axios.defaults.headers.common["acx"] = response.data.acx;

          commit('SET_USER', response.data);
          dispatch('getInfo');
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  async addAccountByAddress({ commit, dispatch }, { houseId, premiseNumber, lastName, inn, pinCode, clientBaseID, billAmount }) {
    return await accountApi
      .addAccountByAddress({ houseId, premiseNumber, lastName, inn, pinCode, clientBaseID, billAmount })
      .then((response) => {
        if (response.data.acx) {
          authService.setAuthData(response.data.acx);
          // axios.defaults.headers.common["acx"] = response.data.acx;

          commit('SET_USER', response.data);
          dispatch('getInfo');
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPayLink(
    _,
    { accountID, sum, sumFine, payInsurance, paymentSystem, payAll, allowCardSaving, paidRequestId, bonusAmount },
  ) {
    return accountApi
      .getPayLink({
        accountID,
        sum,
        sumFine,
        payInsurance,
        paymentSystem,
        payAll,
        allowCardSaving,
        paidRequestId,
        bonusAmount,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPaymentSystems(_, accountId) {
    return accountApi
      .getPaymentSystems(accountId)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getPaymentParameters(_, payload) {
    return accountApi
      .getPaymentParameters(payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  getInvoices(_, accountId) {
    return accountApi
      .getInvoices(accountId)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

  getPayInvoiceLink(_, payload) {
    return accountApi
      .getPayInvoiceLink(payload)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      })
  },

  disableAccountInsurance({ commit }, accountID) {
    return accountApi
      .disableAccountInsurance({ accountID })
      .then((response) => {
        commit('disableStoredAccountInsurance', accountID);

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getSumWithCommission(_, { sum, accountID }) {
    return accountApi
      .getSumWithCommission({ sum, accountID })
      .then((response) => {
        let result = response.data;

        return {
          TotalSum: result.TotalSum,
          Commission: result.Comission,
          HideComissionInfo: result.HideComissionInfo,
        };
      })
      .catch((error) => {
        throw error;
      });
  },

  logout({ commit }) {
    authService.resetAuthData();
    commit('SET_USER', {});
  },

  getAccountPrefixes({ commit }) {
    return accountApi
      .getAccountPrefixes()
      .then((response) => {
        commit('setAccountPrefixes', response.data);
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  deleteMobileUser() {
    // console.log('--- deleteMobileUser action ---')
    return accountApi
      .deleteMobileUser()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getClientBases({ commit }) {
    return accountApi
      .getClientBases()
      .then((response) => {
        commit('setClientBases', response.data);
      })
      .catch((error) => {
        throw error;
      });
  },

  downloadSignedFile(_, ident) {
    return accountApi
      .downloadSignedFile({
        ident: ident,
      })
      .then((response) => {
        if (response.data.size > 0) {
          download(response.data, `${i18n.t('financial_personal_account')} № ${ident}`, response.headers['content-type']);
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  downloadDetailsFile(_, ident) {
    return accountApi
      .downloadDetailsFile({
        ident: ident,
      })
      .then((response) => {
        if (response.data.size > 0) {
          download(response.data, `${i18n.t('personal_account_statement')} № ${ident}`, response.headers['content-type']);
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  downloadControlFile(_, ident) {
    return accountApi
      .downloadControlFile({
        ident: ident,
      })
      .then((response) => {
        if (response.data.size > 0) {
          download(response.data, `${i18n.t('personal_account_reconciliation_file')} № ${ident}`, response.headers['content-type']);
        }
      })
      .catch((error) => {
        throw error;
      });
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },

  getInfoByIdent: (state) => (ident) => state.info[ident] || {},
  getSelectedAddress: (state) => state.info[state.selectedIdentAddress] || {},
  getInfos: (state) => (list) => list.map((ident) => state.info[ident] || {}),
  getAllInfo: (state) => Object.values(state.info),

  isAuth(state) {
    return !!(state.user && state.user.email && state.user.email.length > 0);
  },

  accountPrefixes: (state) => state.accountPrefixes,

  getAccounts: ({ userInfo }) => userInfo.Accounts || null,

  canPayForAllAccounts: (state) => state.canPayForAllAccounts,

  getClientBases: (state) => state.clientBases || [],

  getAccountSettings:
    ({ user }) =>
    (accountId) =>
      user.accounts.find((account) => account.id === accountId) || false,
};

const mutations = {
  SET_USER(state, data) {
    state.user = {
      ...state.user,
      ...data,
    };

    authService.setUserData(state.user);
  },

  setInfo(state, data) {
    state.info = data;
  },

  deleteInfo(state, ident) {
    Vue.delete(state.info, ident);

    if (state.selectedIdentAddress === ident) {
      state.selectedIdentAddress = null;
    }
  },

  setSelectedIdentAddress(state, ident) {
    state.selectedIdentAddress = ident;
  },

  disableStoredAccountInsurance(state, accountID) {
    var info = Object.values(state.info).find((x) => x.AccountID == accountID);
    if (info) {
      info.InsuranceSum = 0;
    }
  },

  setAccountPrefixes(state, accountPrefixes) {
    state.accountPrefixes = accountPrefixes;
  },

  SET_USER_INFO(state, data) {
    state.userInfo = data;
  },

  setCanPayForAllAccounts(state, data) {
    state.canPayForAllAccounts = data;
  },

  setClientBases(state, data) {
    state.clientBases = data;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
