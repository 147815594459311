export default {
  ru: {
    our_service: 'Наш сервис',
    special_offers: 'Специальные предложения',
    additional_service: 'Дополнительная услуга',
  },

  en: {
    our_service: 'Our service',
    special_offers: 'Special offers',
    additional_service: 'Additional service',
  },
}