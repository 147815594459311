export default {
  ru: {
    support: 'Поддержка',
    support_chat: 'Чат поддержки',
    write_to_technical_support: 'Написать в тех.поддержку',
    chat_with_technical_support: 'Чат с тех.поддержкой',
    before_start_communicating_with_technical_support: 'Прежде чем начать общение с нашей тех.поддержкой - ведите свой номер телефона',

    phone_number: 'Номер телефона',
    field_cannot_be_empty: 'Поле не может быть пустым',

    you: 'Вы',
    operator: 'Оператор',
    loading_messages_error: 'Ошибка загрузки сообщений',
    file_sending_error: 'Ошибка отправки файла',
    message_required: 'Напишите вопрос оператору',
    ok: 'Ок',
  },

  en: {
    support: 'Support',
    support_chat: 'Support chat',
    write_to_technical_support: 'Write to technical support',
    chat_with_technical_support: 'Chat with technical support',
    before_start_communicating_with_technical_support: 'Before you start communicating with our technical support, please enter your phone number',

    phone_number: 'Phone number',
    field_cannot_be_empty: 'Field cannot be empty',

    you: 'You',
    operator: 'Operator',
    loading_messages_error: 'Loading messages error',
    file_sending_error: 'File sending error',
    message_required: 'Write a question to the operator',
    ok: 'ok',
  },
}