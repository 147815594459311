export default {
  ru: {
    requests: 'Заявки',
    request: 'Заявка',
    no_requests: 'Нет заявок',
    create_new_request: 'Создать новую заявку',
    create_request: 'создать заявку',

    closed: 'Закрытые',
    confirmation_of_request_closure: 'Подверждение закрытия заявки',
    closing_request: 'Закрытие заявки',
    evaluate_quality_of_execution_of_request: 'Оцените качество выполнения заявки',
    comment_on_closed_request: 'Комментарий к закрываемой заявке',

    reopen: 'Переоткрыть',
    reopening_request: 'Переоткрытие заявки',
    reopened: 'Переоткрыта',
    reason_for_reopening: 'Причина переоткрытия',
    enter_reason: 'Введите причину',

    cancel_request: 'Отменить заявку',
    cancellation_of_request: 'Отмена заявки',
    sure_cancel_request: 'Вы уверены, что ходите отменить заявку',
    canceled: 'Отменена',
    success: 'Успех',

    message: 'Сообщение',
    file_name_error: 'Ошибка названия файла',
    file_download_error: 'Ошибка загрузки файла',
  },

  en: {
    requests: 'Requests',
    request: 'Request',
    no_requests: 'No requests',
    create_new_request: 'Create a new request',
    create_request: 'create a request',

    closed: 'Closed',
    confirmation_of_request_closure: 'Confirmation of request closure',
    closing_request: 'Closing a request',
    evaluate_quality_of_execution_of_request: 'Evaluate the quality of execution of the request',
    comment_on_closed_request: 'Comment on the closed request',

    reopen: 'Reopen',
    reopening_request: 'Reopening a request',
    reopened: 'Reopened',
    reason_for_reopening: 'Reason for reopening',
    enter_reason: 'Enter reason',

    cancel_request: 'Cancel the request',
    cancellation_of_request: 'Cancellation of request',
    sure_cancel_request: 'Are you sure you want to cancel your request',
    canceled: 'Canceled',
    success: 'Success',

    message: 'Message',
    file_name_error: 'File name error',
    file_download_error: 'File download error',
  },
}