var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data)?_c('article',{staticClass:"service-card"},[_c('router-link',{staticClass:"service-card__detail-link",attrs:{"to":_vm.data.ShopID
        ? {
            name: 'Shop',
            params: {
              id: _vm.data.ShopID,
              AdditionalServiceId: _vm.data.ID,
              type: _vm.data.ShopType,
              id_RequestType: _vm.data.id_RequestType,
            },
          }
        : {
            name: 'AdditionalService',
            params: {
              id: _vm.data.ID,
            },
          }}},[_c('h3',{staticClass:"service-card__title"},[_vm._v(" "+_vm._s(_vm.data.Name)+" ")]),(_vm.data.HasLogo)?_c('figure',{staticClass:"service-card__image"},[_c('img',{attrs:{"src":_vm.data.LogoLink,"alt":_vm.data.Name}})]):_vm._e()])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }