export default {
  ru: {
    back: 'Назад',
    next: 'Далее',
    more: 'Еще',
    more_details: 'Подробнее',
    create: 'Создать',
    enter: 'Войти',
    upload: 'Загрузить',
    download: 'Скачать',
    get: 'Получить',
    confirm: 'Подтвердить',
    save: 'Сохранить',
    change: 'Изменить',
    send: 'Отправить',
    delete: 'Удалить',
    cancel: 'Отмена',
    select_all: 'Выбрать все',
    error: 'Ошибка',
    no_data: 'Нет данных',
    from: 'С',
    to: 'по',
    at: 'в',
    inclusive: 'включительно',
    yes: 'Да',
    no: 'Нет',
    address: 'Адрес',
    question: 'Вопрос',
    total: 'Итого',
    sq_m: 'м.кв.',
    kg: 'кг',
  },

  en: {
    back: 'Back',
    next: 'Next',
    more: 'More',
    more_details: 'More details',
    create: 'Create',
    enter: 'Enter',
    upload: 'Upload',
    download: 'Download',
    get: 'Get',
    confirm: 'Confirm',
    save: 'Save',
    change: 'Change',
    send: 'Send',
    delete: 'Delete',
    cancel: 'Cancel',
    select_all: 'Select all',
    error: 'Error',
    no_data: 'No data',
    from: 'From',
    to: 'to',
    at: 'at',
    inclusive: 'inclusive',
    yes: 'Yes',
    no: 'No',
    address: 'Address',
    question: 'Question',
    total: 'Total',
    sq_m: 'sq.m.',
    kg: 'kg',
  },
}