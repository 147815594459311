<template>
  <Modal
    :title="$t('message_required')"
    @close="$emit('close')"
    class="message-required-modal"
  >
    <div class="message-required-modal__btn-wrapper">
      <button
        class="message-required-modal__btn"
        @click="$emit('close')"
      >
        {{ $t('ok') }}
      </button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';

export default {
  name: 'messageRequiredModal',
  components: { Modal },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.message-required-modal__btn-wrapper
  display: flex;
  justify-content: center;

.message-required-modal__btn
  min-width: 80px;
  padding: 10px 20px;
  
  font-style: normal;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #FFFFFF;
  
  background-color: var(--base-color);
  border: none;
  border-radius: 16px;
  
  transition: filter 0.2s ease, background-color 0.2s ease;

  &:hover
    filter: brightness(110%);
  
  &:active
    filter: brightness(90%);

</style>
