export default {
  ru: {
    authorization: 'Авторизация',
    telephone: 'Телефон',
    password: 'Пароль',
    forgot_password: 'Забыли пароль?',
    incorrect_login_password_pair: 'Не верная пара логин/пароль',
    telephone_required: 'Телефон обязательно',
    password_required: 'Пароль обязательно',
    to_recover_password: 'Для восстановления пароля пройдите повторную регистрацию',

    powered_by_technology: 'Работает на технологиях',
    management_of_apartment_buildings: 'Управление МКД - программы и CRM системы для РСО, УО, ТСН, ТСЖ.',

    create_requests: 'Создавайте заявки',
    to_dispatch_service: 'в диспетчерскую службу',
    discuss_request: 'Обсуждайте заявку',
    in_real_time: 'в реальном времени',
    pass_on_readings: 'Передавайте показания',
    without_leaving_home: 'не выходя из дома',
    pay_bill: 'Оплачивайте квитанцию',
    with_one_button: 'одной кнопкой',
    receive_instantly: 'Мгновенно получайте',
    many_other_features: 'Много других функций',
  },

  en: {
    authorization: 'Authorization',
    telephone: 'Telephone',
    password: 'Password',
    forgot_password: 'Forgot your password?',
    incorrect_login_password_pair: 'Incorrect login/password pair',
    telephone_required: 'Telephone required',
    password_required: 'Password required',
    to_recover_password: 'To recover your password, please re-register',

    powered_by_technology: 'Powered by technology',
    management_of_apartment_buildings: 'Management of apartment buildings - programs and CRM systems for RSO, MO, POA, HOA.',

    create_requests: 'Create requests',
    to_dispatch_service: 'to the dispatch service',
    discuss_request: 'Discuss your requests',
    in_real_time: 'in real time',
    pass_on_readings: 'Pass on your readings',
    without_leaving_home: 'without leaving home',
    pay_bill: 'Pay the bill',
    with_one_button: 'with one button',
    receive_instantly: 'Receive instantly',
    many_other_features: 'Many other features',
  },
}