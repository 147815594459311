<template>
  <div v-loading="loading" class="polls-page" ref="$root">
    <page-header
      :back="{
        title: $t('polls'),
        to: { name: 'Home' },
      }"
      class="polls-page__title"
    />

    <section v-if="polls" class="polls-page__filter">
      <label class="polls-page__filter-label">{{ $t('show_completed_polls') }}</label>
      <toggler v-model="showPassedPolls" />
    </section>

    <div class="polls-page__wrapper">
      <section class="polls-page__list" ref="pollsList">
        <polls-list v-if="polls.length" :items="polls" />

        <alert v-else type="info">{{ $t('no_polls_to_complete') }}</alert>
      </section>

      <section class="polls-page__content" ref="pollContent">
        <div v-if="showPollForm || showPollResults" class="poll-page__content-overlay" @click="onClosePoll()"></div>

        <!-- Пройти опрос -->
        <poll-form v-if="showPollForm" @pass="onPassPoll" />

        <!-- Результаты опроса -->
        <poll-results v-if="showPollResults" />
      </section>
    </div>
  </div>
</template>

<script>
//vuex
import { mapActions, mapGetters } from 'vuex';
// components
import Toggler from '@/components/ui/Toggler';
import Alert from '@/components/ui/Alert';
import PageHeader from '@/components/pages/common/PageHeader';
import PollForm from '@/components/pages/polls/PollForm';
import PollResults from '@/components/pages/polls/PollResults';
import PollsList from '@/components/pages/polls/PollsList';

export default {
  name: 'Polls',

  components: {
    PollForm,
    PollResults,
    Toggler,
    Alert,
    PageHeader,
    PollsList,
  },

  data() {
    return {
      loading: true,
      showPassedPolls: false,
      showPollForm: false,
      showPollResults: false,
    };
  },

  computed: {
    ...mapGetters({
      getPolls: 'polls/getPolls',
    }),

    polls() {
      if (this.showPassedPolls) {
        return this.getPolls;
      } else {
        return this.getPolls.filter((poll) => !poll.Passed && !poll.PartiallyPassed);
      }
    },
  },

  watch: {
    '$route.params'(val, old) {
      if (val.id && val.id !== old.id) {
        this.detailsToggle();
      }

      this.setPollsListMaxHeight();
    },
    loading(val) {
      if (!val) {
        this.setPollsListMaxHeight();
      }
    },
  },

  created() {
    this.initPolls();
  },

  methods: {
    ...mapActions({
      getEventBlockData: 'news/getEventBlockData',
    }),

    initPolls() {
      this.loading = true;

      this.getEventBlockData().then(() => {
        this.loading = false;

        if (this.$route.params.id) {
          this.detailsToggle();
        }
      });
    },

    detailsToggle() {
      // console.log('--- detailsToggle method ---')
      if (this.$route.params.subpage === 'results') {
        this.showPollResults = true;
        this.showPollForm = false;

        return;
      }

      if (this.$route.params.subpage === 'form') {
        this.showPollResults = false;
        this.showPollForm = true;

        return;
      }

      this.showPollResults = false;
      this.showPollForm = false;
    },

    onClosePoll() {
      this.showPollForm = false;
      this.showPollResults = false;

      this.$router.push({
        name: 'Polls',
      });
    },

    setPollsListMaxHeight() {
      setTimeout(() => {
        this.$nextTick(() => {
          if (!this.isCustomView(768)) {
            const $pollsPage = this.$refs.$root;
            const $pollsList = this.$refs.pollsList;
            const height = this.$refs.pollContent.clientHeight;
            // const height2 = this.$refs.pollContent.scrollHeight;

            $pollsList.style.maxHeight = `${height}px`;
            $pollsPage.style.maxHeight = `inherit`;
          }
        });
      }, 1000);
    },

    onPassPoll() {
      this.loading = true;

      this.getEventBlockData().then(() => {
        this.loading = false;

        this.detailsToggle();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../sass/variables';
@import '../sass/mixins';

.polls-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 70px);
}
.polls-page__title {
}
.polls-page__filter {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.polls-page__filter-label {
  @include TextMain();
  width: auto;
  margin-right: 16px;
}
.polls-page__wrapper {
  flex-grow: 1;

  display: flex;

  overflow-y: hidden;
}
.polls-page__list {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  margin-right: 0;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    max-width: 260px;
    margin-right: 12px;
  }
  @media screen and (min-width: map-get($bp, 'tablet-big')) {
    max-width: 350px;
    margin-right: 16px;
  }
}
.polls-page__content {
  flex-grow: 1;

  width: 100%;
  max-height: 70%;
  overflow-y: scroll;
  border-radius: 16px 16px 0 0;

  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 0;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    position: initial;
    max-height: initial;
    // overflow-y: auto;
    overflow-y: initial;
  }
}
.poll-page__content-overlay {
  display: block;
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;

  background: rgba(0, 0, 0, 0.6);

  @media screen and (min-width: map-get($bp, 'tablet')) {
    display: none !important;
  }
}
</style>
