<template>
  <modal
    :title="$t('payment')"
    class="modal--ident pay-modal"
    @close="$emit('close')"
  >
    <div class="form-box">
      <form
        v-loading="isSending"
        class="form-box__form pay-modal__form"
        @submit.prevent
      >
        <!-- ЛС -->
        <form-box-select
          v-if="form.accountID"
          v-model="form.accountID"
          :label="$t('agreement')"
          :list="accountInfo"
          valuePropName="AccountID"
          keyPropName="AccountID"
          @input="setForm"
        >
          <template #selectedOption="{ item }"> ({{ $t('short_personal_account') }}: {{ item.Ident }}) {{ item.Address }}
          </template>
          <template #option="{ item }">
            {{ item.Ident }} ({{ item.AccountType }})<br />
            {{ item.Address }}
          </template>
        </form-box-select>

        <!-- Сумма к оплате (Sum) -->
        <form-box-input
          v-model="sum"
          type="amount"
          :label="`${$t('payment_amount')} (₽)`"
          :readonly="!allowEditSum"
          :message="!validate ? $t('invalid_amount') : ''"
        />

        <!-- Пени (SumFine) -->
        <form-box-input
          v-if="config.useSeparatedFinePayment"
          v-model="sumFine"
          type="amount"
          :label="`${$t('penalty_incl')} (₽)`"
          :readonly="!allowEditSum"
          :min="0"
        />

        <!-- Страхование -->
        <template v-if="insuranceSum && (!form.paymentSystem || form.paymentSystem == 'Tinkoff')">
          <form-box-checkbox
            v-model="form.payInsurance"
            :label="`${$t('insurance')} ${insuranceSum} ₽`"
            refName="insuranceControl"
            inputId="insurance"
            :classModificators="['pay-modal']"
          />

          <div class="pay-modal__insurance">
            <a
              class="pay-modal__insurance-link pay-modal__insurance-link--rules"
              target="_blank"
              href="https://sm-center.ru/vsk_polis.pdf"
            >
              {{ $t('insurance_conditions') }}
            </a>
            <a
              class="pay-modal__insurance-link pay-modal__insurance-link--disable"
              @click.prevent="disableInsurance()"
              href="javascript:;"
            >
              {{ $t('dont_offer_insurance') }}
            </a>
          </div>
        </template>

        <!-- Save card  -->
        <form-box-checkbox
          v-if="form.paymentSystem == 'Tinkoff' ||
            (paymentSystems && paymentSystems.length === 1 && paymentSystems[0].name == 'Tinkoff')
            "
          v-model="form.allowCardSaving"
          :label="$t('save_card')"
          refName="saveCardControl"
          inputId="save-card"
          :classModificators="['pay-modal']"
        />

        <alert
          v-if="showSberPaymentSystemAlert"
          type="danger"
          critical
          class="pay-modal__alert"
        >
          {{ $t('when_paying_through_sber') }}
        </alert>

        <!-- Payment system -->
        <template v-if="paymentSystems.length > 1">
          <form-box-radioset
            v-model="form.paymentSystem"
            :label="$t('payment_system')"
            :list="paymentSystemsRadiosetList"
            :message="$t('select_payment_system')"
            class="pay-modal__payment-system"
            :classModificators="['pay-modal']"
          >
            <template #label="{ item }">
              <div class="payment-system">
                <img
                  class="payment-system__icon"
                  :src="getPaymentSystemImage(item.value)"
                />
                {{ item.title }}
              </div>
            </template>
          </form-box-radioset>
        </template>

        <!-- Totals -->
        <div class="pay-modal__totals">
          <p
            v-loading="commissionIsLoading"
            class="pay-modal__total pay-modal__total--sum"
          >
            {{ $t('total') }}:
            <strong>{{ totalSum }} ₽</strong>
          </p>
          <template v-if="!hideComissionInfo">
            <p
              v-if="commission > 0"
              class="pay-modal__total pay-modal__total--commission"
            >
              *{{ $t('commission') }}: {{ commission }} ₽
            </p>
            <p
              v-else
              class="pay-modal__total pay-modal__total--commission"
            >*{{ $t('no_commission_charged') }}</p>
          </template>
        </div>

        <base-button
          :disabled="!validate"
          class="pay-modal__button"
          @click="onPay"
        >{{ $t('pay') }}</base-button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import modal from '@/components/ui/Modal';
import * as commonApi from '@/api/common';
import FormBoxInput from '@/components/form/FormBoxInput';
import FormBoxSelect from '@/components/form/FormBoxSelect';
import FormBoxCheckbox from '@/components/form/FormBoxCheckbox';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';
import BaseButton from '@/components/ui/BaseButton';
import Alert from '@/components/ui/Alert';

export default {
  name: 'payModal',

  components: {
    modal,
    FormBoxInput,
    FormBoxSelect,
    FormBoxCheckbox,
    FormBoxRadioset,
    BaseButton,
    Alert,
  },

  props: {
    id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      form: {
        accountID: null,
        sum: 0,
        sumFine: 0,
        allowCardSaving: false,
        paymentSystem: null,
        payInsurance: false,
        payAll: false,
      },
      sum: 0,
      sumFine: 0,

      insuranceSum: 0,
      totalSum: 0,
      commission: 0,
      hideComissionInfo: true,
      commissionIsLoading: false,
      //accountInfoIDs: [],
      accountInfo: [],
      paymentSystems: [],
      isSending: false,
      isPaymentSystemSelectionMode: false,
      allowEditSum: true,
      sumControlIsFocus: false,
      showSberPaymentSystemAlert: false
    };
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo',
    }),

    /**
     * uniqueAccounts - Уникальные лицевые счета. Удалены дубликаты.
     * @returns {array} Список счетов.
     */
    uniqueAccounts() {
      return this.accountInfo.filter((x, i, self) => {
        return self.findIndex((t) => t.Ident == x.Ident) === i;
      });
    },

    paymentSystemsRadiosetList() {
      return this.paymentSystems.map((item) => {
        return {
          title: item.displayName,
          value: item.name,
        };
      });
    },

    validate() {
      return this.form.sum && +this.form.sum > 0;
    },
  },

  mounted() {
    this.form.accountID = this.id;
    this.getAccountInfo();
    this.setPaymentSystems();
  },

  watch: {
    sum(val) {
      // console.log('--- sum watcher ---')
      if (this.config.useSeparatedFinePayment) {
        this.form.sum = parseFloat((+val + +this.sumFine).toFixed(2));
      } else {
        // console.log('val:', val)
        this.form.sum = val;
        // console.log('this.form.sum', this.form.sum)
      }
      this.getCommission();
    },
    sumFine(val) {
      if (this.config.useSeparatedFinePayment) {
        // this.form.sum = parseFloat((+this.sum + +val)).toFixed(2);
        this.form.sum = +this.sum + +val;
        this.getCommission();
      }
    },
    'form.payInsurance'() {
      this.getCommission();
    },

    'form.paymentSystem': {
      handler(value) {
        if (this.isGranel && value === 'Sber') {
          this.showSberPaymentSystemAlert = true;
          const newPaymentSystem = this.paymentSystemsRadiosetList.find(system => system.value !== value);
          this.$set(this.form, 'paymentSystem', newPaymentSystem.value);
        }
      }
    }

  },

  methods: {
    ...mapActions({
      getSumWithCommission: 'account/getSumWithCommission',
      disableAccountInsurance: 'account/disableAccountInsurance',
      getPaymentSystems: 'account/getPaymentSystems',
      getPayLink: 'account/getPayLink',
    }),

    getAccountInfo() {
      this.accountInfo = this.getAllInfo;
      this.setForm();
    },

    disableInsurance() {
      // console.log('--- disableInsurance ---')
      this.isSending = true;

      this.disableAccountInsurance(this.id)
        .then((result) => {
          this.isSending = false;
          if (result.error) {
            this.$notify.error({
              position: 'top-left',
              title: this.$t('error'),
              message: result.error,
            });
          } else {
            this.getAccountInfo();
          }
        })
        .catch(() => (this.isSending = false));
    },

    setPaymentSystems() {
      this.isSending = true;

      this.getPaymentSystems(this.form.accountID)
        .then((result) => {
          this.isSending = false;
          this.paymentSystems = result;
          if (this.paymentSystems && this.paymentSystems.length > 1) {
            this.form.paymentSystem = this.paymentSystems[0].name;
          } else {
            this.form.paymentSystem = 'Tinkoff';
          }
        })
        .catch(() => (this.isSending = false));
    },

    setForm() {
      const accountInfo = this.accountInfo.find((item) => item.AccountID === +this.form.accountID);

      if (accountInfo) {
        this.form.sum = accountInfo.Sum;
        // this.sum = accountInfo.Sum;
        this.form.paymentSystem = this.form ? this.form.paymentSystem : null;
        this.form.allowCardSaving = false;
        // this.form.payInsurance = !!(accountInfo.InsuranceSum);
        this.insuranceSum = accountInfo.InsuranceSum;
        // this.form.sumFine = accountInfo.SumFine;
        this.sumFine = accountInfo.SumFine;
      } else {
        this.allowEditSum = false;

        this.form.sum = this.accountInfo.reduce((accumulator, current) => {
          if (current.Sum > 0) {
            return parseFloat((accumulator + current.Sum).toFixed(2));
          } else {
            return accumulator;
          }
        }, 0);
        // this.sum = this.accountInfo
        //   .reduce((accumulator, current) => {
        //     if (current.Sum > 0) {
        //       return parseFloat((accumulator + current.Sum).toFixed(2));
        //     } else {
        //       return accumulator;
        //     }
        //   }, 0);
        this.form.accountID = null;
        this.form.paymentSystem = this.form ? this.form.paymentSystem : null;
        this.form.allowCardSaving = false;
        this.form.payAll = true;
        // this.form.sumFine = this.accountInfo
        //   .reduce((accumulator, current) => {
        //     if (current.SumFine > 0) {
        //       return parseFloat((accumulator + current.SumFine).toFixed(2));
        //     } else {
        //       return accumulator;
        //     }
        //   }, 0);
        this.sumFine = this.accountInfo.reduce((accumulator, current) => {
          if (current.SumFine > 0) {
            return parseFloat((accumulator + current.SumFine).toFixed(2));
          } else {
            return accumulator;
          }
        }, 0);
      }

      if (this.config.useSeparatedFinePayment && +this.sumFine !== 0) {
        this.sum = parseFloat((this.form.sum - this.sumFine).toFixed(2));
        this.form.sumFine = this.sumFine;
      } else {
        this.sum = this.form.sum;
      }

      // this.getCommission();
    },

    // Оплатить
    onPay() {
      if (parseInt(this.form.sum) > 0) {
        this.isSending = true;

        // console.log('this.form.sumFine:', this.form)
        this.getPayLink({
          ...this.form,
          sum: this.sum,
          sumFine: this.sumFine,
        })
          .then((result) => {
            this.isPaymentSystemSelectionMode = false;
            const isResultPayLink = result.payLink && result.payLink.length > 0;
            if (!isResultPayLink) return;
            if (!this.authUser.email) {
              this.$root.$emit("show-email-for-pay-modal", result.payLink);
              this.$emit('close');
            } else {
              location.href = result.payLink;
            }

            this.isSending = false;
          })
          .catch(() => {
            this.isSending = false;
            this.isPaymentSystemSelectionMode = false;
          });
      }
    },

    getPaymentSystemImage(name) {
      return commonApi.getPaymentSystemImageDownloadLink(name);
    },

    getCommission() {
      if (!this.form.sum) {
        this.commission = 0;
        this.totalSum = 0;
        return;
      }

      this.commissionIsLoading = true;

      let sum = parseFloat(this.form.sum);
      if (this.form.payInsurance) {
        sum += this.insuranceSum;
      }
      // if (this.config.useSeparatedFinePayment) {
      //   sum += this.form.sumFine;
      // }

      this.getSumWithCommission({
        sum: sum,
        accountID: this.form.accountID,
      })
        .then((result) => {
          this.commission = result.Commission;
          this.totalSum = result.TotalSum;
          this.hideComissionInfo = result.HideComissionInfo;

          this.commissionIsLoading = false;
        })
        .catch(() => (this.commissionIsLoading = false));
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/form'

.pay-modal__alert
  margin-bottom: 12px

.pay-modal
  .payment-system
    display: flex
    align-items: center

.pay-modal__form
  margin-bottom: 0

.pay-modal__insurance
  display: flex
  justify-content: space-between
  margin-bottom: 12px

.pay-modal__insurance-link
  +TextButtonMini()

.pay-modal__totals
  margin-bottom: 24px

.pay-modal__total
  +TextMain()

  &--commission
    +TextMini()

  &:not(:last-child)
    margin-bottom: 2px

  strong
    +TextHeader(2)
    display: inline-block
    margin-left: 8px

.pay-modal__button
  margin-bottom: 0
</style>
