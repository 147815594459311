<template>
  <main class="main-content">
    <div class="meeting-owners_section">
      <div class="container">
        <div class="main-title">
          {{ $t('notices_about_holding') }}
          <small>{{ $t('general_meeting_of_owners') }}</small>
        </div>

        <template>
          <div class="tab-container">
            <ul class="tab-names">
              <li :class="['tab', showActive ? 'active' : '']" @click="switchListMode(true)">{{ $t('active') }}</li>
              <li :class="['tab', !showActive ? 'active' : '']" @click="switchListMode(false)">{{ $t('completed') }}</li>
            </ul>
            <div class="content-tabs">
              <div class="tab-item" style>
                <div class="conduct-messages_header">
                  <span class="cell">{{ $t('name') }}</span>
                  <span class="cell">{{ $t('initiator_of_meeting') }}:</span>
                  <span class="cell">{{ $t('house_address') }}:</span>
                  <span class="cell">{{ $t('meeting_date') }}:</span>
                  <span class="cell">{{ $t('form_of_meeting') }}:</span>
                  <span class="cell">{{ $t('meeting_status') }}:</span>
                  <span class="cell"></span>
                </div>
                <div class="meeting-item" v-for="oss in displayedOss" :key="`oss-${oss.ID}`">
                  <div class="column">
                    <span :class="'meetings-item_title ' + oss_statusParameters(oss).listStatusClass">{{
                      oss.MeetingTitle
                    }}</span>
                  </div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('initiator_of_meeting') }}:</span>
                    {{ oss.InitiatorNames }}
                  </div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('house_address') }}:</span>
                    {{ oss.HouseAddress }}
                  </div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_date') }}:</span>
                    {{ $t('from') }} {{ dateNoTime(oss.DateStart) }} {{ $t('to') }} {{ dateNoTime(oss.DateEnd) }} {{ $t('inclusive') }}.
                  </div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('form_of_meeting') }}:</span>
                    {{ oss.Form }}
                  </div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_status') }}:</span>
                    <span :class="'meeting-item_status ' + oss_statusParameters(oss).listStatusClass">
                      <span :class="oss_statusParameters(oss).glipf"></span>
                      {{ oss_statusParameters(oss).statusText }}
                    </span>
                  </div>
                  <div class="column">
                    <span class="red-arrow"></span>
                  </div>
                  <router-link
                    class="meeting-item_link"
                    :to="{ name: 'OssInfo', params: { id: oss.ID } }"
                  ></router-link>
                </div>
              </div>
              <div class="tab-item" style="display: none">
                <div class="conduct-messages_header">
                  <span class="cell">{{ $t('name') }}</span>
                  <span class="cell">{{ $t('initiator_of_meeting') }}:</span>
                  <span class="cell">{{ $t('house_address') }}:</span>
                  <span class="cell">{{ $t('meeting_date') }}:</span>
                  <span class="cell">{{ $t('form_of_meeting') }}:</span>
                  <span class="cell">{{ $t('meeting_status') }}:</span>
                  <span class="cell"></span>
                </div>
                <div class="meeting-item">
                  <div class="column">
                    <span class="meetings-item_title complete">{{ $t('owners_meeting') }} №&nbsp;3</span>
                  </div>
                  <div class="column"><span class="hidden-text">{{ $t('initiator_of_meeting') }}:</span>Иванов Иван Иванович</div>
                  <div class="column"><span class="hidden-text">{{ $t('house_address') }}:</span>г. Бийск, Яминская, 36-в</div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_date') }}:</span>{{ $t('from') }} 03.01.2020 {{ $t('to') }} 5.01.2020 {{ $t('inclusive') }}.
                  </div>
                  <div class="column"><span class="hidden-text">{{ $t('form_of_meeting') }}:</span>Заочная (Онлайн)</div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_status') }}:</span>
                    <span class="meeting-item_status complete">
                      <span class="glipf-start-and-finish"></span>{{ $t('meeting_completed') }}
                    </span>
                  </div>
                  <div class="column">
                    <span class="red-arrow"></span>
                  </div>
                  <a href class="meeting-item_link"></a>
                </div>
                <div class="meeting-item">
                  <div class="column">
                    <span class="meetings-item_title complete">{{ $t('owners_meeting') }} №&nbsp;3</span>
                  </div>
                  <div class="column"><span class="hidden-text">{{ $t('initiator_of_meeting') }}:</span>Иванов Иван Иванович</div>
                  <div class="column"><span class="hidden-text">{{ $t('house_address') }}:</span>г. Бийск, Яминская, 36-в</div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_date') }}:</span>{{ $t('from') }} 03.01.2020 {{ $t('to') }} 5.01.2020 {{ $t('inclusive') }}.
                  </div>
                  <div class="column"><span class="hidden-text">{{ $t('form_of_meeting') }}:</span>Заочная (Онлайн)</div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_status') }}:</span>
                    <span class="meeting-item_status complete">
                      <span class="glipf-start-and-finish"></span>{{ $t('meeting_completed') }}
                    </span>
                  </div>
                  <div class="column">
                    <span class="red-arrow"></span>
                  </div>
                  <a href class="meeting-item_link"></a>
                </div>
                <div class="meeting-item">
                  <div class="column">
                    <span class="meetings-item_title complete">{{ $t('owners_meeting') }} №&nbsp;3</span>
                  </div>
                  <div class="column"><span class="hidden-text">{{ $t('initiator_of_meeting') }}:</span>Иванов Иван Иванович</div>
                  <div class="column"><span class="hidden-text">{{ $t('house_address') }}:</span>г. Бийск, Яминская, 36-в</div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_date') }}:</span>{{ $t('from') }} 03.01.2020 {{ $t('to') }} 5.01.2020 {{ $t('inclusive') }}.
                  </div>
                  <div class="column"><span class="hidden-text">{{ $t('form_of_meeting') }}:</span>Заочная (Онлайн)</div>
                  <div class="column">
                    <span class="hidden-text">{{ $t('meeting_status') }}:</span>
                    <span class="meeting-item_status complete">
                      <span class="glipf-start-and-finish"></span>{{ $t('meeting_completed') }}
                    </span>
                  </div>
                  <div class="column">
                    <span class="red-arrow"></span>
                  </div>
                  <a href class="meeting-item_link"></a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Oss',
  data() {
    return {
      hasAccounts: true,
      ossIDs: [],
      isLoading: false,
      showActive: true,
    };
  },
  mounted() {
    this.getAccountInfo();
    this.getOSS();
  },
  created() {
    this.$root.$on('updated-account-info', this.onAccountInfoChanged);
    this.$root.$on('deleted-account-info', this.onAccountInfoChanged);
  },
  destroyed() {
    this.$root.$off('updated-account-info', this.onAccountInfoChanged);
    this.$root.$off('deleted-account-info', this.onAccountInfoChanged);
  },
  methods: {
    switchListMode(isActive) {
      this.showActive = isActive;
    },
    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getOSS();
    },
    getAccountInfo() {
      this.$store.dispatch('account/getUserInfo').then((result) => {
        this.hasAccounts = result.Accounts && result.Accounts.length;
      });
    },
    getOSS() {
      this.isLoading = true;
      this.$store
        .dispatch('oss/getOSS')
        .then((result) => {
          this.ossIDs = result.keys;
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    oss_statusParameters(oss) {
      var result = this.$store.getters['oss/getStateParameters'](oss);
      return result;
    },
  },
  computed: {
    displayedOss() {
      return this.$store.getters['oss/getOssByCompletion'](this.ossIDs, !this.showActive);
    },
  },
};
</script>

<style scoped></style>
