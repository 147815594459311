var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-page"},[_c('page-header',{attrs:{"back":{
      title: _vm.$t('service'),
      to: { name: 'Services' },
    }}}),_c('section',{staticClass:"shop-page__header"},[_c('div',{staticClass:"shop-page__header-wrap"},[_c('h1',{staticClass:"shop-page__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('base-button',{attrs:{"style-class":"text"},on:{"click":function($event){_vm.sortDirection = _vm.sortsSwitch[_vm.sortDirection].name}}},[_vm._v(" "+_vm._s(_vm.$t('by_price'))+" "),_c(_vm.sortingIcon,{tag:"component",staticClass:"shop-page__sort-icon"})],1)],1)]),_c('div',{staticClass:"shop-page__container"},[_c('section',{staticClass:"shop-page__column shop-page__column--left"},[_c('div',{staticClass:"shop-page__goods-list"},_vm._l((_vm.goods),function(good){return _c('shop-item',{key:`good-${good.ID}`,attrs:{"good":good}})}),1)]),_c('section',{staticClass:"shop-page__column shop-page__column--right",attrs:{"id":"cart"}},[_c('div',{staticClass:"amount-card"},[_c('ul',{staticClass:"prices"},[(_vm.isGoodsType)?_c('li',{staticClass:"prices__field"},[_c('label',{staticClass:"prices__title"},[_vm._v(_vm._s(_vm.$t('weight'))+":")]),_c('span',{staticClass:"prices__value"},[_vm._v(" "+_vm._s(_vm.cartWeight)+" "+_vm._s(_vm.$t('kg'))+" ")])]):_vm._e(),_c('li',{staticClass:"prices__field"},[_c('label',{staticClass:"prices__title"},[_vm._v(_vm._s(_vm.$t('total_payable'))+":")]),_c('span',{staticClass:"prices__value"},[_vm._v(" "+_vm._s(_vm.cartCost)+" ₽ ")])])]),_c('base-button',{staticClass:"amount-card__button",attrs:{"disabled":_vm.cartIsEmpty},on:{"click":function($event){return _vm.$router.push({
              name: 'UserCart',
              params: _vm.$route.params,
            })}}},[_vm._v(" "+_vm._s(_vm.$t('place_order'))+" ")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }