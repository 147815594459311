<template>
  <article :class="['card', modificator ? `card--${modificator}` : false]">
    <div class="card__header">
      <slot name="header">
        <component
          :is="cardLikeLink ? 'router-link' : 'div'"
          :to="detailLink && cardLikeLink ? detailLink : false"
          class="card__title"
        >
          <slot name="title">
            <h3
              v-if="title"
              class="card__name"
            >
              {{ title }}
            </h3>
            <p
              v-if="subtitle"
              class="card__subtitle"
            >
              {{ subtitle }}
            </p>
          </slot>
        </component>
        <slot name="headerRight">
          <trash-icon
            v-if="deleteButton"
            class="card__delete-icon"
            :title="`${deleteButton}`"
            @click="$emit('card:remove')"
          />
        </slot>
      </slot>
    </div>
    <div :class="['card__body', { 'card__body--has-offset': hasFooterContent }]">
      <slot name="body">
        <component
          :is="cardLikeLink ? 'router-link' : 'div'"
          :to="detailLink && cardLikeLink ? detailLink : false"
          class="card__body-wrapper"
        >
          <div
            class="card__text"
            v-html="text"
          ></div>

          <div
            v-if="preview"
            class="card__preview"
          >
            <img
              :src="preview"
              :alt="title"
            />
          </div>
        </component>
      </slot>
    </div>
    <div class="card__footer">
      <slot name="footer">
        <slot name="footerLeft">
          <p
            v-if="footerCaption"
            class="card__caption"
          >
            {{ footerCaption }}
          </p>
        </slot>
        <slot name="footerRight">
          <button
            v-if="footerButton"
            type="button"
            class="card__button"
            @click="$emit('card:onclickfooterbutton')"
          >
            {{ footerButton }}
          </button>

          <router-link
            v-if="detailLink && !cardLikeLink"
            :to="detailLink"
            class="card__detail-link"
          >
            {{ $t('more_details') }}
            <arrow-right-icon class="card__detail-icon" />
          </router-link>
        </slot>
      </slot>
    </div>
  </article>
</template>

<script>
import { TrashIcon, ArrowRightIcon } from '@vue-hero-icons/outline';

export default {
  name: 'card',

  components: {
    TrashIcon,
    ArrowRightIcon,
  },

  props: {
    modificator: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    deleteButton: {
      type: String,
      required: false,
      default: '',
    },
    badges: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    table: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    footerCaption: {
      type: String,
      required: false,
      default: '',
    },
    footerButton: {
      type: String,
      required: false,
      default: '',
    },
    detailLink: {
      type: [String, Object],
      required: false,
      default: '',
    },
    cardLikeLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    preview: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },

  computed: {
    hasFooterContent() {
      return this.$slots.footer || this.$slots.footerLeft || this.$slots.footerRight;
    },
  },
};
</script>

<style
  lang="sass"
  scoped
>
@import '../../sass/variables'
@import '../../sass/mixins'

.card
  +Size(100%, auto)
  display: flex
  flex-direction: column
  justify-content: space-between
  background: $cBgMain
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
  border-radius: 16px
  padding: 16px

  @media screen and (min-width: 768px)
    padding: 24px

.card__header
  +Size(100%, auto)
  display: flex
  align-items: flex-start
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    margin-bottom: 24px

.card__title
  flex-grow: 1

.card__name
  +TextHeader(2)

.card__subtitle
  +TextMini($cTextMain)
  margin-top: 8px
  margin-bottom: 0

.card__address
  +TextMini()
  display: inline-block
  width: 100%
  max-width: 210px
  margin-bottom: 0

.card__delete-icon
  flex-shrink: 0
  color: $cIconNeutral
  +Transition((color))

  &:hover
    color: $cAccent
    cursor: pointer

.card__body
  +Size(100%)
  flex-grow: 1

  &--has-offset
    margin-bottom: 16px

    @media screen and (min-width: 768px)
      margin-bottom: 24px

.card__text
  +TextMain($cTextMain)

  p, ul
    margin-bottom: 0

.card__preview
  +ResponsiveImg(100%, 154px)
  border-radius: 16px
  margin-top: 24px

.card__footer
  +Size(100%, auto)
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap

  @media screen and (min-width: 768px)
    flex-wrap: nowrap

  @media screen and (min-width: 1200px)
    flex-wrap: wrap

.card__caption
  +TextMini()
  width: 100%
  margin-bottom: 16px

  @media screen and (min-width: 576px)
    width: auto
    margin-bottom: 0

.card__button-icon
  flex-shrink: 0

.card__detail-link
  +TextButtonMini()
  width: auto
  display: inline-flex
  align-items: center
  margin-left: auto

.card__detail-icon
  +Size(16px)
  flex-shrink: 0
  margin-left: 4px

.card__attention
  +TextMini()
  margin-bottom: 0
</style>
