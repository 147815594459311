import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '@/localization';

Vue.use(VueI18n);

export default new VueI18n({
  locale: localStorage.getItem('language') || 'ru',
  fallbackLocale: 'ru',
  messages: messages,
})