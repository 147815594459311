<template>
  <main class="authorization">
    <!-- Form pane -->
    <div class="authorization__form-pane" ref="form-pane">
      <div>
        <template v-if="config.logoLink">
          <a :href="config.logoLink()" class="form-pane__logo">
            <img :src="config.appIconFile" :alt="config.main_name" />
          </a>
        </template>
        <template v-else>
          <div class="form-pane__logo">
            <img :src="config.appIconFile" :alt="config.main_name" />
          </div>
        </template>

        <!-- Form -->
        <slot />
      </div>

      <div class="form-pane__footer">
        <a :href="`tel:${config.clearPhone}`" class="form-pane__contact">
          <phone-icon class="form-pane__phone-icon" preserveAspectRatio="xMidYMid meet" />
          {{ config.phone || '' }}
        </a>
        <p class="form-pane__help-note">
          Возникли проблемы с личным кабинетом?
          <br>
          <a 
            @click.prevent="openSupportModalChatWithousAuth"
          >
            Напишите нам
          </a>
        </p>

        <div class="authorization__app-links authorization__app-links--form-pane">
          <a v-if="config.appLinkAndroid" :href="appLinks.googlePlay.url" class="authorization__app-link">
            <img :src="appLinks.googlePlay.src" :alt="appLinks.googlePlay.alt" />
          </a>
          <a v-if="config.appLinkIOS" :href="appLinks.appStore.url" class="authorization__app-link">
            <img :src="appLinks.appStore.src" :alt="appLinks.appStore.alt" />
          </a>
        </div>

        <p class="authorization__powered-by authorization__powered-by--form-pane">
          {{ $t('powered_by_technology') }}
          <a target="_blank" href="https://sm-center.ru">sm-center.ru</a>. {{ $t('management_of_apartment_buildings') }}
        </p>
      </div>
    </div>

    <!-- Intro pane -->
    <div class="authorization__intro-pane" ref="intro-pane">
      <div class="intro-pane">
        <h1 class="intro-pane__title">
          {{ $t('welcome') }}
          <br />
          {{ $t('to_account') }} {{ this.config.main_name }}!
        </h1>

        <!-- Carousel -->
        <div class="intro-pane__carousel">
          <!-- <auth-carousel /> -->
          <auth-carousel />
        </div>

        <div class="intro-pane__footer">
          <div class="authorization__app-links">
            <a v-if="config.appLinkAndroid" :href="appLinks.googlePlay.url" class="authorization__app-link">
              <img :src="appLinks.googlePlay.src" :alt="appLinks.googlePlay.alt" />
            </a>
            <a v-if="config.appLinkIOS" :href="appLinks.appStore.url" class="authorization__app-link">
              <img :src="appLinks.appStore.src" :alt="appLinks.appStore.alt" />
            </a>
          </div>

          <p class="authorization__powered-by">
            {{ $t('powered_by_technology') }}
            <a target="_blank" href="https://sm-center.ru">sm-center.ru</a>. {{ $t('management_of_apartment_buildings') }}
          </p>
        </div>
      </div>
      <!-- / .intro-pane -->
    </div>

    <app-notification />
  </main>
</template>

<script>
import { PhoneIcon } from '@vue-hero-icons/outline';
import AuthCarousel from '@/components/pages/authorization/AuthCarousel';
import AppNotification from '@/components/AppNotification';

export default {
  name: 'LoginRegisterLayout',

  components: {
    PhoneIcon,
    AuthCarousel,
    AppNotification,
  },

  computed: {
    appLinks() {
      return {
        googlePlay: {
          url: this.config.appLinkAndroid,
          src: this.resolveUrl('/img/static/application/google-play-gray.svg'),
          alt: 'Google Play',
        },
        appStore: {
          url: this.config.appLinkIOS,
          src: this.resolveUrl('/img/static/application/app-store-gray.svg'),
          alt: 'App Store',
        },
      };
    },
  },

  mounted() {
    // this.setLayoutHeight();
    // this.resizeThrottlerFunctionList.push(
    //   this.setLayoutHeight
    // );
  },

  methods: {
    setLayoutHeight() {
      const $formPane = this.$refs['form-pane'];
      const $introPane = this.$refs['intro-pane'];

      const formPaneHeight = $formPane.offsetHeight;

      $introPane.style.height = `${formPaneHeight}px`;

      this.$root.$emit('auth-layout:updated');
    },
  },
};
</script>

<style lang="sass">
@import "../sass/variables"
@import "../sass/mixins"

.authorization__form-pane
  +Size(100%)
  // width: 100%
  min-height: 100vh
  max-width: 100%
  flex-shrink: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-between
  padding: 33px 24px 28px
  background-color: $cBgSec

  @media screen and (min-width: 992px)
    max-width: 332px

.authorization__intro-pane
  flex-shrink: 1
  display: none
  width: calc(100% - 332px)
  min-height: 100vh
  position: relative

  @media screen and (min-width: 992px)
    display: flex

.authorization__app-links
  display: inline-flex
  margin-bottom: 24px

  @media screen and (min-width: 992px)
    margin-right: 40px
    margin-bottom: 0

    &--form-pane
      display: none

.authorization__app-link
  +ResponsiveImg(126px, 31px)
  display: inline-flex

  &:first-child
    margin-right: 44px

.authorization__powered-by
  +TextMain()
  margin-bottom: 0

  @media screen and (min-width: 992px)
    margin-bottom: 0

    &--form-pane
      display: none

.authorization
  // FORM PANE
  .form-pane__logo
    +ResponsiveImg(auto, 80px)
    display: block
    margin-bottom: 43px

  .form-pane__footer
    display: flex
    flex-direction: column
    align-items: center

  .form-pane__contact
    +TextMain($cTextMain)
    display: flex
    align-items: center
    margin-bottom: 24px
    +Transition((color))

    &:hover
      color: $cAccent

      .form-pane__phone-icon
        color: $cAccent
        transition: color .15s

  .form-pane__phone-icon
    color: $cIconNeutral
    margin-right: 10px
    transition: color .3s

  .form-pane__help-note
    +TextMini()
    text-align: center
    margin: 0 0 24px

    a
      +Transition((color))
      color: $cAccentBlue
      cursor: pointer

      &:hover
        color: $cAccent

    @media screen and (min-width: 992px)
      margin: 0

  // INTRO PANE
  .intro-pane
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    position: relative

  .intro-pane__title
    +Typography (35px, 700, 140%, $cAccentBlue)
    flex-shrink: 0
    padding: 40px 40px 0
    margin-bottom: 16px

    @media screen and (min-width: 991px)
      margin-bottom: 24px

    @media screen and (min-width: 1400px)
      padding: 81px 95px 0

  .intro-pane__carousel
    +Size(100%)
    flex-grow: 1
    display: flex
    align-items: flex-end
    overflow: hidden

  .intro-pane__footer
    display: flex
    align-items: center
    flex-shrink: 0
    flex-basis: auto
    padding: 0 40px 40px

    position: absolute
    left: 0
    bottom: 0

    @media screen and (min-width: 992px)
      padding: 0 95px 40px
</style>
