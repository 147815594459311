export default {
  ru: {
    payment: 'Оплата',
    make_payment: 'провести оплату',
    payment_history: 'История платежей',
    payment_successful: 'Оплата прошла успешно',
    payment_failed: 'Оплата не прошла',
    payment_method: 'Способ оплаты',
    pay: 'Оплатить',
    to_pay: 'К оплате',
    pay_all: 'Оплатить все',
    after_successful_payment: 'После успешной оплаты Вам придет код подтверждения в смс',
    when_paying_through_sber: 'При оплате через Сбер возможны ошибки при обработке платежа, информация о задолженности обновляется в течение 3 рабочих дней',
    payment_amount: 'Сумма оплаты',
    invalid_amount: 'Неверная сумма',

    payment_system: 'Платежная система',
    select_payment_system: 'Выберите платежную систему',

    commission: 'Комиссия',
    no_commission_charged: 'Комиссия не взимается',
    commission_included_in_payment_amount: 'В сумму оплаты включена комиссия',

    credit_with_bonuses: 'Зачесть бонусами',
    point_s: 'балл(ов)',

    by_card: 'Картой',
    upon_receipt: 'При получении',
    agreement: 'Договор',
    penalty_incl: 'Пеня в т.ч.',
    accrued: 'Начислено',
    invoice: 'Счет',

    insurance: 'Страхование',
    insurance_conditions: 'Условия страхования',
    dont_offer_insurance: 'Не предлагать страхование',

    save_card: 'Cохранить карту',

    on_date: 'На',
    really_delete_address: 'Действительно удалить адрес',
    hcs: 'ЖКУ',
    date: 'Дата',
    sum: 'Сумма',
    in_processing: 'В обработке',

    bills: 'Квитанции',
    bill: 'Квитанция',
    downloading_bill: 'Скачивание квитанции',
    failed_to_download_bill: 'Не удалось скачать квитанцию. Повторите попытку позже.',

    check: 'Чек',
    downloading_check: 'Скачивание чека',
    failed_to_download_check: 'Не удалось скачать чек. Повторите попытку позже.',

    download_reconciliation_file: 'Скачать файл сверки',
    download_extract: 'Скачать выписку',
  },

  en: {
    payment: 'Payment',
    make_payment: 'make a payment',
    payment_history: 'Payment history',
    payment_successful: 'Payment was successful',
    payment_failed: 'Payment failed',
    payment_method: 'Payment method',
    pay: 'Pay',
    to_pay: 'To pay',
    pay_all: 'Pay all',
    after_successful_payment: 'After successful payment you will receive a confirmation code via sms',
    when_paying_through_sber: 'When paying through Sber, errors may occur during payment processing, information debt is updated within 3 business days',
    payment_amount: 'Payment amount',
    invalid_amount: 'Invalid amount',

    payment_system: 'Payment system',
    select_payment_system: 'Select payment system',

    commission: 'Commission',
    no_commission_charged: 'No commission charged',
    commission_included_in_payment_amount: 'Commission is included in the payment amount',

    credit_with_bonuses: 'Credit with bonuses',
    point_s: 'point(s)',

    by_card: 'By card',
    upon_receipt: 'Upon receipt',
    agreement: 'Agreement',
    penalty_incl: 'Penalty, incl.',
    accrued: 'Accrued',
    invoice: 'Invoice',

    insurance: 'Insurance',
    insurance_conditions: 'Insurance conditions',
    dont_offer_insurance: 'Don\'t offer insurance',

    save_card: 'Save card',

    on_date: 'On',
    really_delete_address: 'Really delete address',
    hcs: 'HCS',
    date: 'Date',
    sum: 'Sum',
    in_processing: 'In processing',

    bills: 'Bills',
    bill: 'Bill',
    downloading_bill: 'Downloading a bill',
    failed_to_download_bill: 'Failed to download bill. Please try again later.',

    check: 'Сheck',
    downloading_check: 'Downloading a check',
    failed_to_download_check: 'Failed to download check. Please try again later.',

    download_reconciliation_file: 'Download the reconciliation file',
    download_extract: 'Download extract',
  },
}