<template>
  <modal :title="$t('add_personal_account')" :setBaseStyle="true" class="modal--ident" @close="$emit('close')">
    <div class="form-box">
      <form v-loading="isSending" class="form-box__form" @submit.prevent>
        <template v-if="stage === 1">
          <form-box-input
           v-if="this.config.accountSelectMode === 'AddressAndLastName'"
            v-model="form.inn"
            refName="inn"
            :label="$t('inn')"
            autocomplete="off"
          />
          <form-box-input
           v-if="this.config.accountSelectMode === 'AddressAndLastName'"
            v-model="form.lastName"
            refName="lastName"
            :label="$t('last_name')"
            autocomplete="off"
          />
          <form-box-select
           v-if="this.config.accountSelectMode === 'AddressAndLastName'"
            v-model="form.houseId"
            :label="$t('address')"
            :list="houses"
            valuePropName="ID"
            keyPropName="Address"
            @input="getHouseDataById"
            :maxShowOptions="6"
            search
          />
          <form-box-select
           v-if="this.config.accountSelectMode === 'AddressAndLastName'"
            :disabled="!form.houseId"
            v-model="form.premiseNumber"
            :label="$t('premise_number')"
            :list="houseData"
            valuePropName="Number"
            keyPropName="Number"
            :maxShowOptions="6"
            search
          />
          <form-box-select
            v-if="config.AddAccountByIdentOptions.promtClientBaseID"
            v-model="form.clientBaseID"
            :label="$t('city')"
            :list="clientBases"
            valuePropName="ID"
            keyPropName="Name"
            :maxShowOptions="6"
          />

          <form-box-input
           v-if="this.config.accountSelectMode === 'Ident'"
            v-model="form.ident"
            refName="identControl"
            :label="$t('account_number')"
            placeholder="1234567890"
            :focused="!config.AddAccountByIdentOptions.promtClientBaseID"
            autocomplete="off"
          />

          <form-box-select
            v-if="selectPrefix"
            v-model="selectedPrefix"
            :label="$t('managing_organization')"
            :list="prefixes"
            valuePropName="Prefix"
            keyPropName="Name"
            autocomplete="off"
          />

          <form-box-input
            v-if="config.AddAccountByIdentOptions.promtBillAmount"
            v-model="form.billAmount"
            type="number"
            :label="$t('amount_in_any_of_bills')"
            placeholder="1000"
          />
        </template>
        <template v-else>
          <form-box-input
            v-if="config.AddAccountByIdentOptions.promtPinCode"
            v-model="form.pinCode"
            :label="promtPinCodeLabel"
            :message="promtPinCodeMessage"
            :minlength="1"
            :showPopover="showPinPopover"
            refName="pinControl"
            :placeholder="promtPinCodePlaceholder"
            autocomplete="off"
          />
        </template>
        <base-button type="submit" :disabled="isDisabled" @click="onSend()">{{ $t('connect') }}</base-button>
      </form>
    </div>
  </modal>
</template>

<script type="javascript">
import { mapActions, mapGetters } from 'vuex';
import Modal from "@/components/ui/Modal";
import BaseButton from "@/components/ui/BaseButton";
import FormBoxInput from '@/components/form/FormBoxInput'
import FormBoxSelect from '@/components/form/FormBoxSelect'

export default {
  name: "addAccountIdentModal",

  components: {
    Modal,
    BaseButton,
    FormBoxInput,
    FormBoxSelect,
  },

  data() {
    return {
      form: {
        ident: '',
        pinCode: '',
        clientBaseID: null,
        billAmount: null,
        inn: null,
        lastName: '',
        houseId: null, 
        premiseNumber: ''

      },
      stage: 1,
      isSending: false,
      selectedPrefix: "",
      showPinPopover: true,
      selectedAccount: null
    };
  },


  computed: {
    ...mapGetters({
      prefixes: 'account/accountPrefixes',
      clientBases: 'account/getClientBases',
      houses: 'request/getHouses',
      houseData: 'request/getHouseData',
    }),

    selectPrefix() {
      return this.prefixes.length;
    },

    isDisabled() {
      const stage = this.stage;
      const ident = this.form.ident;
      const pinCode = this.form.pinCode;
      const billAmount = this.form.billAmount;
      const selectedPrefix = this.selectedPrefix;
      const selectPrefix = this.selectPrefix;
      const promtBillAmount = this.config.AddAccountByIdentOptions.promtBillAmount;
      const inn = this.form.inn;
      const lastName = this.form.lastName;
      const houseId = this.form.houseId; 
      const premiseNumber = this.form.premiseNumber;
      const isValidHouseData = this.houseData.length > 0 ? !!premiseNumber : true;
      const isValidUserCredentials = inn ? inn : lastName;
      const isValid = (isValidUserCredentials && houseId && isValidHouseData) || ident.length

      if (stage === 1) {
        if (!isValid) return true;
        if (promtBillAmount && !billAmount) return true;
        if (selectPrefix && (!selectedPrefix || !selectedPrefix.length)) return true;
        return false;
      }

      if (!pinCode.length) return true;

      return false;
    },

    promtPinCodeLabel() {
      const isRostovZKH = this.isRostovZKH;
      const pinCodeFieldName = this.config.AddAccountByIdentOptions.pinCodeFieldName;

      if (isRostovZKH) return this.$t('access_code_from_bill');

      return  pinCodeFieldName || this.$t('access_code');
    },

    promtPinCodeMessage() {
      const isRostovZKH = this.isRostovZKH;

      if (isRostovZKH) return this.$t('enter_first_six_digits_of_access_code');

      return  this.$t('enter_code_from_last_bill');
    },

    promtPinCodePlaceholder() {
      const isRostovZKH = this.isRostovZKH;
      if (isRostovZKH) return '000000';

      return '0000';
    }
  },

  created() {
    this.getPrefixes()
    this.getHouses()
  },

  mounted() {
   this.getClientBases();
  },

  methods: {
    ...mapActions({
      getPrefixes: 'account/getAccountPrefixes',
      addAccountByIdent: 'account/addAccountByIdent', 
      addAccountByAddress: 'account/addAccountByAddress', 
      getClientBases: 'account/getClientBases',
      getHouses: 'request/getHouses',
      getHouseData: 'request/getHouseData',
    }),

    onSend() {
      if (this.stage === 1) {
        if (this.config.AddAccountByIdentOptions.promtPinCode) {
          this.stage = 2;
          return;
        }
      }

      if (
        this.form.ident.length > 0 && this.config.accountSelectMode === 'Ident'
      ) {
        this.isSending = true;

        if (this.selectPrefix) {
          this.form = {
            ...this.form,
            ident: this.selectedPrefix + this.form.ident,
            pinCode: this.form.pinCode,
          };
        }
          this.addAccountByIdent(this.form)
            .then((result) => {
              if (result.accounts?.length) {
                this.$emit("close");
                this.$notify.success(`${this.$t('personal_account')} №${this.form.ident} ${this.$t('connected')}!`);

                this.form.ident = "";
              } else {
                this.$notify.error(`${this.$t('personal_account')} №${this.form.ident} ${this.$t('not_found')}`);
              }
            })
            .finally(() => {
              this.stage = 1;
              this.isSending = false;
            })}
          else if (this.config.accountSelectMode === 'AddressAndLastName') {
            this.addAccountByAddress(this.form)
              .then((result) => {
                if (result.accounts?.length) {
                  this.$emit("close");
                  this.selectedAccount = result.accounts.find(item => item.houseId == this.form.houseId && item.address.includes(`кв. ${this.form.premiseNumber}`))

                  this.$notify.success(`${this.$t('personal_account')} №${this.selectedAccount?.ident} ${this.$t('connected')}!`);

                  this.form.ident = "";
                } else {
                  this.$notify.error(`${this.$t('personal_account')} ${this.$t('not_found')}`);
                }
              })
              .finally(() => {
                this.stage = 1;
                this.isSending = false;
              });
    }
    },

    addAccount(data) {
      this.addAccountByIdent(data)
        .then((result) => {
          if (result.accounts && result.accounts.length) {
            this.$emit("close");
            this.$root.$emit("updated-account-info");
            this.$notify.success(`${this.$t('personal_account')} №${this.form.ident} ${this.$t('connected')}!`);

            this.form.ident = "";
          }
        })
        .then(() => {
          this.stage = 1;
        })
        .finally(() => {
          this.isSending = false;
        });
    },

     getHouseDataById() {
      if (this.form.houseId) {
        this.getHouseData(this.form.houseId)
      }
    },
  },
};
</script>

<style lang="sass">
@import "../../sass/variables"
@import "../../sass/mixins"
@import "../../sass/components/form/form"
</style>
