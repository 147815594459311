export default {
  ru: {
    install_mobile_application: 'Установить мобильное приложение',
    install_mobile_application_alt: `
    <strong>Дорогие жители!</strong>
    <br><br>
    <span style="display: inline-block;text-indent: 20px;text-align: left;">С целью повышения вашего комфорта и безопасности мы запустили пилотный проект с мобильным доступом к системе видеонаблюдения.</span><br>
    <span style="display: inline-block;text-indent: 20px;text-align: left;">Также Вам доступны такие функции как Заявки в УК и оплата ЖКУ.<span/><br>
    <br>
    <span style="display: inline-block;text-indent: 20px;text-align: left;">Надеемся это сделает вашу жизнь в ЖК лучше!</span><br>
    <br>
    <strong style="display: inline-block;text-indent: 20px;text-align: left;">Ваш УК Глобус!</strong>
    <br><br>
    <em style="font-size="15px;text-align: left;">Примечание</em><br>
    <em style="font-size="15px;text-align: left;">Доступ открыт только для собственников номера мобильных телефонов, которых авторизованы в УК Глобус!</em>
    `,
    download_mobile_application: 'Скачать мобильное приложение',
    for_phone: 'для телефона',
  },

  en: {
    install_mobile_application: 'Install mobile application',
    download_mobile_application: 'Download mobile application',
    for_phone: 'for phone',
    install_mobile_application_alt: `
    <strong>Dear residents!</strong>
    <br><br>
    <span style="display: inline-block;text-indent: 20px;">In order to increase your comfort and safety, we have launched a pilot project with mobile access to the video surveillance system.</span><br>
    You also have access to such functions as Applications to the Criminal Code and payment of housing and communal services.<br>
    <br>
    <span style="display: inline-block;text-indent: 20px;">We hope this will make your life in the residential complex better!<span/><br>
    <br>
    <strong style="display: inline-block;text-indent: 20px;">Your management company Globus!</strong>
    <br><br>
    <em style="font-size="15px">Note</em><br>
    <em style="font-size="15px">Access is open only to owners of mobile phone numbers who are authorized by Globus Management Company!</em>
    `,
  },
}