import { normalizeData } from '../../services/common';
import * as meterApi from '@/api/meter';
import moment from 'moment';

const state = {
  // meters: null,
  meters: [],
  meterValues: {},
};

const actions = {
  async getMetersList({ commit }) {
    return await meterApi
      .getMetersList()
      .then(response => {
        commit('setMeters', response.data.Data);

        return normalizeData(response.data.Data, 'ID')
      })
      .catch((error) => {
        throw error;
      });
  },

  getMeterValues({ commit }, { meterUniqueNum }) {
    return meterApi
      .getMeterValues({ meterUniqueNum })
      .then((response) => {
        commit('setMeterValues', response.data.Data);

        return response.data.Data;
      })
      .catch((error) => {
        throw error;
      });
  },

  saveMeterValue(context, { ID, Value, ValueT2, ValueT3 }) {
    return meterApi
      .saveMeterValue({ ID, Value, ValueT2, ValueT3 })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  deleteMeterValue(context, { ID }) {
    return meterApi
      .deleteMeterValue({ ID })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  createRequestOnMeterRecheck(context, ID) {
    return meterApi
      .createRequestOnMeterRecheck({ ID })
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

const getters = {
  getMeterById: (state) => (id) => state.meters[id] || {},
  getMeters: (state) => (list) => {
    if (list) {
      return list.map((id) => state.meters[id] || {})
    }
    
    return null
  },
  getMeterValues: (state) => state.meterValues || [],

  getExpiredVerificationMeters: (state) => {
    if (state.meters) {
      let expiredMeters = [];
      const now = moment();

      Object.keys(state.meters)
        .forEach(i => {
          if (state.meters[i].NextCheckupDate) {
            // const lastCheckup = moment(meter.LastCheckupDate, 'DD.MM.YYYY');
            const nextCheckup = moment(state.meters[i].NextCheckupDate, 'DD.MM.YYYY');

            if (now.diff(nextCheckup, 'days') > 0) {
              expiredMeters = [
                ...expiredMeters,
                state.meters[i]
              ]
            }
          }
        })

      return expiredMeters;
    }

    return [];
  }
};

const mutations = {
  setMeters(state, data) {
    state.meters = data;
  },

  setMeterValues(state, data) {
    state.meterValues = [...data];
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
