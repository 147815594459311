<template>
  <section class="privilege-club__page">
    <page-header
      :back="{
        title: $t('back'),
        to: { name: 'Home' },
      }"
      :title="$t('privilege_club_for_new_residents')"
      class="privilege-club__title"
    />

    <section v-for="(section, i) in sections" :key="`privilege-club-section-${i}`" class="privilege-club__section">
      <section-header :title="section.title" />

      <div class="privilege-club__list">
        <div v-for="(item, j) in section.items" :key="`privilege-club-item-${j}`" class="privilege-club__item">
          <article class="card">
            <figcaption class="card__preview">
              <img :src="item.preview" :alt="item.title" />
            </figcaption>

            <div class="card__body">
              <h3 class="card__name">
                {{ item.title }}
              </h3>

              <div class="card__text" v-html="item.text"></div>
            </div>

            <div class="card__footer">
              <a v-loading="item.isLoading" class="card__link" @click.stop="onRedirect(item)">
                {{ item.link.title }}
                <arrow-right-icon class="card__link-icon" />
              </a>
            </div>
          </article>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapActions } from 'vuex';
import { ArrowRightIcon } from '@vue-hero-icons/outline';
import PageHeader from '@/components/pages/common/PageHeader';
import SectionHeader from '@/components/pages/common/SectionHeader';

export default {
  name: 'NewsSection',

  components: {
    PageHeader,
    ArrowRightIcon,
    SectionHeader,
  },

  data() {
    return {
      sections: [
        {
          id: 'kitchen',
          title: this.$t('kitchens'),
          items: [
            {
              id: null,
              title: this.$t('in_stock_and_at_special_prices'),
              text: this.$t('our_partner_furniture_factory_vivat'),
              preview: this.resolveUrl('/img/static/privilege-club/kitchen-1.png'),
              link: {
                title: this.$t('contact_the_designer'),
                url: null,
                // handler: () => this.openPrivilegeClubAmoModal(fileds)
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
            {
              id: null,
              title: this.$t('in_stock_and_at_special_prices'),
              text: this.$t('our_partner_furniture_factory_vivat'),
              preview: this.resolveUrl('/img/static/privilege-club/kitchen-2.png'),
              link: {
                title: this.$t('contact_the_designer'),
                url: null,
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
            {
              id: null,
              title: this.$t('in_stock_and_at_special_prices'),
              text: this.$t('our_partner_furniture_factory_vivat'),
              preview: this.resolveUrl('/img/static/privilege-club/kitchen-3.png'),
              link: {
                title: this.$t('contact_the_designer'),
                url: null,
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
            {
              id: null,
              title: this.$t('in_stock_and_at_special_prices'),
              text: this.$t('our_partner_furniture_factory_vivat'),
              preview: this.resolveUrl('/img/static/privilege-club/kitchen-4.png'),
              link: {
                title: this.$t('contact_the_designer'),
                url: null,
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
          ],
        },
        {
          id: 'furniture',
          title: this.$t('furniture'),
          items: [
            {
              id: null,
              title: this.$t('in_stock_and_at_special_prices'),
              text: this.$t('our_partner_furniture_factory_vivat'),
              preview: this.resolveUrl('/img/static/privilege-club/furniture-1.png'),
              link: {
                title: this.$t('contact_the_designer'),
                url: null,
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
            {
              id: null,
              title: this.$t('in_stock_and_at_special_prices'),
              text: this.$t('our_partner_furniture_factory_vivat'),
              preview: this.resolveUrl('/img/static/privilege-club/furniture-2.png'),
              link: {
                title: this.$t('contact_the_designer'),
                url: null,
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
          ],
        },
        {
          id: 'finishing-work',
          title: this.$t('finishing_work'),
          items: [
            {
              id: null,
              title: this.$t('companies_with_extensive_experience'),
              text: this.$t('provide_free_measurements_and_estimates'),
              preview: this.resolveUrl('/img/static/privilege-club/fishining-work-1.png'),
              link: {
                title: this.$t('get'),
                url: null,
                handler: ($event) => this.sendAmoData($event),
              },
              isLoading: false,
            },
          ],
        },
        {
          id: 'building-materials',
          title: this.$t('repair_products'),
          items: [
            {
              id: 'petrovich',
              title: `${this.$t('discounts_on_the_card')} Союз отважных`,
              text: `${this.$t('our_partner')} - СТД Петрович`,
              preview: this.resolveUrl('/img/static/privilege-club/building-materials-1.png'),
              link: {
                title: this.$t('get_card'),
                url: null,
              },
              isLoading: false,
            },
          ],
        },
      ],
      // fields: [
      //   {
      //     title: 'Телефон',
      //     type: 'phone',
      //     name: 'Phone'
      //   },
      //   {
      //     title: 'Адрес',
      //     type: 'textarea',
      //     name: 'Address'
      //   },
      //   {
      //     title: 'ФИО',
      //     type: 'text',
      //     name: 'Fio'
      //   },
      //   {
      //     title: 'Площадь квартиры',
      //     type: 'text',
      //     name: 'Fio'
      //   },
      //   {
      //     title: 'Источник',
      //     type: 'hidden',
      //     name: 'Fio'
      //   },

      // ]
    };
  },

  methods: {
    ...mapActions({
      clickableLink: 'common/clickableLink',
      amoSend: 'amo/send',
    }),

    onRedirect(item) {
      if (item.link.handler) {
        item.link.handler(item);
      }

      if (item.id) {
        item.isLoading = true;
        this.clickableLink(item.id).then((result) => {
          if (!result) {
            return false;
          }

          window.location.href = result;
        });
      }

      if (item.link.url) {
        window.location.href = item.link.url;
      }
    },

    sendAmoData(item) {
      item.isLoading = true;
      this.amoSend()
        .then(({ isSucceed }) => {
          // console.log('result:', result)

          if (isSucceed) {
            this.$notify.success(this.$t('request_sent'));
            // console.warn('Данные AMO CRM отправлены')
          }
        })
        .finally(() => {
          item.isLoading = false;
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.privilege-club__title ::v-deep .page-header__title
  width: 100%

.privilege-club__list
  display: flex
  flex-wrap: wrap

.privilege-club__item
  flex-grow: 1
  flex-shrink: 0
  flex-basis: 100%
  max-width: 100%
  padding-left: calc(16px / 2)
  padding-right: calc(16px / 2)
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    flex-basis: calc(100% / 2)
    max-width: calc(100% / 2)
    margin-bottom: 20px

    &:nth-child(1), &:nth-child(2n) + .privilege-club__item
      padding-left: 0

    &:nth-child(2n)
      padding-right: 0

  @media screen and (min-width: 992px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

  @media screen and (min-width: 1200px)
    flex-basis: calc(100% / 4)
    max-width: calc(100% / 4)
    padding-left: calc(16px / 2)
    padding-right: calc(16px / 2)

    &:nth-child(2n) + .privilege-club__item
      padding-left: calc(16px / 2)

    &:nth-child(2n)
      padding-right: calc(16px / 2)

    &:nth-child(1), &:nth-child(4n) + .privilege-club__item
      padding-left: 0

    &:nth-child(4n)
      padding-right: 0

  @media screen and (min-width: 1400px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

    &:nth-child(2n) + .privilege-club__item
      padding-left: calc(20px / 2)

    &:nth-child(2n)
      padding-right: calc(20px / 2)

    &:nth-child(4n) + .privilege-club__item
      padding-left: 0

    &:nth-child(4n)
      padding-right: 0

.privilege-club__card
  height: 100%

.card
  +Size(100%, auto)
  display: flex
  flex-direction: column
  justify-content: space-between
  background: $cBgMain
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
  border-radius: 16px

.card__preview
  +ResponsiveImg(100%, 154px, cover)
  border-radius: 16px
  margin-bottom: 24px
  border-radius: 16px 16px 0 0
  overflow: hidden

.card__body
  +Size(100%, auto)
  padding: 0 16px
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    padding: 0 24px

.card__name
  +TextHeader(2)
  width: 100%
  margin-bottom: 2px

.card__text
  +TextMini($cBlueDarkest)

  p, ul
    margin-bottom: 0

.card__footer
  +Size(100%, auto)
  display: flex
  justify-content: flex-end
  align-items: center
  flex-wrap: wrap
  padding: 0 16px 16px

  @media screen and (min-width: 768px)
    flex-wrap: nowrap
    padding: 0 24px 24px

  @media screen and (min-width: 1200px)
    flex-wrap: wrap

.card__link
  +TextButtonMini($cAccent)
  width: auto
  display: inline-flex
  align-items: center
  margin-left: auto

  cursor: pointer

.card__link-icon
  +Size(16px)
  flex-shrink: 0
  margin-left: 4px
</style>
