var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"polls"},[_c('section-header',{attrs:{"title":_vm.$t('polls'),"moreLink":{
      name: 'Polls',
    }},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('chat-icon',{staticClass:"icon"})]},proxy:true}])}),(_vm.items.length)?_c('div',{staticClass:"polls__list"},_vm._l((_vm.items),function(item,i){return _c('div',{key:`poll-${i}`,staticClass:"polls__item"},[_c('card',{staticClass:"polls__card",attrs:{"title":item.Name,"detail-link":{
          name: 'Polls',
          params: {
            id: item.ID,
            subpage: 'form',
          },
        }}})],1)}),0):[_c('alert',{attrs:{"type":"warning"}},[_vm._v(" "+_vm._s(_vm.$t('no_polls_to_complete_at_this_time'))+" "),_c('router-link',{staticClass:"polls__link",attrs:{"to":{
          name: 'Polls',
        }}},[_vm._v(" "+_vm._s(_vm.$t('all_polls'))+" ")])],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }