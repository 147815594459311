<template>
  <div
    v-loading="isLoadingAccounts"
    :class="['requests-page', !accounts || !accounts.length ? 'requests-page--no-accounts' : false]"
  >
    <template v-if="accounts && accounts.length">
      <div class="requests-page__column--left" ref="list">
        <page-header :title="$t('requests')" ref="title" class="requests-page__header">
          <template #right-content>
            <div v-show="requests.length" class="requests-page__toggler">
              {{ $t('closed') }}
              <toggler v-model="showClosed" />
            </div>
          </template>
        </page-header>

        <section ref="buttonSection" class="requests-page__new">
          <router-link :to="{ name: 'NewRequest' }" role="button" class="requests-page__new-request">
            + {{ $t('create_new_request') }}
          </router-link>
        </section>

        <requests-list
          v-if="filteredRequests.length"
          v-loading="isLoadingRequests"
          :items="filteredRequests"
          ref="requestsList"
          @request-list:request-canceled="getRequestsList"
          @request-list:open-request="openRequest"
        />
        <section v-else class="requests-page__list-warning">
          <Alert type="warning">{{ $t('no_requests') }}</Alert>
        </section>
      </div>
      <div
        ref="chat"
        :class="['requests-page__column--right', { 'requests-page__column--placeholder': !this.requestID }]"
      >
        <!-- Chat. Desktop -->
        <request-chat v-if="!isTabletView() && requestID" :requestId="+requestID" />
      </div>
    </template>

    <template v-else>
      <page-header :title="$t('requests')" />

      <section class="requests-page__add-account">
        <add-account-ident :text="$t('create_request')" />
      </section>
    </template>

    <!-- Modals -->
    <!-- Chat. Mobile -->
    <modal name="requestChatModal" :addaptive="true" height="100%" :scrollable="true" class="request-page__modal">
      <modal-wrapper
        :title="`${$t('request')} №${requestNumber}`"
        :chatMode="true"
        class="request-chat"
        @close="closeRequest"
        @request-chat:open-pay-modal="closeRequest"
      >
        <!-- Chat -->
        <request-chat v-if="requestID" :requestId="+requestID" />
      </modal-wrapper>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AddAccountIdent from '@/components/pages/common/AddAccountIdent';
import PageHeader from '@/components/pages/common/PageHeader';
import Toggler from '@/components/ui/Toggler';
import RequestsList from '@/components/pages/requests/RequestsList';
import RequestChat from '@/components/pages/requests/RequestChat';
import ModalWrapper from '@/components/ui/Modal';
import Alert from '@/components/ui/Alert';

export default {
  name: 'Requests',

  components: {
    PageHeader,
    Toggler,
    RequestsList,
    RequestChat,
    ModalWrapper,
    AddAccountIdent,
    Alert,
  },

  props: {
    requestID: null,
  },

  data() {
    return {
      windowWidth: 0,
      messageIDs: [],
      selectedRequestID: this.requestID,
      selectedRequestInfo: null,
      templateMessageFiles: [],
      timer: null,
      showDialogModal: false,
      showClosed: false,
      isLoadingAccounts: true,
      isLoadingRequests: false,
    };
  },

  computed: {
    ...mapGetters({
      requests: 'request/getRequests',
      getRequestById: 'request/getRequestById',
      getMenuVisibilitySettings: 'config/getMenuVisibilitySettings',
      accounts: 'account/getAccounts',
    }),

    filteredRequests() {
      return this.requests.filter((request) => {
        if (this.showClosed) {
          return request.IsClosed;
        }

        return !request.IsClosed;
      });
    },

    // selectedRequestHeader() {
    //   return `Заявка № ${this.selectedRequestInfo.number} ${
    //     this.selectedRequestInfo.paidRequestStatus
    //       ? this.selectedRequestInfo.paidRequestStatus
    //       : ""
    //   }`;
    // },

    requestNumber() {
      return this.getRequestById(this.requestID).RequestNumber;
    },
  },

  created() {
    this.$root.$on('new-request', this.getRequestsList);
    this.$root.$on('paid-request', this.getRequestsList);
    this.$root.$on('requests:sent-message', this.getRequestsList);
    this.$root.$on('updated-account-info', this.onAccountInfoChanged);
    this.$root.$on('deleted-account-info', this.onAccountInfoChanged);
    this.$root.$on('requests:closed-request', this.getRequestsList);

    this.resizeThrottlerFunctionList.push(this.setContainerHeight, this.calcWindowWidth);
  },

  mounted() {
    // let requestId = this.requestID;

    this.getAccountInfo();
    this.calcWindowWidth();
    // this.$nextTick(() => {
    this.setContainerHeight();
    // })

    // this.getRequests(requestId);

    let target = this;
    this.timer = setInterval(() => {
      target.update();
    }, 30000);

    // this.initPerfectScroll();
    // setTimeout(this.showPaymentResult, 1000);
  },

  destroyed() {
    window.removeEventListener('resize', this.calcWindowWidth);

    this.$root.$off('new-request', this.getRequestsList);
    this.$root.$off('updated-account-info', this.onAccountInfoChanged);
    this.$root.$off('deleted-account-info', this.onAccountInfoChanged);
    this.$root.$off('requests:closed-request');

    clearInterval(this.timer);
  },

  methods: {
    ...mapActions({
      getUserInfo: 'account/getUserInfo',
      refreshRequest: 'request/update',
      getRequestsList: 'request/getRequestsList',
      cancelRequest: 'request/CancelRequest',
    }),

    calcWindowWidth() {
      this.windowWidth = window.innerWidth;
    },

    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getRequests();
    },

    getAccountInfo() {
      this.isLoadingAccounts = true;

      this.getUserInfo().finally(() => {
        this.isLoadingAccounts = false;
      });
    },

    update() {
      this.refreshRequest(this.requestID).then((result) => {
        if (result.current) {
          // Add new messages
          this.$root.$emit('requests:update');
        }
      });
    },

    getRequests(selectId = null) {
      this.isLoadingRequests = true;

      this.messageIDs = [];
      this.selectedRequestID = null;

      this.getRequestsList()
        .then(() => {
          if (selectId) {
            this.selectedRequestID = selectId;
            if (this.isTabletView()) {
              this.showDialogModal = true;
            }
            return;
          }
          if (this.filteredRequests.length) {
            this.$router.push({
              name: 'Requests',
              params: { requestID: this.filteredRequests[0].ID },
            });
          }
        })
        .finally(() => {
          this.isLoadingRequests = false;
        });
    },

    scrollToBottom() {
      try {
        this.$refs.dialogListScroll.scrollTop = this.$refs.dialogListScroll.scrollHeight;
      } catch (e) {
        console.log(e);
      }
    },

    showPaymentResult() {
      if (location.hash.match(/paymentSuccess$/)) {
        this.$notify.success({
          title: this.$t('payment'),
          message: this.$t('payment_successful'),
          duration: 6000,
          offset: 100,
        });
      }

      if (location.hash.match(/paymentFail$/)) {
        this.$notify.error({
          title: this.$t('payment'),
          message: this.$t('payment_failed'),
          duration: 6000,
          offset: 100,
        });
      }
    },

    openRequest() {
      if (this.isTabletView()) {
        this.$modal.show('requestChatModal');
      }
    },

    closeRequest() {
      this.selectedRequestID = null;
      this.$router.push({
        name: 'Requests',
      });
      this.$modal.hide('requestChatModal');
    },

    setContainerHeight() {
      // console.log('--- Reqests page -> setContainerHeight method ---')
      // this.$nextTick(() => {
      let interval = setInterval(() => {
        const $chat = this.$refs.chat;
        const $list = this.$refs.list;

        if ($chat && $list) {
          // const $main = this.$parent.$refs.main;
          const headerHeight = this.$parent.$refs.header.$el.offsetHeight;
          const footerHeight = this.$parent.$refs.footer.$el.offsetHeight;
          const viewportHeight = Math.floor(window.visualViewport.height);

          const mainHeight = viewportHeight - (headerHeight + footerHeight);

          // $main.style.maxHeight = `${mainHeight}px`;
          // $main.style.overflowX = `hidden`;

          $chat.style.height = `${mainHeight}px`;
          $list.style.maxHeight = `${mainHeight}px`;

          const $requestsList = this.$refs.requestsList;

          if ($requestsList) {
            const titleHeight = this.$refs.title.$el.offsetHeight;
            const buttonSectionHeight = this.$refs.buttonSection.offsetHeight;
            const requestsListHeight = mainHeight - (titleHeight + buttonSectionHeight);

            $requestsList.$refs.scrollSection.$el.style.maxHeight = `${requestsListHeight}px`;
          }

          clearInterval(interval);
        }
      }, 300);
      // });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.requests-page
  display: flex
  height: 100%
  align-items: stretch

  &--no-accounts
    display: block

  .request-chat
    height: 100%
    max-height: 100%

.requests-page__header
  border-bottom: 1px solid $cBorder
  padding:
    left: 16px
    right: 16px

  @media screen and (min-width: 768px)
    padding:
      left: 24px
      right: 24px

  @media screen and (min-width: 1200px)
    padding:
      left: 80px
      right: 24px

.requests-page__column
  &--left
    flex:
      grow: 0
      shrink: 0
      basis: 100%

  &--right
    display: none
    flex:
      grow: 0
      shrink: 1
      basis: 100%
    border-left: 1px solid $cBorder

  &--placeholder
    background:
      image: url('../../public/img/static/request-chat-placeholder.svg')
      repeat: no-repeat
      position: center

  @media screen and (min-width: 1200px)
    &--left
      max-width: 50%
      // padding-right: 24px

    &--right
      display: flex

  @media screen and (min-width: 1400px)
    &--left
      max-width: 592px

.requests-page__toggler
  +TextMain()
  display: flex
  align-items: center
  flex-shrink: 0

  .toggler
    margin-left: 16px

.requests-page__new
  padding: 16px
  border-bottom: 1px solid $cBorder

  @media screen and (min-width: 768px)
    padding: 16px 24px

.requests-page__new-request
  +Button('text')
  display: inline-flex
  padding: 0 24px

.requests-page__list-warning
  padding: 24px 16px
</style>
