export default {
  ru: {
    user_cart: 'Корзина',
    placing_order: 'Оформление заказа',
    select_address: 'Выберите адрес',
    continue: 'Продолжить',
    fine: 'Хорошо',
    price: 'Цена',
  },

  en: {
    user_cart: 'User cart',
    placing_order: 'Placing an order',
    select_address: 'Select address',
    continue: 'Continue',
    fine: 'Fine',
    price: 'Price',
  },
}