var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-box__field"},[_c('div',{class:[
      'field',
      'field--textarea',
      { 'field--error': _vm.hasError || _vm.error },
      { 'field--active': _vm.value.toString().length > 0 || _vm.fieldIsFocus },
      { 'field--popover': _vm.showPopover },
      { 'field--no-label': !_vm.label },
    ]},[_c('div',{staticClass:"field__group",style:(`
        ${_vm.width ? `width: ${_vm.width};` : ''}
        ${_vm.height ? `height: ${_vm.height};` : ''}
      `)},[_c('div',_vm._g({staticClass:"field__control"},_vm.toggleFieldFocus()),[(_vm.label)?_c('label',{staticClass:"field__label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('textarea',_vm._g({ref:_vm.ref,staticClass:"field__textarea",attrs:{"name":_vm.name,"cols":_vm.cols ? _vm.cols : false,"rows":_vm.rows ? _vm.rows : false,"placeholder":_vm.placeholder ? _vm.placeholder : false},domProps:{"value":_vm.value},on:{"input":_vm.inputHandler}},_vm.toggleInputFocus()))]),_vm._t("icon")],2),_c('p',{directives:[{name:"show",rawName:"v-show",value:((_vm.hasError || _vm.error) && _vm.message),expression:"(hasError || error) && message"}],staticClass:"field__message"},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPopover),expression:"showPopover"}],staticClass:"field__popover"},[_vm._t("popover")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }