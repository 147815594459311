<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 6H8C6.34315 6 5 7.34315 5 9V19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19V9C19 7.34315 17.6569 6 16 6ZM8 4C5.23858 4 3 6.23858 3 9V19C3 21.7614 5.23858 24 8 24H16C18.7614 24 21 21.7614 21 19V9C21 6.23858 18.7614 4 16 4H8Z"
      :fill="fill"
    />
    <path d="M8 2C8 1.44772 8.44772 1 9 1H15C15.5523 1 16 1.44772 16 2V5H8V2Z" :stroke="fill" stroke-width="2" />
    <path
      d="M10.3884 19C6.4171 17.1658 8.10002 14.0685 10.3882 11.2504C12.6763 8.4322 12.8504 10.9227 14.9646 13.3639C17.4053 16.182 14.9645 19 13.4391 19"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'HouseholdGasIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
