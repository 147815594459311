export default {
  ru: {
    personal_account: 'Лицевой счет',
    personal_accounts: 'Лицевые счета',
    short_personal_account: 'Л/с',
    financial_personal_account: 'Финансовый лицевой счет',
    connect_personal_account: 'Подключить лицевой счет',
    add_personal_account: 'Добавить лицевой счет',
    delete_personal_account: 'Удалить счет',
    deleting_personal_account: 'Удаление лицевого счета',
    sure_delete_personal_account: 'Вы уверены, что хотите удалить лицевой счет',
    deleted: 'удален',
    personal_accounts_on: 'лицевых счета на',
    account_number: 'Номер лицевого счета',
    you_dont_have_personal_accounts: 'У вас пока нет лицевых счетов',
    by_which_you_can: 'по которым можно',
    carry_out_operations: 'проводить операции',
    please_select_personal_account: 'Пожалуйста, выберите лицевой счет',
    personal_account_statement: 'Выписка по лицевому счету',
    personal_account_reconciliation_file: 'Файл сверки по лицевому счету',
    update_sum_information: 'Сумма к оплате обновляется один раз в месяц при формировании квитанций. Обратите внимание на дату, указанную рядом с суммой к оплате',
    inn: "ИНН",
    last_name: "Фамилия",
    address: "Адрес",
    premise_number: "Номер квартиры или помещения",
    connected_successfully: "успешно подключён"
  },

  en: {
    personal_account: 'Personal account',
    personal_accounts: 'Personal accounts',
    short_personal_account: 'P/a',
    financial_personal_account: 'Financial personal account',
    connect_personal_account: 'Connect your personal account',
    add_personal_account: 'Add personal account',
    delete_personal_account: 'Delete personal account',
    deleting_personal_account: 'Deleting a personal account',
    sure_delete_personal_account: 'Are you sure you want to delete your personal account',
    deleted: 'deleted',
    personal_accounts_on: 'personal accounts on',
    account_number: 'Account number',
    you_dont_have_personal_accounts: 'You don\'t have any personal accounts yet',
    by_which_you_can: 'by which you can',
    carry_out_operations: 'carry out operations',
    please_select_personal_account: 'Please select a personal account',
    personal_account_statement: 'Personal account statement',
    personal_account_reconciliation_file: 'Personal account reconciliation file',
    update_sum_information: 'The amount due is updated once a month when receipts are generated. Pay attention to the date next to the amount payable',
    inn: "INN",
    last_name: "Last Name",
    address: "Address",
    premise_number: "Premise_Number",
    connected_successfully: "connected successfully"
  },

}