<template>
  <button
    :type="typeAttr"
    :class="['button', `button--${styleClass}`, modificator ? `button--${modificator}` : false]"
    :disabled="disabled"
    @click="$emit('click')"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @clickout="$emit('clickout')"
  >
    <slot>
      {{ title }}
    </slot>
  </button>
</template>

<script>
export default {
  name: 'BaseButton',

  props: {
    typeAttr: {
      type: String,
      required: false,
      default: 'button',
    },
    styleClass: {
      type: String,
      required: false,
      default: 'base',
    },
    /**
     * Allow mods:
     * remove - add a error color
     */
    modificator: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    caption: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.button
  &--base
    +Button('base')

  &--additional
    +Button('additional')

  &--secondary
    +Button('secondary')

  &--text
    +Button('text')

  &--text-mini
    +Button('text-mini')

  &--secondary-text
    +Button('secondary-text')

  &--secondary-text-mini
    +Button('secondary-text-mini')

  &--error
    color: $cError
</style>
