<template>
  <div class="tabs">
    <ul v-if="tabs" class="tabs__header">
      <template v-for="tab in tabs">
        <li
          v-if="tab"
          :key="`tab-${tab.id}`"
          class="tabs__tab"
          :class="{ 'tabs__tab--active': value === tab.id }"
          :ref="tab.id"
          @click="$emit('input', tab.id)"
        >
          {{ tab.text }}
        </li>
      </template>
    </ul>

    <div class="tabs__body">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'tabs',

  props: {
    tabs: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: String,
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../sass/variables"
@import "../../sass/mixins"

.tabs
  +Size(100%, auto)

.tabs__header
  +ListReset()
  +Size(100%, auto)
  display: flex
  justify-content: space-around
  background-color: $cBgMain

.tabs__tab
  +TextButtonMini()
  display: inline-flex
  justify-content: center
  align-items: center
  text-align: center
  background-color: $cElmLight
  padding: 8px
  cursor: pointer
  flex:
    shrink: 1
    basis: 100%
    wrap: nowrap

  &--active
    background-color: $cBgSec
    color: $cTextMain
    cursor: default

  &:hover:not(&--active)
    color: $cAccent

  &:first-child
    border-radius: 12px 0 0 12px

  &:last-child
    border-radius: 0 12px 12px 0

  @media screen and (min-width: 576px)
    padding: 12px

.tabs__body
  padding: 16px 0
</style>
