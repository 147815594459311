import * as amoApi from '@/api/amo';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async send(_, params) {
    if (typeof params !== 'undefined') {
      return await amoApi
        .send(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
    }

    return await amoApi
      .send()
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
