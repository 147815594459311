import * as pollsApi from '@/api/polls';

const state = {
  polls: [],
  detailPoll: null,
  detailPassedPoll: null,
  pollResults: null,
};

const getters = {
  // return (
  //   item.Questions.filter((question) => question.IsCompleteByUser)
  //     .length !== item.Questions.length
  // );
  getNonPassedPolls: ({ polls }) => polls.filter((item) => !item.Passed && !item.PartiallyPassed) || [],
  getPolls: ({ polls }) => polls,
  getDetailPoll: ({ detailPassedPoll }) => detailPassedPoll,
  detailPoll: ({ detailPoll }) => detailPoll,
  getPollResults: ({ pollResults }) => pollResults || [],
};

const mutations = {
  setPolls(state, data) {
    data.forEach((poll) => {
      poll.Questions.forEach((item) => {
        const urlRegExp =
          /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*))/;
        item.Text = item.Text.replace(urlRegExp, "<a target='_blank' class='poll__anchor' href='$1'>$1</a>");
      });

      const passedQuestions = poll.Questions.filter((question) => question.IsCompleteByUser);

      poll['Passed'] = passedQuestions.length && passedQuestions.length === poll.Questions.length;
      poll['PartiallyPassed'] = passedQuestions.length && passedQuestions.length !== poll.Questions.length;
    });

    state.polls = [...data];
  },

  setReadedFlag(state, id) {
    state.polls.find((item) => item.ID == id).IsReaded = true;
  },

  SET_DETAIL_POLL(state, id) {
    state.detailPoll = state.polls.find((poll) => poll.ID === id);
  },

  SET_POLL_RESULTS(state, data) {
    state.pollResults = data;
  },
};

const actions = {
  saveResult(_, data) {
    return pollsApi
      .saveResult(data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  setReadedFlag({ commit }, id) {
    return pollsApi
      .setReadedFlag(id)
      .then(() => {
        commit('setReadedFlag', id);
      })
      .catch((error) => {
        throw error;
      });
  },

  openPoll({ commit }, id) {
    commit('SET_DETAIL_POLL', id);
  },

  getPollResults({ commit }, id) {
    return pollsApi
      .results(id)
      .then((response) => {
        commit('SET_POLL_RESULTS', response.data.Data);
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
