export default {
  ru: {
    notification: 'Уведомление',
    notifications: 'Уведомления',
    no_notifications: 'Уведомлений нет',
  },

  en: {
    notification: 'Notification',
    notifications: 'Notifications',
    no_notifications: 'No notifications',
  },
}