<template>
  <component
    :is="iconComponent"
    :width="width"
    :height="height"
    :viewBox="`0 0 ${width} ${height}`"
    :fill="iconColor"
  />
</template>

<script>
import SupportIcon from './SupportIcon';
import WaterIcon from './WaterIcon';
import GasIcon from './GasIcon';
import HistoryIcon from './HistoryIcon';
import ElectricityIcon from './ElectricityIcon';
import UndefinedMeterIcon from './UndefinedMeterIcon';
import WastewaterIcon from './WastewaterIcon';
import HouseholdGasIcon from './HouseholdGasIcon';
import SolidFuelIcon from './SolidFuelIcon';
import ThermalEnergyIcon from './ThermalEnergyIcon';

export default {
  name: 'UIIcons',

  components: {
    SupportIcon,
    WaterIcon,
    GasIcon,
    HistoryIcon,
    ElectricityIcon,
    UndefinedMeterIcon,
    WastewaterIcon,
    HouseholdGasIcon,
    SolidFuelIcon,
    ThermalEnergyIcon,
  },

  props: {
    iconName: {
      type: String,
      required: true,
    },
    width: {
      type: [Number, String],
      required: false,
      default: 20,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 20,
    },
    iconColor: {
      type: String,
      required: false,
      default: 'currentColor',
    },
  },

  computed: {
    iconComponent() {
      return `${this.iconName}Icon`;
    },
  },
};
</script>
