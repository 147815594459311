export default {
  ru: {
    access_code: 'Код доступа',
    access_code_from_bill: 'Код доступа из квитанции',
    amount_in_any_of_bills: 'Сумма в любой из квитанций за последние 3 месяца',
    enter_first_six_digits_of_access_code: 'Введите первые шесть цифр кода доступа из квитанции',
    repeat_code_request_after: 'Повторить запрос кода через:',
    enter_last_4_digits: 'Введите код, который Вам сообщат в звонке на номер',
    to_receive_access_code: 'Чтобы получить код доступа нажмите «Звонок с кодом». Вам позвонит робот на номер',
    and_tell_you_the_code: 'и сообщит код, либо нажмите «Код в SMS» для получения кода сообщением по SMS.',
    call_with_code: 'Звонок с кодом',
    code_in_sms: 'Код в SMS',
    request_code: 'Запросить код',
    didnt_receive_the_code: 'Не получили код?',
    enter_code_from_last_bill: 'Введите код c последней квитанции',
  },

  en: {
    access_code: 'Access code',
    access_code_from_bill: 'Access code from bill',
    amount_in_any_of_bills: 'Amount in any of the bills for the last 3 months',
    enter_first_six_digits_of_access_code: 'Enter the first six digits of the access code from your bill',
    repeat_code_request_after: 'Repeat the code request after:',
    enter_last_4_digits: 'Enter the code that you will receive when calling the number',
    to_receive_access_code: 'To receive an access code, click «Call with code». A robot will call you at your number',
    and_tell_you_the_code: 'and tell you the code, or click «Code in SMS» to receive the code via SMS.',
    call_with_code: 'Call with code',
    code_in_sms: 'Code in SMS',
    request_code: 'Request code',
    didnt_receive_the_code: 'Didn\'t receive the code?',
    enter_code_from_last_bill: 'Enter the code from your last bill',
  },
}