<template>
  <Modal title="Обращение в службу технической поддержки" @close="$emit('close')">
    <form @submit.prevent="send" class="application-form" v-loading="isLoading">
      <div class="form-group">
        <span class="support-info">Задайте вопрос, опишите проблему. Мы обязательно вам поможем</span>
      </div>
      <div class="form-group">
        <label class="form-label"> <span class="glipf-phone-2"></span>Телефон </label>
        <input
          name="phone"
          type="tel"
          placeholder="+7 (999) 999 99 99"
          ref="loginControl"
          v-mask="`+7 (###) ### ## ##`"
          class="form-control"
          v-model="form.phone"
        />
      </div>
      <div class="form-group">
        <label class="form-label"> <span class="glipf-mail-2"></span>E-mail </label>
        <input type="email" class="form-control" v-model="form.mail" />
      </div>
      <div class="form-group">
        <textarea v-model="form.text" class="form-textarea" placeholder="Опишите проблему..."></textarea>
      </div>
      <!-- <button 
        :class="['payment-form_submit main-btn', !isValid ? 'closeRequest-btn_disabled' : '' ]"
        @click="send" style="border: 0">Отправить</button> -->
      <div :class="['wrapper_unified-form_submit main-btn', isValid ? '' : 'main-btn-disabled']">
        <span class="text">Отправить</span>
        <input type="submit" class="unified-form_submit-btn" value="" :disable="isLoading || isValid" />
      </div>
      <div class="form-group" v-if="isLoggedIn && canAddReqeuest">
        <span class="support-warn"
          >Данная форма предназначена для обращения в поддержку личного кабинета. Если вы хотите обратиться в
          управляющую организацию -
          <a href="" class="navigation-link" @click.prevent="openRequests">создайте заявку тут</a></span
        >
      </div>
    </form>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import * as commonApi from '@/api/common';
import * as authService from '@/services/auth.service';

export default {
  name: 'contactSupportModal',
  components: { Modal },
  data() {
    return {
      form: {
        login: '',
        idents: '',
        phone: '',
        mail: '',
        address: '',
        os: '-',
        info: 'Отправлено со страницы: ' + window.location.href + '\nAgent: ' + navigator.userAgent,
        appVersion: 'WEB LK build ' + document.documentElement.dataset.buildTimestampUtc,

        text: '',
      },

      isLoading: false,
      isLoggedIn: false,
      canAddReqeuest: false,
    };
  },
  created() {
    this.$root.$on('menuitem-visibility-changed', this.updateMenuItemVisibility);
    this.updateMenuItemVisibility(this.$store.getters['config/getMenuVisibilitySettings']);
  },
  destroyed() {
    this.$root.$off('menuitem-visibility-changed', this.updateMenuItemVisibility);
  },
  mounted() {
    if (authService.isAuthUser()) {
      this.getAccountInfo();
    }
  },
  methods: {
    getAccountInfo() {
      if (this.$store) {
        this.form.isLoading = true;
        this.$store
          .dispatch('account/getUserInfo')
          .then((result) => {
            if (result) {
              this.isLoggedIn = true;
              this.form.login = result.Login;
              this.form.idents = result.Accounts.map((x) => x.Ident).join('; ');
              if (result.Phone.startsWith('7')) {
                this.form.phone = '+' + result.Phone;
              }
              this.form.mail = result.Email;
              if (result.Accounts && result.Accounts.length > 0) {
                this.form.address = result.Accounts.reduce(
                  (accumulator, item) =>
                    (accumulator.length > 0 && item.Address && item.Address.length > 0 ? '; ' : '') +
                    (item.Address ? item.Address : ''),
                  '',
                );
              }
            }
          })
          .then(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          })
          .catch(() => (this.isLoading = false));
      }
    },
    send() {
      if (!this.isValid) {
        return;
      }
      this.isLoading = true;
      commonApi
        .techSupportAppeal(this.form)
        .then(() => {
          this.isLoading = false;
          this.$notify.success({
            title: 'Обращение отправлено',
            message: 'Обращение успешно отправлено',
            duration: 6000,
            offset: 100,
          });
          this.$emit('close');
        })
        .catch((error) => {
          this.isLoading = false;
          throw error;
        });
    },
    openRequests() {
      this.$emit('close');
      this.$router.replace({ name: 'Requests' });
    },
    updateMenuItemVisibility(visibilitySettings) {
      if (visibilitySettings) {
        this.canAddReqeuest = visibilitySettings.requests;
      } else {
        this.canAddReqeuest = false;
      }
    },
  },
  computed: {
    isValid() {
      return this.form.phone && this.form.mail && this.form.text;
    },
  },
  watch: {},
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
.el-select
  position: relative
  top: 1px
  margin-left: 10px
</style>

<style lang="scss">
.el-input__inner:focus {
  border: 1px solid #dcdfe6;
}
</style>
